export const bSante = (sante) => {
    if (sante === '0') {;return 'lime'};
    if (sante === '1') {return 'palegreen'};
    if (sante === '2') {return 'gray'};
    if (sante === '3') {return 'orange'};
    if (sante === '4') {return 'red'};
    if (sante === '5') {return 'black'};
    if (sante === 0) {;return 'lime'};
    if (sante === 1) {return 'palegreen'};
    if (sante === 2) {return 'gray'};
    if (sante === 3) {return 'orange'};
    if (sante === 4) {return 'red'};
    if (sante === 5) {return 'black'};
    };
