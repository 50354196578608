import React from 'react'

import { Container, Row, Col, ButtonGroup, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from 'react-redux'

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';


import i18n from '../services/i18n';
import { dispDate } from '../services/prepDate';
import { bSante } from '../services/bSante';

import CustomTextStd2 from '../../components/CustomTextStd2';



const ResumeVisite = (props) => {
   const {
      visite, langue,
      ...inputProps
   } = props

   const { cPickers } = useSelector(state => state.cPicker);
   let contenuPickers = Object.assign({}, cPickers);
 

   const libCR = (pres_CR) => {
      const laCR = contenuPickers.TypCR.find(mar => mar.id === pres_CR);
      if (typeof (laCR) != "undefined") { return laCR.nom };
      return '';
    };




   return (
      <CardContent>
         <Box>
            {visite.date ? (
               <>
                  <Box style={{ flexDirection: 'row', flex: 0, display: 'flex' }}>
                     <Box style={{ flex: 3, flexDirection: 'column' }}>
                        <CustomTextStd2 label={i18n.t('colonie.date')} contenu={dispDate(visite.date, langue)} />
                     </Box>
                     <Box style={{ flex: 3, flexDirection: 'column' }} bgcolor={bSante(visite.sante)} >
                        <CustomTextStd2 label={i18n.t('colonie.sante')} contenu='' />
                     </Box>
                  </Box>
                  <Box style={{ flexDirection: 'row', flex: 0, display: 'flex' }}>
                     <Typography style={{ flex: 1, textDecorationLine: (visite.pres_oeuf) ? 'none' : 'line-through' }}>{i18n.t('visite.oeufs')}</Typography>
                     <Typography style={{ flex: 1, textDecorationLine: (visite.pres_couv_ouv) ? 'none' : 'line-through' }}>{i18n.t('visite.cOuvert')}</Typography>
                     <Typography style={{ flex: 1, textDecorationLine: (visite.pres_couv_ferm) ? 'none' : 'line-through' }}> {i18n.t('visite.cFermé')}</Typography>
                     <Typography style={{ flex: 1, textDecorationLine: (visite.pres_reine) ? 'none' : 'line-through' }}>{i18n.t('visite.reine')}</Typography>
                     <Typography style={{ flex: 1,  }}>{libCR(visite.pres_CR)}</Typography>
                     <Typography style={{ flex: 1 }}>{i18n.t('visite.ruelles') + ': ' + (visite.ruelles ? visite.ruelles : "")}</Typography>
                  </Box>
               </>
            )
               : (<Typography>{i18n.t('colonie.pasDeVisite')}</Typography>)}

            {visite.observations ? (
               <Box style={{ flexDirection: 'row', flex: 0, display: 'flex', }} overflow="hidden"    >
                  <Typography style={{ flex: 1 }}>{i18n.t('colonie.observation')}</Typography>
                  <Typography sx={{
                     flex: 5, borderColor: 'black', border: 1, borderRadius: 2, display: "-webkit-box",
                     WebkitLineClamp: "2", WebkitBoxOrient: "vertical",
                  }} style={{
                     whiteSpace: 'pre-line',
                  }}>{visite.observations}</Typography>
               </Box>
            ) : null}

            {visite.travauxrealises ? (
               <Box style={{ flexDirection: 'row', flex: 0, display: 'flex', }} overflow="hidden"    >
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.réalisé')}     </Typography>
                  <Typography sx={{
                     flex: 5, borderColor: 'black', border: 1, borderRadius: 2, display: "-webkit-box",
                     WebkitLineClamp: "2", WebkitBoxOrient: "vertical",
                  }} style={{
                     whiteSpace: 'pre-line',
                  }}>{visite.travauxrealises}</Typography>
               </Box>
            ) : null}

            {visite.travauxafaire ? (
               <Box style={{ flexDirection: 'row', flex: 0, display: 'flex', }} overflow="hidden"    >
                  <Typography style={{ flex: 1 }}>{i18n.t('colonie.aFaire')}     </Typography>
                  <Typography sx={{
                     flex: 5, borderColor: 'black', border: 1, borderRadius: 2, display: "-webkit-box",
                     WebkitLineClamp: "2", WebkitBoxOrient: "vertical",
                  }} style={{
                     whiteSpace: 'pre-line',
                  }}>{visite.travauxafaire}</Typography>
               </Box>
            ) : null}
         </Box>
      </CardContent>
   )

}

export default ResumeVisite;
