import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { makeStyles } from '@mui/styles';


import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { red, amber, grey, green } from '@mui/material/colors';

import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';



import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';

import CustomTextStd2 from '../../components/CustomTextStd2';
import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomInputSsLabel from '../../components/CustomInputSsLabel';
import CustomButton from '../../components/CustomButton';


import {
  getLastRecolte, getLastVisite, getLastComptage, getLastObservation, getLastDemenagement, getDetColonie,
  getListTypPb, getListTypCR, getListTypMedoc, getListUniNourri, getOptionsVisite, getFirstVisite,
  getFirstColonie, listColoniesdeApi, getListRuchersDeApi, listColoniesDelegation, creeMultComptage
} from '../services/accessApiRuchers';


import { randomId } from '@mui/x-data-grid-generator';

// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import { setAffColonie, setAffCreeRucher, setAffCreeColonie, setAffNewMultiComptage } from '../../store/displayRucherslice'
import {
  laColonieStore, laDerniereVisiteStore, laVisiteStore,
  leDernierComptageStore, leComptageStore,
  laDerniereRecolteStore, laRecolteStore,
  laDerniereObservationStore, lObservationStore,
  lesColoniesDeApiStore, lesRuchersTravailStore,
  apiProprietaireStore, lesColoniesDelegationStore
} from '../../store/ruchersslice'
import { DisplaySettingsRounded } from '@mui/icons-material';
import i18n from '../services/i18n';
import { dispDate, dispDate6 } from '../services/prepDate';
import { bSante } from '../services/bSante';

const useStyles = makeStyles({
  root: {
    '& Div': {
      padding: 0,
    },
  },
});


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function NewMultiComptage() {
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.user);
  const langue = user.langage;
  let itemApi = user.id;
  const [visible, setVisible] = useState(true);

  const { cPickers } = useSelector(state => state.cPicker);
  const { lesColoniesDuRucher, lesColoniesDelegation, leRucher } = useSelector(state => state.ruchers);


  let contenuPickers = Object.assign({}, cPickers);

  const typeRuche = (colonie) => {
    const lacolo = contenuPickers.typRuc.find (typr => typr.id === colonie.typeruche);
    if ((typeof (lacolo) != "undefined"))
         {return lacolo.modeleruche};
    return '';
    };

  const Initialisation = () => {
    console.log('init');
    const init = new Object();

    let lesColonies = [];
    for (let colonie of lesColoniesDuRucher) {
      if (colonie.motifsuppression == null) {
        const laColo = new Object();
        laColo.id = colonie.id;
        laColo.nom = colonie.nom;
        laColo.typeruche = colonie.typeruche;
        laColo.choix = true;
        laColo.nombrevarroas = "0";
        lesColonies.push(laColo);
      }
    }
    init.colonies = lesColonies;
    init.dateS= new Date();

    init.methode = 1;
    init.nombreabeilles = "0";

    init.switch = true;
    return init ;

  }

  const ValidationSchema = yup.object().shape({

  });


  const FormonSubmit = (data) => {
    const mComptage = new Object();
    let laDate = dayjs(data.dateS).format('YYYY-MM-DD');
    let methode = data.methode;
    let nombreabeilles = data.nombreabeilles;
  
    //mComptage.colonie = colonie.id;
    contenuPickers.date = data.date;
    let lesComptages = [];

    data.colonies.forEach((colonie) => {

      if (colonie.nombrevarroas > 0) {
        const leComptage = new Object();
        leComptage.colonie = colonie.id;
        leComptage.date = laDate;
        leComptage.methode = methode;
        leComptage.nombreabeilles = nombreabeilles;
        leComptage.nombrevarroas = colonie.nombrevarroas;
        
        lesComptages.push(leComptage);
      }
    });
    console.log(lesComptages);
    creeMultComptage(lesComptages)
      .then(() => { dispatch(setAffNewMultiComptage(false)) })
  };

  const abeilleComptage = (comptage) => {
    const laMethode = contenuPickers.listTypComptage.find(item => item.id === comptage);
    if (typeof (laMethode) != "undefined") { return laMethode.indNombreAbeilles };
    return false;
  };

  return (
    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Box sx={{
          width: 500, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2,
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>



                <Typography sx={{ fontSize: 30, }} align='center'>Comptages du Rucher {leRucher.nom}</Typography>
                <Divider />
                <p></p>
                <Grid container>
                  <Grid xs={6} sx={{ pt: 1, pl: 1, color: grey[600], fontSize: 15 }} display="flex">
                    <Typography style={{ flex: 1 }}>{i18n.t('comptage.date')}</Typography>
                  </Grid>
                  <Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                      <DesktopDatePicker
                        value={values.dateS}
                        onChange={(newValue) => { setFieldValue("dateS", newValue || values.dateS); }}
                        renderInput={(params) =>
                          <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Divider />



                <Field component={CustomPicker}
                  liste={contenuPickers.listTypComptage} itemKey='id' itemLabel='Méthode '
                  ModeModif={false} name='methode'
                  selectedValue={values.methode}
                  langue={i18n.locale} label={i18n.t('comptage.methode')}
                  handleChange={(itemIndex) => handleChange('methode')(itemIndex)}
                />
                  {abeilleComptage(values.methode) &&
                  <Field component={CustomInput} name="nombreabeilles" label={i18n.t('comptage.nombreabeilles')}
                    placeholder={i18n.t('comptage.nombreabeilles')} required={true} /> }
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Typography style={{ flex: 2 }} sx={{ textDecoration: 'underline' }}>{i18n.t('ruchers.nom')}</Typography>
                  <Typography style={{ flex: 2 }} sx={{ textDecoration: 'underline' }}>{i18n.t('ruchers.format')}</Typography>
                  <Typography style={{ flex: 2 }} sx={{ textDecoration: 'underline' }}>{i18n.t('comptage.nombrevarroas')}</Typography>

                </Box>
                {values.colonies.map((colonie, index) => (
                  <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} key={colonie.id}>

                    <Typography style={{ flex: 2 }}>{colonie.nom} </Typography>
                    <Typography style={{ flex: 2 }}>{typeRuche(colonie)} </Typography>
                    <Box style={{ flex: 2 }}>
                      <Field component={CustomInputSsLabel} name={'colonies[' + index + '].nombrevarroas'}
                        label={i18n.t('comptage.nombrevarroas')} placeholder={i18n.t('comptage.nombrevarroas')} />
                    </Box>
                  </Box>
                ))}

                <Grid container display="flex" justifyContent="flex-end">
                  <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffNewMultiComptage(false)) }} >
                      <RestoreIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
              </>
            )}

        </Formik>

      </Box>
    </Modal>
    </>
  )
};
// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>

export default NewMultiComptage;