import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';


//import momentTimezone from "moment-timezone";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';


import { modifyColonieDel, createColonie } from '../services/accessApiRuchers';
import {
  getListRace, listColoniesdeApi, getListRuchersDeApi, listColoniesDelegation,
  getListRuchersDelegue, getColoniesDuRucher
} from '../../rucherModule/services/accessApiRuchers';

import { useDispatch, useSelector } from 'react-redux';
import { reineStore } from '../../store/reineslice';
import { treeStore } from '../../store/treeslice';
import {
  lesColoniesDeApiStore, lesColoniesDelegationStore, lesRuchersDeApiStore, lesRuchersDelegationStore,
  lesColoniesDuRucherStore
} from '../../store/ruchersslice';
import { laColonieStore } from '../../store/ruchersslice';


//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomButton from '../../components/CustomButton';

import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';
import ResumeColonie from './ResumeColonie';

import { setAffModColonie, setAffModDelColonie, setAffCreeColonie, setAffCreeRucher } from '../../store/displayRucherslice';
import { geoConicConformalRaw } from 'd3';
import { Construction } from '@mui/icons-material';


//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';
import i18n from '../services/i18n';
import { prepDate, dispDate } from '../../services/prepDate';


//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function ModDelColonie(props) {
  const {
    ...inputProps
  } = props
  const Moment = require('moment');

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(true);
  const { user } = useSelector(state => state.user);
  //const [lRuchers, setLRuchers] = useState([]);
  const [allFetch, setAllFetch] = useState();
  const { cPickers } = useSelector(state => state.cPicker);
  let contenuPickers = Object.assign({}, cPickers);

  contenuPickers = cPickers;
  console.log(contenuPickers);
  const [errorMessage, setErrorMessage] = useState('');
  const [locale, setLocale] = React.useState('fr');


  let itemApi = user.id;
  const langue = user.langage;

  //const colonie = new Object();
  const colonie = useSelector(state => state.ruchers).laColonie;
  const lRuchers = useSelector(state => state.ruchers).lesRuchersTravail;
  var modeDuplic = false;
  /*
    useEffect(() => {
      let mounted = true;
      setAllFetch(false);
      Promise.all([getListRuchersDeApi(itemApi)])
        .then(async ([items]) => {
          { if (mounted) { setLRuchers(items) } };
          { if (mounted) { setAllFetch(true); } }
        });
  
      return () => mounted = false;
  
  
    }, []);
  */


  const Initialisation = () => {
    const init = new Object();
    init.dateS = new Date();
    init.motifsuppression = "";

    return init
  };

  const ValidationSchema = yup.object().shape({
    dateS: yup
      .date()
      .required('date obligatoire'),
    motifsuppression: yup
      .string()
      .matches(/^(?!-1$)/)
      .required(i18n.t('error.obligatoire')),
  });

  const handleLoadingError = (res) => {
    if (res.error === 401) {
      console.log('login nécessaire');
    } else {
      setErrorMessage(res.message);
    };
  }

  const FormonSubmit = (data) => {
    var mColonie = new Object ();
     mColonie = { ...colonie };


    mColonie.datesuppression = dayjs(data.dateS).format('YYYY-MM-DD');
    mColonie.motifsuppression = data.motifsuppression;
    console.log('AVT MODIFY');
    modifyColonieDel(mColonie)
      .then(() => {
        mColonie.nomRucher = detNomRucher(parseInt(mColonie.rucher));
        dispatch(laColonieStore(mColonie));
        Promise.all([listColoniesdeApi(itemApi), listColoniesDelegation(itemApi), getListRuchersDeApi(itemApi),
        getListRuchersDelegue(itemApi), getColoniesDuRucher(mColonie.rucher)])
          .then(async ([coloniesdeApi, coloniesDelegation, ruchersDeApi, ruchersDelegation,
            coloniesDuRucher]) => {
            dispatch(lesColoniesDeApiStore(coloniesdeApi));
            dispatch(lesColoniesDelegationStore(coloniesDelegation));
            dispatch(lesRuchersDeApiStore(ruchersDeApi));
            dispatch(lesRuchersDelegationStore(ruchersDelegation));
            dispatch(lesColoniesDuRucherStore(coloniesDuRucher));
            dispatch(setAffModDelColonie(false));
          }
          )
      })
      .catch(handleLoadingError);
  };


  const detNomRucher = (idRuc) => {
    const rucher = lRuchers.find(iruc => iruc.id === idRuc);
    if ((typeof (rucher) != "undefined")) { return rucher.nom };
    return '';
  };

  const determSuppression = (optionsColonie) => {
    let lesChoix = optionsColonie.actions.PUT.motifsuppression.choices;
    console.log(lesChoix);
    let tabChoix = [];
    let ind = 0;
    for (var choix of lesChoix) {
      tabChoix.push({ label: choix.display_name, id: choix.value });
      ind++;
    };
    return tabChoix
  };


  return (
    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Box sx={{
          width: 500, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2,
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>
                <Typography align='center' variant='h6'>Archivage de la colonie</Typography>
                <ResumeColonie colonie={colonie} langue={langue} />
                <br />
                <Divider />
                <br />
                <Grid container>
                  <Grid xs={6}>
                    Date
                  </Grid>
                  <Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>

                      <DesktopDatePicker
                        value={values.dateS}
                        onChange={(newValue) => {
                          setFieldValue("dateS", newValue || values.dateS);
                        }}
                        renderInput={(params) => <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Field component={CustomPicker}
                  label={i18n.t('colonie.motifsuppression')}
                  liste={determSuppression(contenuPickers.optCol)} itemKey='id' itemLabel='label'
                  ModeModif={true}
                  selectedValue={values.motifsuppression}
                  name="motifsuppression"
                  NomListObjet="label"
                  handleChange={(itemIndex) => handleChange('motifsuppression')(itemIndex)}
                  labD={6} fieldD={6} 
                />



                <Box style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                  {errorMessage ? (<Typography>{errorMessage}</Typography>) : null}

                </Box>

                <Grid container display="flex" justifyContent="flex-end">
                  <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffModDelColonie(false)) }} >
                      <RestoreIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
              </>
            )}

          </Formik>

        </Box>
      </Modal>
    </>);
}

//<Button onClick={() => dispatch(toggleAffReine()) } >Annuler</Button>


export default ModDelColonie;
