import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';


import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import { red, amber, grey } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';


import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';

import CustomTextStd2 from '../../components/CustomTextStd2';

import {
  getLastRecolte, getLastVisite, getLastSoin, getLastComptage, getLastObservation, getLastDemenagement, getDetColonie,
  getListTypPb, getListTypCR, getListTypMedoc, getListUniNourri, getOptionsVisite, getFirstVisite,
  getFirstColonie, listColoniesdeApi, getListRuchersDeApi, listColoniesDelegation, getListTravauxAFaire,
  getLastHausseVisites, getColoniePhoto
} from '../services/accessApiRuchers';


import { randomId } from '@mui/x-data-grid-generator';

// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import { setAffColonie, setAffCreeRucher, setAffCreeColonie } from '../../store/displayRucherslice'
import {
  laColonieStore, laColoniePhotoStore, laColoniePhotoRevoke, laDerniereVisiteStore, laVisiteStore,
  leDernierSoinStore, leSoinStore,
  leDernierComptageStore, leComptageStore,
  lesTravauxAFaireStore, 

  laDerniereRecolteStore, laRecolteStore,
  laDerniereObservationStore, lObservationStore, 
  lesColoniesDeApiStore, lesRuchersTravailStore, 
  apiProprietaireStore, lesColoniesDelegationStore,
  lesHausseVisitesStore
} from '../../store/ruchersslice'
import { DisplaySettingsRounded } from '@mui/icons-material';
import i18n from '../services/i18n';
import { dispDate , dispDate6} from '../services/prepDate';
import { bSante } from '../services/bSante';

// padding 0 à l'intérieur des tabulations + font de datagrid
const useStyles = makeStyles({
  root: {
    '& Div' : {
      padding :0, 
    },    
  },
  grey: {
    color:grey[500],
    backgroundColor:grey[100],
  },
  black: {
    color:"black",
  }    
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function AfficheLesColoniesdeApi() {

  const { user } = useSelector(state => state.user);
  const langue = user.langage;
  let itemApi = user.id;


  const leslignes = (items) => {
    var lignes = [];
    var ind = 0;
    for (var colonie of items) {
      lignes.push({
        'id': colonie.id, 'colonie': colonie.nom, 'rucher': colonie.nomRucher,
        'sante': colonie.get_last_sante, 
        'date': (colonie.get_last_date_visite ) ? dispDate6(colonie.get_last_date_visite, langue) : "",
        'motSuppression' :colonie.motifsuppression

      })
      ind++;
    };
    return lignes
  };


  const leslignesDelegation = (items) => {
    //console.log(items);
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
    var lignes = [];
    var ind = 0;
    for (var colonie of items) {
      lignes.push({
        'id': colonie.id, 'colonie': colonie.nom, 'rucher': colonie.nomRucher,
        'sante': colonie.get_last_sante, 
        'date': (colonie.get_last_date_visite ) ? dispDate6(colonie.get_last_date_visite, langue) : "",
        'apiculteur': colonie.get_apiculteur_nom, 'apiId': colonie.get_apiculteur,
        'motSuppression' :colonie.motifsuppression

        
      });
      console.log(colonie.get_apiculteur);
      ind++;
    };
    return lignes
  };  
  const dispatch = useDispatch()
  const [valTab, setValTab] = useState(0);

  const [allFetch, setAllFetch] = useState();
  const [rows, setRows] = useState();
  const [rowsDelegation, setRowsDelegation] = useState();

  const { lesColoniesDeApi, lesColoniesDelegation } = useSelector(state => state.ruchers);

  useEffect(() => {
    console.log('use effetc');
    let mounted = true;
    setAllFetch(false);
    if (mounted) {
      setRows(leslignes(lesColoniesDeApi));
      setRowsDelegation(leslignesDelegation(lesColoniesDelegation));
      setAllFetch(true);
      //console.log(lesColoniesDeApi);
    }
    return () => { mounted = false; setAllFetch(false); };

  }, [lesColoniesDeApi, lesColoniesDelegation]);

  const [idr, setIdr] = useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [messageErreur, setMessageErreur] = useState();

  const handleChange = (event, newValue) => {
    setValTab(newValue);
  };



  const handleLoadingError = (res) => {
    console.log('erreur');
    console.log(res);
  };

  const nouveauRucher = () => {
    dispatch(setAffCreeRucher(true));
  };

  const nouvelleColonie = () => {
    getListRuchersDeApi(itemApi)
    .then ((ruchers) => {
      dispatch(apiProprietaireStore (itemApi));

      dispatch(lesRuchersTravailStore(ruchers));
      dispatch(setAffCreeColonie(true));
    })

  };



  const handleEditClick = (idColonie, douJeviens) => {
    let proprietaireDeLaRuche = 0;
    dispatch(laColoniePhotoRevoke());
    if (douJeviens == 'delegat') {
      const colo = rowsDelegation.find(co => co.id == idColonie);
      proprietaireDeLaRuche = colo.apiId;
    }
    else {
      proprietaireDeLaRuche = itemApi;
    }
    Promise.all([getDetColonie(JSON.stringify(idColonie)), getLastRecolte(JSON.stringify(idColonie)), getLastVisite(JSON.stringify(idColonie)),
    getLastSoin(JSON.stringify(idColonie)),  getLastComptage(JSON.stringify(idColonie)), getLastObservation(JSON.stringify(idColonie)), getLastDemenagement(JSON.stringify(idColonie)),
    getListTravauxAFaire(idColonie), getLastHausseVisites(idColonie), getColoniePhoto(idColonie)])
      .then(([laColonie, itemRecolte, itemVisite, itemSoin, itemComptage, itemObservation, itemDemenagement, lesTravAF, 
        hausseVisite, iPhoto]) => {
        dispatch(laColonieStore(laColonie)); dispatch(laColoniePhotoStore(iPhoto.photo));
        dispatch(laDerniereVisiteStore(itemVisite)); dispatch(laVisiteStore(itemVisite));
        dispatch(setAffColonie(true));
        dispatch(leDernierSoinStore(itemSoin)); dispatch(leSoinStore(itemSoin));
        dispatch(leDernierComptageStore(itemComptage)); dispatch(leComptageStore(itemComptage));
        dispatch(laDerniereObservationStore(itemObservation)); dispatch(lObservationStore(itemObservation));
        dispatch(laDerniereRecolteStore(itemRecolte)); dispatch(laRecolteStore(itemRecolte));
        dispatch(apiProprietaireStore (proprietaireDeLaRuche));
        dispatch(lesTravauxAFaireStore(lesTravAF));
        dispatch(lesHausseVisitesStore(hausseVisite));

        setMessageErreur('');
        //console.log('fini !!!!');
      })
      .catch((res) => handleLoadingError(res))   ;

    //console.log(idColonie)
  };


  const columns = [
    // { field: 'id', headerName: 'id', width: 150 },
    { field: 'colonie', headerName: 'Colonie', editable: true, flex: 25 },
    { field: 'rucher', headerName: 'Rucher', editable: true, flex :40 },
    { field: 'date', headerName: 'Visitée le', editable: true, flex : 20 },
    {
      field: 'sante',
      headerName: 'sante',
      flex : 15,

      renderCell: (params) => (
        <Box sx={{ width: 30 }} bgcolor={bSante(params.row.sante)} >
          &nbsp;
        </Box>
      ), },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id, 'proprio')}
            color="inherit"
          />,
    ]; }, }, ];


  const columnsDelegation = [
    // { field: 'id', headerName: 'id', width: 150 },
    { field: 'colonie', headerName: 'Colonie', editable: true, flex: 100 },
    { field: 'rucher', headerName: 'Rucher', editable: true, flex: 100 },
    { field: 'apiculteur', headerName: 'Apiculteur', editable: true, flex: 100 },

    { field: 'date', headerName: 'Visitée le', editable: true, flex: 80},
    {
      field: 'sante',
      headerName: 'sante',
      flex : 50, 
      renderCell: (params) => (
        <Box sx={{ width: 30 }} bgcolor={bSante(params.row.sante)} >
          &nbsp;
        </Box>
      ), },
    {
      field: 'actions',
      type: 'actions',
      width: 50,
      getActions: ({ id}) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id,'delegat')} 
            color="inherit"
          />,
    ]; }, }, ];

  const setRefresh = () => {
    Promise.all([listColoniesdeApi(itemApi), listColoniesDelegation(itemApi)])
      .then(([coloniesdeApi, coloniesDelegation]) => { 
        dispatch(lesColoniesDeApiStore(coloniesdeApi)) ;
        dispatch(lesColoniesDelegationStore(coloniesDelegation)) ;
      })
  }

  const classes = useStyles();



  return (

    <>
      {allFetch && (
        <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
              <Tabs value={valTab} onChange={handleChange} aria-label="basic tabs example">
                <Tab label="Mes Colonies" {...a11yProps(0)} />
                <Tab label="Mes Délégations" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={valTab} index={0} className={classes.root}>
            <div style={{ height: 600, width: '100%' }}  >
              <DataGrid
                columns={columns}
                rows={rows}
                editMode="row"
                rowHeight={30}
                rowModesModel={rowModesModel}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) => {return params.row.motSuppression ==  null ? classes.black : classes.grey}}

                experimentalFeatures={{ newEditingApi: true }}
                sx={{ m: 0}}
                componentsProps={{
                  panel: { sx: { top: '-70px !important'}, }
                }}
              />
             </div> 
             <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Button variant="text" endIcon={<AddCircleOutlineIcon />} onClick={() => { nouveauRucher(); }}>
              Nouveau Rucher
            </Button>
            <Button variant="text" endIcon={<AddCircleOutlineIcon />} onClick={() => { nouvelleColonie(); }}>
              Nouvelle Colonie
            </Button>            
          </Box>             
            </TabPanel>
            <TabPanel value={valTab} index={1} className={classes.root}>
            <div style={{ height: 600, width: '100%' }}>
              <DataGrid
                columns={columnsDelegation}
                rows={rowsDelegation}
                editMode="row"
                rowHeight={30}
                rowModesModel={rowModesModel}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) => {return params.row.motSuppression ==  null ? classes.black : classes.grey}}

                experimentalFeatures={{ newEditingApi: true }}
                sx={{ m: 1 }}
                componentsProps={{
                  panel: { sx: { top: '-70px !important', }, }
                }}
              />
             </div> 
            </TabPanel>
            {messageErreur ? <p>{messageErreur}</p> : null}


        </Paper>
      )}
    </>
  )
};
// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>

export default AfficheLesColoniesdeApi;