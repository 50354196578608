import React, { useState, useEffect, } from 'react';

import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';

import ListEleveur from '../components/listEleveur'
import ChercheReine from '../components/chercheReine'
import AfficheReine from '../components/AfficheReine'
import ModifieReine from '../components/ModifieReine'
import AfficheGenealogie from '../components/AfficheGenealogie'
import {useSelector, useDispatch} from 'react-redux'
import MenuBar from '../../components/MenuBar'
import { setTitreBar, setAffLogin } from '../../store/displayslice';
import { login, retrieveApiculteurTotal, postPasswordReset } from '../../userModule/services/authentification';
import { userStore } from '../../store/userslice';






function EleveurScreen() {
 //const { affReine, affGenealogie } = useSelector(state => state.display);
 const dispatch = useDispatch()   ;

 const { user } = useSelector(state => state.user);

 dispatch(setTitreBar('ELEVEURS'));

 useEffect(() => {
  console.log('homme sreeen - useeffect');
  console.log(user);
  if (!user) {

    const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;
    console.log(token);    
    if (token) {
      Promise.all([retrieveApiculteurTotal(), ])
        .then(async ([userValues, ]) => {
          console.log(userValues);
          dispatch(userStore(userValues));
          //dispatch(apiProprietaireStore (userValues.id));
          //dispatch(setAffVisite(false));

          //dispatch(setAffReine(true));
          //toggleAff();
          //navigate('/reine');
        })


    }


  }

}, []);

return (
  <>
      <MenuBar />


  <ListEleveur></ListEleveur>
  </>
);
};


export default EleveurScreen;


