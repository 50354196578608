import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';

import { grey, amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';


import { } from '../services/accessApiRuchers';
import { getListRace } from '../services/accessApiRuchers';
import { modifySoin, createSoin, getLastSoin, getListSoin } from '../services/accessApiRuchers';


import { useDispatch, useSelector } from 'react-redux'
import { reineStore } from '../../store/reineslice'
import { treeStore } from '../../store/treeslice'

//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';
import CustomInput1 from '../../components/CustomInput1';
import CustomInput2 from '../../components/CustomInput2';
import CustomButton from '../../components/CustomButton';


import { setAffModSoin, setAffCreeSoin, listColoniesdeApi } from '../../store/displayRucherslice';
import { leSoinStore, leSoinRevoke, leDernierSoinStore, lesSoinsStore } from '../../store/ruchersslice'

import { geoConicConformalRaw } from 'd3';
import { Construction } from '@mui/icons-material';


//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';
import i18n from '../services/i18n';
import { prepDate, dispDate } from '../../services/prepDate';
import dayjs from 'dayjs';

//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function ModSoin(props) {
  const {
    ...inputProps
  } = props

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(true);
  const Moment = require('moment');
  const { cPickers } = useSelector(state => state.cPicker);

  let contenuPickers = Object.assign({}, cPickers);

  const soin = useSelector(state => state.ruchers).leSoin;
  console.log(soin);
  console.log();
  const colonie = useSelector(state => state.ruchers).laColonie;
  var modeModif = false;
  var modeDuplic = false;
  modeModif = useSelector(state => state.displayRucher).affModSoin;

  const Initialisation = () => {
    console.log('init');
    const init = new Object();

    if (modeModif) {
      init.dateS = prepDate(soin.date);
      { soin.qtmedicament ? init.qtmedicament = '' + soin.qtmedicament : init.qtmedicament = '' };
      { soin.medicament ? init.medicament = '' + soin.medicament : init.medicament = '' };
      { soin.qtnourrissment ? init.qtnourrissment = '' + soin.qtnourrissment : init.qtnourrissment = '' };
      { soin.unitenourrissement ? init.unitenourrissement = '' + soin.unitenourrissement : init.unitenourrissement = '' };
      { soin.nourrissement ? init.nourrissement = '' + soin.nourrissement : init.nourrissement = '' };
      { soin.typnourrissement ? init.typnourrissement = '' + soin.typnourrissement : init.typnourrissement = '' };
    }
    else {
      init.qtmedicament = '';
      init.medicament = '';
      init.qtnourrissment = '';
      init.unitenourrissement = '';
      init.typnourrissement = '';
      init.nourrissement = '';
    }
    if (contenuPickers.date) {
      init.dateS = prepDate(contenuPickers.date);
    } else {
      init.dateS = new Date();
    }
    return init

  };

  const ValidationSchema = yup.object().shape({
    qtmedicament: yup
      .string().ensure()
      .matches(/^\s*$|^\d{1,2}((\.|\,)\d{0,2})?$/, i18n.t('soins.errorMedi')),
    qtnourrissment: yup
      .string().ensure()
      .matches(/^$|^\d{1,4}((\.|\,)\d{0,1})?$/, i18n.t('soins.errorNourri')),
    typnourrissement: yup
      .string()
      .max(20, 'trop long'),
    medicament: yup
      .string(),
    unitenourrissement: yup
      .string(),
    typnourrissement: yup
      .string()
  });
  /*
      const ValidationSchema = yup.object().shape({
        qtmedicament: yup
          .string().ensure().when('qtnourrissment', { is: '', then: yup.string().required() })
          .matches(/^\s*$|^\d{1,2}((\.|\,)\d{0,2})?$/, i18n.t('soins.errorMedi')),
        qtnourrissment: yup
          .string().ensure().when('qtmedicament', { is: '', then: yup.string().required() })
          .matches(/^$|^\d{1,4}((\.|\,)\d{0,1})?$/, i18n.t('soins.errorNourri')),
        typnourrissement: yup
          .string()
          .max(20, 'trop long'),
        medicament: yup
          .string()
          .when('qtmedicament', { is: ((qtmedicament) => qtmedicament.length > 0), then: yup.string().required('obligatoire') }),
        unitenourrissement: yup
          .string()
          .when('qtnourrissment', { is: ((qtnourrissment) => qtnourrissment.length > 0), then: yup.string().required('obligatoire') }),
        typnourrissement: yup
          .string()
          .when('qtnourrissment', { is: ((qtnourrissment) => qtnourrissment.length > 0), then: yup.string().required('obligatoire') })
      }, [['qtmedicament', 'qtnourrissment']],
        [['qtmedicament', 'medicament']],
        [['qtnourrissment', 'unitenourrissement']],
        [['qtnourrissment', 'typnourrissement']]); */

  const FormonSubmit = (data) => {
    const mSoin = new Object();
    mSoin.date = dayjs(data.dateS).format('YYYY-MM-DD');

    mSoin.qtmedicament = data.qtmedicament.replace(',', '.');
    mSoin.medicament = data.medicament;
    mSoin.qtnourrissment = data.qtnourrissment.replace(',', '.');
    mSoin.unitenourrissement = data.unitenourrissement;
    mSoin.typnourrissement = data.typnourrissement;
    mSoin.nourrissement = data.nourrissement;

    mSoin.colonie = colonie.id;
    contenuPickers.date = data.date;

    if (modeModif) {
      mSoin.id = soin.id;
      modifySoin(mSoin)
        .then(() => {
          Promise.all([getLastSoin(JSON.stringify(mSoin.colonie)), getListSoin(JSON.stringify(mSoin.colonie))])
            .then(([localeSoin, lesSoins]) => {
              dispatch(leDernierSoinStore(localeSoin));
              dispatch(leSoinRevoke());
              dispatch(lesSoinsStore(lesSoins));
            })
            .catch((err) => { console.log('erreur !!! mod'); console.log(err) });
          dispatch(setAffModSoin(false));
          dispatch(leSoinStore(mSoin))
        })
      // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher })});
    } else {
      createSoin(mSoin)
        .then((item) => {
          Promise.all([getLastSoin(JSON.stringify(mSoin.colonie)), getListSoin(JSON.stringify(mSoin.colonie))])
            .then(([localeSoin, lesSoins]) => {
              dispatch(leDernierSoinStore(localeSoin));
              dispatch(leSoinRevoke());
              dispatch(lesSoinsStore(lesSoins));
            })
            .catch(console.log('erreur !!!'));
          dispatch(leSoinStore(mSoin)); dispatch(setAffCreeSoin(false));
        })
      // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher})});
    };
  };



  return (
    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Box sx={{
          width: 500, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2,
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>

                <Typography sx={{ fontSize: 30, }} align='center'>{i18n.t('soins.colonie')} : {colonie.nom} </Typography>
                <Divider />
                <p></p>

                <Grid container alignItems='center'>
                  <Grid xs={3} sx={{ pt: 1, pl: 1, color: grey[600], fontSize: 15 }} display="flex">
                    <Typography style={{ flex: 1 }}>{i18n.t('soins.soinDu')}</Typography>
                  </Grid>
                  <Grid xs={3}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                      <DesktopDatePicker
                        value={values.dateS}
                        onChange={(newValue) => { setFieldValue("dateS", newValue || values.dateS); }}
                        renderInput={(params) =>
                          <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={6}></Grid>

                </Grid>
                <p></p>

                <Divider />



                <Grid container>
                  <Typography >{i18n.t('soins.traitement')} :</Typography>
                </Grid>
                <Grid container alignItems='center'>
                  <Grid xs={3}></Grid>

                  <Grid xs={3}>
                    <Field component={CustomInput} name='qtmedicament' placeholder={i18n.t('soins.qtéMédoc')} labD={0} fieldD={12} />
                  </Grid>
                  <Grid xs={6}>

                    <Field component={CustomPicker}
                      liste={contenuPickers.listMedoc} itemKey='id' itemLabel='nom'
                      ModeModif={modeModif} name='medicament'
                      selectedValue={values.medicament}
                      langue={i18n.locale} labD={1} fieldD={11}
                      handleChange={(itemIndex) => handleChange('medicament')(itemIndex)}
                    />

                  </Grid>
                  <Grid xs={0}></Grid>


                  <Grid style={{ flex: 1 }}></Grid>
                </Grid>
                <p></p>
                <Divider />
                <Grid container>
                  <Typography >{i18n.t('soins.nourrissement')} :</Typography>
                </Grid>
                <Grid container alignItems='center' justifyContent='flex-start'>
                  <Grid xs={3}>

                    <Typography>Combien</Typography>
                  </Grid>
                  <Grid xs={3}>
                    <Field component={CustomInput} name='qtnourrissment' placeholder={i18n.t('soins.quantitéNourrissement')}
                      llabD={0} fieldD={12} />
                  </Grid>
                  <Grid xs={2}>
                    <Field component={CustomPicker}
                      liste={contenuPickers.listUniNourri} itemKey='id' itemLabel='nom'
                      ModeModif={modeModif} name='unitenourrissement'
                      selectedValue={values.unitenourrissement}
                      langue={i18n.locale}
                      handleChange={(itemIndex) => handleChange('unitenourrissement')(itemIndex)}
                    />
                  </Grid>
                  <Grid xs={4}></Grid>

                </Grid>
                <Grid container alignItems='center'>
                  <Grid xs={3}>
                    <Typography>Quoi</Typography>
                  </Grid>
                  <Grid contaner flex xs={9} flexDirection='column' justifyContent='flex-start'>
                    <Grid container>
                      <Grid xs={7}>
                        <Field component={CustomPicker}
                          liste={contenuPickers.listNourri} itemKey='id' itemLabel='nom'
                          ModeModif={modeModif} name='nourrissement'
                          selectedValue={values.nourrissement}
                          langue={i18n.locale} labD={0} fieldD={12}
                          handleChange={(itemIndex) => handleChange('nourrissement')(itemIndex)}
                        />
                      </Grid>
                      <Grid xs={5}></Grid>
                    </Grid>
                    <Field component={CustomInput} name='typnourrissement' placeholder={i18n.t('soins.typeNourrissement')}
                      labD={0} fieldD={12} />
                  </Grid>
                  <Grid xs={0}></Grid>

                </Grid>

                <Grid container display="flex" justifyContent="flex-end">
                  <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffModSoin(false)); dispatch(setAffCreeSoin(false)) }} >
                      <RestoreIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
              </>
            )}

          </Formik>

        </Box>
      </Modal>
    </>);
}

//<Button onClick={() => dispatch(toggleAffReine()) } >Annuler</Button>


export default ModSoin;
