import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';


//import momentTimezone from "moment-timezone";
import dayjs, { Dayjs } from 'dayjs';
import 'dayjs/locale/fr';


import { modifyColonie, createColonie } from '../services/accessApiRuchers';
import { getListRace, listColoniesdeApi, getListRuchersDeApi, listColoniesDelegation,
  getListRuchersDelegue, getColoniesDuRucher, getDetRucher} from '../../rucherModule/services/accessApiRuchers';

import { useDispatch, useSelector } from 'react-redux';
import { reineStore } from '../../store/reineslice';
import { treeStore } from '../../store/treeslice';
import { lesColoniesDeApiStore , lesColoniesDelegationStore, lesRuchersDeApiStore, lesRuchersDelegationStore,
  lesColoniesDuRucherStore, leRucherStore} from '../../store/ruchersslice';
import { laColonieStore } from '../../store/ruchersslice';


//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomButton from '../../components/CustomButton';

import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';

import { setAffModColonie, setAffCreeColonie, setAffCreeRucher } from '../../store/displayRucherslice';
import { geoConicConformalRaw } from 'd3';
import { Construction } from '@mui/icons-material';


//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';
import i18n from '../services/i18n';
import { prepDate, dispDate } from '../../services/prepDate';


//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function ModColonie(props) {
  const {
    ...inputProps
  } = props
  const Moment = require('moment');

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(true);
  const { user } = useSelector(state => state.user);
  //const [lRuchers, setLRuchers] = useState([]);
  const [allFetch, setAllFetch] = useState();
  const { cPickers } = useSelector(state => state.cPicker);
  let contenuPickers = Object.assign({}, cPickers);

  contenuPickers = cPickers;
  const [errorMessage, setErrorMessage] = useState('');
  const [locale, setLocale] = React.useState('fr');
  const [titre, setTitre] = useState('Création');


  let itemApi = user.id;
  const langue = user.langage;

  //const colonie = new Object();
  const colonie = useSelector(state => state.ruchers).laColonie;
  const lRuchers = useSelector(state => state.ruchers).lesRuchersTravail;
  var modeModif = false;
  var modeDuplic = false;
  modeModif = useSelector(state => state.displayRucher).affModColonie;
  var modeCreat = useSelector(state => state.displayRucher).affCreeColonie;
  if (modeModif && modeCreat) {
    modeDuplic = true;
  };

  useEffect(() => {
    let mounted = true;
    if (mounted) {
    modeModif && setTitre("Modification");
    if (modeModif && modeCreat) {
      setTitre('Duplication');
    };
  };
    return () => mounted = false;


  }, [modeModif, modeCreat]);


  const Initialisation = () => {
    const init = new Object();
    console.log()
    if (modeModif || modeDuplic) {
      modeDuplic ? init.nom = "" : init.nom = colonie.nom;
      init.emplacement = colonie.emplacement;

      console.log(colonie.filiation) ;
      { (colonie.filiation == null ) ? init.filiation = '' : init.filiation = '' + colonie.filiation };

      init.typeruche = '' + colonie.typeruche;
      init.marquage = '' + colonie.marquage;
      init.race = '' + colonie.race;
      init.rucher = '' + colonie.rucher;
      init.dateS = prepDate(colonie.date);
      { (colonie.numreine) ? init.numreine = '' + colonie.numreine : init.numreine = '' };
      init.originereine = colonie.originereine;
      init.nomreine = colonie.nomreine;
      init.fecondationreine = colonie.fecondationreine;
      init.ancetrereine = colonie.ancetrereine;
    }
    else {
      init.nom = "";
      init.emplacement = "";
      init.filiation = "";
      init.typeruche = "";
      init.marquage = "";
      init.race = "";
      init.rucher = "";
      init.dateS = new Date();
      init.numreine = "";
      init.originereine = "";
      init.nomreine = "";
      init.fecondationreine = "";
      init.ancetrereine = "";
    };
    return init


  }

  const ValidationSchema = yup.object().shape({
    nom: yup
      .string()
      .min(1)
      .max(25, 'trop long')
      .required('Nom obligatoire'),
      emplacement: yup
      .string()
      .max(25, '25 maxi')
      .nullable(true),      
    filiation: yup
      .string()
      .matches(/^(?!-1$)/)
      .nullable() ,
    //    .required('Obligatoire'),  
    typeruche: yup
      .string()
      .matches(/^(?!-1$)/)
      .required('Obligatoire'),
    marquage: yup
      .string()
      .matches(/^(?!-1$)/)
      .required('Obligatoire'),
    race: yup
      .string()
      .matches(/^(?!-1$)/)
      .required('Obligatoire'),
    rucher: yup
      .string()
      .matches(/^(?!-1$)/)
      .required('Obligatoire'),
    //  dateS: yup.mixed().required('aa'),
    numreine: yup
      .number()
      .typeError('Nombre'),

    originereine: yup
      .string()
      .max(15, '15 maxi')
      .nullable(true),
    nomreine: yup
      .string()
      .max(15, '15 maxi')
      .nullable(true),
    fecondationreine: yup
      .string()
      .max(25)
      .nullable(true),
    ancetrereine: yup
      .string()
      .max(15)
      .nullable(true)

  });

  const handleLoadingError = (res) => {
    if (res.error === 401) {
      console.log('login nécessaire');
    } else {
      setErrorMessage(res.message);
    };
  }

  const FormonSubmit = (data) => {
    //const colonie = new Object ();
    let mColonie = {};
    console.log(data);
    mColonie.rucher = data.rucher;
    mColonie.emplacement = data.emplacement;
    mColonie.nom = data.nom;
    mColonie.marquage = parseInt(data.marquage);
    mColonie.race = parseInt(data.race);
    mColonie.date = dayjs(data.dateS).format('YYYY-MM-DD');
    mColonie.numreine = data.numreine;
    mColonie.nomreine = data.nomreine;
    mColonie.originereine = data.originereine;
    mColonie.fecondationreine = data.fecondationreine;
    mColonie.ancetrereine = data.ancetrereine;
    mColonie.typeruche = parseInt(data.typeruche);
    mColonie.filiation = data.filiation;
    if (modeModif && !modeDuplic) {
      mColonie.id = colonie.id;
      modifyColonie(mColonie)
        .then(() => {
          mColonie.nomRucher = detNomRucher(parseInt(mColonie.rucher));
          dispatch(laColonieStore(mColonie));
          Promise.all([listColoniesdeApi(itemApi),listColoniesDelegation(itemApi), getListRuchersDeApi(itemApi), 
            getListRuchersDelegue(itemApi), getColoniesDuRucher(mColonie.rucher), getDetRucher(mColonie.rucher)])
            .then(async ([coloniesdeApi, coloniesDelegation, ruchersDeApi,ruchersDelegation,
                coloniesDuRucher, rucher]) => {
              dispatch(lesColoniesDeApiStore(coloniesdeApi));
              dispatch(lesColoniesDelegationStore(coloniesDelegation));
              dispatch(lesRuchersDeApiStore(ruchersDeApi));
              dispatch(lesRuchersDelegationStore(ruchersDelegation));
              dispatch(lesColoniesDuRucherStore(coloniesDuRucher)) ;
              dispatch(leRucherStore(rucher));
              dispatch(setAffModColonie(false));
            }
            )
        })
        .catch(handleLoadingError);
    } else {
      createColonie(mColonie)
        .then((repColonie) => {
          console.log(repColonie);
          mColonie.id = repColonie.id;
          mColonie.nomRucher = detNomRucher(parseInt(mColonie.rucher));

          dispatch(laColonieStore(mColonie));
          console.log('avt promise');
          console.log(mColonie);
          console.log(mColonie.rucher);
          Promise.all([listColoniesdeApi(itemApi), listColoniesDelegation(itemApi), getListRuchersDeApi(itemApi), 
            getListRuchersDelegue(itemApi), getColoniesDuRucher(mColonie.rucher), getDetRucher(mColonie.rucher)])
            .then(async ([coloniesdeApi, coloniesDelegation, ruchersDeApi,ruchersDelegation,
              coloniesDuRucher, rucher]) => {
              console.log('at dispatvh #####################################')
              console.log(coloniesDuRucher);
              dispatch(lesColoniesDeApiStore(coloniesdeApi));
              dispatch(lesColoniesDelegationStore(coloniesDelegation));
              dispatch(lesRuchersDeApiStore(ruchersDeApi));
              dispatch(lesRuchersDelegationStore(ruchersDelegation));
              dispatch(lesColoniesDuRucherStore(coloniesDuRucher)) ;  
              dispatch(leRucherStore(rucher));
                          
              console.log('avp dispat setaff mod');
              dispatch(setAffCreeColonie(false))
              dispatch(setAffModColonie(false));
            }
            )
        })
        .catch(handleLoadingError);

    }
  };

  const determFiliation = (optionsColonie) => {
    let lesChoix = optionsColonie.actions.PUT.filiation.choices;
    console.log(lesChoix);
    let tabChoix = [];
    let ind = 0;
    for (var choix of lesChoix) {
      tabChoix.push({ label: choix.display_name, id: choix.value });
      ind++;
    };
    return tabChoix
  };

  const detNomRucher = (idRuc) => {
    const rucher = lRuchers.find(iruc => iruc.id === idRuc);
    if ((typeof (rucher) != "undefined")) { return rucher.nom };
    return '';
  };

  const nouveauRucher = () => {
    console.log('nouveau rucher)');
    dispatch(setAffCreeRucher(true));
  };

  return (
    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Box sx={{
          width: 500, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2,
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>
              <Typography align='center' variant='h6'>{titre}</Typography>

                <Field component={CustomInput} name="nom" label={i18n.t('colonie.nom')} placeholder="nom colonie" required={true} />

                <Field component={CustomPicker}
                  label={i18n.t('colonie.rucher')}
                  liste={lRuchers} itemLabel='nom' itemKey='id'
                  ModeModif={modeModif}
                  selectedValue={values.rucher}
                  name="rucher"
                  handleChange={(itemIndex) => handleChange('rucher')(itemIndex)}
                  labD={6} fieldD={6} required={true}
                />
                      <Box style={{flexDirection: 'row',   display: 'flex', justifyContent: 'flex-end' }}>  

            <Button variant="text" endIcon={<AddCircleOutlineIcon />} onClick={() => { nouveauRucher(); }}>
              Nouveau Rucher
            </Button>    
           </Box>             
                {/*      <TextField
        id="date"
        label="Enruchement"
        type="date"
        defaultValue={values.dateS}
        sx={{ width: 220, backgroundColor:'white', height: 10 }}
        onChange={(event, date) => {setFieldValue("dateS", date || values.dateS)}} 

        InputLabelProps={{
          shrink: true,
        }}
      /> */}
      <Field component={CustomInput} name="emplacement" label={i18n.t('colonie.emplacement')} placeholder={i18n.t('colonie.emplacement')} />

                <Grid container>
                  <Grid xs={6} sx={{ pt: 1, pl: 1, color: grey[600], fontSize: 15 }} display="flex">
                    <Typography>{i18n.t('colonie.enruchement')}</Typography>
                    <Typography color={amber[900]}>&nbsp;*</Typography>

                  </Grid>
                  <Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>

                      <DesktopDatePicker
                        value={values.dateS}
                        onChange={(newValue) => {
                          setFieldValue("dateS", newValue || values.dateS);
                        }}
                        renderInput={(params) => <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>



                <Field component={CustomPicker}
                  label={i18n.t('colonie.format')}
                  liste={contenuPickers.typRuc} itemLabel='typeruche' itemKey='id'
                  ModeModif={modeModif}
                  selectedValue={values.typeruche}
                  NomListObjet="modeleruche"
                  name="typeruche"
                  handleChange={(itemIndex) => handleChange('typeruche')(itemIndex)}
                  labD={6} fieldD={6} required={true}
                />

                <Field component={CustomPicker}
                  label={i18n.t('colonie.race')}
                  liste={contenuPickers.listRac} itemLabel='race' itemKey='id'
                  ModeModif={modeModif}
                  selectedValue={values.race}
                  NomListObjet="race"
                  name="race"
                  handleChange={(itemIndex) => handleChange('race')(itemIndex)}
                  labD={6} fieldD={6} required={true}
                />

                <Field component={CustomPicker}
                  label={i18n.t('colonie.marquage')}
                  liste={contenuPickers.listMarc} itemLabel='race' itemKey='id'
                  ModeModif={modeModif}
                  selectedValue={values.marquage}
                  NomListObjet="couleur"
                  name="marquage"
                  handleChange={(itemIndex) => handleChange('marquage')(itemIndex)}
                  labD={6} fieldD={6} required={true}
                />

                <Field component={CustomPicker}
                  label={i18n.t('colonie.filiation')}
                  liste={determFiliation(contenuPickers.optCol)} itemLabel='label' itemKey='id'
                  ModeModif={modeModif}
                  selectedValue={values.filiation}
                  NomListObjet="label"
                  name="filiation"
                  handleChange={(itemIndex) => handleChange('filiation')(itemIndex)}
                  labD={6} fieldD={6}
                />


                <Field component={CustomInput} name="numreine" label={i18n.t('colonie.numreine')} placeholder={i18n.t('colonie.numreineNombre')} />

                <Field component={CustomInput} name="nomreine" label={i18n.t('colonie.nomreine')} placeholder={i18n.t('colonie.nomreinemat')} />

                <Field component={CustomInput} name="originereine" label={i18n.t('colonie.orig reine')} placeholder={i18n.t('colonie.orig reine')} />

                <Field component={CustomInput} name="fecondationreine" label={i18n.t('colonie.fécondation')} placeholder={i18n.t('colonie.modeDeFécondation')} />

                <Field component={CustomInput} name="ancetrereine" label={i18n.t('colonie.mère')} placeholder={i18n.t('colonie.mère')} />

                <Box style={{ flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-end' }}>
                  {errorMessage ? (<Typography>{errorMessage}</Typography>) : null}

                </Box>

                <Grid container display="flex" justifyContent="flex-end">
                  <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffModColonie(false)); dispatch(setAffCreeColonie(false)) }} >
                      <RestoreIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
              </>
            )}

          </Formik>

        </Box>
      </Modal>
    </>);
}

//<Button onClick={() => dispatch(toggleAffReine()) } >Annuler</Button>


export default ModColonie;
