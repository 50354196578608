import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import { getListEleveur, delEleveur, createEleveur, modifyEleveur } from '../services/accesApi';
import { useDispatch, useSelector } from 'react-redux'
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';

import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,

  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import { red } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Slide from '@mui/material/Slide';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { TransitionProps } from '@mui/material/transitions';
import {
  randomCreatedDate,
  randomTraderName,
  randomUpdatedDate,
  randomId,
} from '@mui/x-data-grid-generator';

//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';

import { listEleveurStore, setaffReine, setmodReine, setAffReine } from '../../store/eleveurslice'


import CustomTextStd from '../../components/CustomTextStd';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';

//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
function EditToolbar(props) {
  const { setRows, setRowModesModel } = props;
  //  const apiRef = useGridApiContext();

  const handleClick = () => {
    const id = randomId();
    console.log(typeof (id));
    //  console.log(rowModesModel);
    setRows((oldRows) => [{ id, isNew: true }, ...oldRows]);
    //    apiRef.current.MuiDataGrid-virtualScroller(scrollTop);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nom' },
    }));
    //  console.log(rowModesModel);
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Ajouter Eleveur (en première ligne de la première page)
      </Button>
    </GridToolbarContainer>
  );
}


EditToolbar.propTypes = {
  setRowModesModel: PropTypes.func.isRequired,
  setRows: PropTypes.func.isRequired,
};


function ListEleveur() {

  const dispatch = useDispatch()

  const [allFetch, setAllFetch] = useState();

  //const { listdesEleveurs } = useSelector(state => state.eleveur)
  //const [lesEleveurs, setLesEleveurs] = useState ()
  const [rows, setRows] = useState();
  const [open, setOpen] = useState(false);
  const [idr, setIdr] = useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [messageErreur, setMessageErreur] = useState();
  //   const apiRef = useGridApiContext();


  /*
  const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
      children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>,
  ) {
    return <Slide direction="up" ref={ref} {...props} />;
  });
*/
  /*  
 const Transition = React.forwardRef(function Transition(props, ref) {
    <Slide direction="up" ref={ref} {...props} />}
 );
 */




  const leslignes = (items) => {
    console.log('aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa');
    console.log(items);
    var lignes = [];
    var ind = 0;
    for (var eleveur of items) {
      lignes.push({ 'id': eleveur.id, 'nom': eleveur.nom, 'prenom': eleveur.prenom, 'departement': eleveur.departement });
      ind++;
    };
    console.log(lignes);
    return lignes
  };

  const handleLoadingError = (res) => {
    console.log('erreur');
    console.log(res);
  };

  useEffect(() => {

    console.log('useeffect');
    let mounted = true;
    setAllFetch(false);
    getListEleveur()
      .then(items => {
        if (mounted) {
          //  setLesEleveurs(items);
          dispatch(listEleveurStore(items));
          setRows(leslignes(items))
        }
        if (mounted) { setAllFetch(true); }

      })
      .catch(handleLoadingError);

    return () => { mounted = false; setAllFetch(false); };

  }, []);

  const handleCloseIgnore = () => {
    setOpen(false);
  };


  const handleDelError = (res) => {
    if (res.error !== 204) {
      console.log('pb suppression');
    }
  };

  const handleCloseAgree = () => {
    setOpen(false);
    delEleveur(idr)
      .then(() => { setRows((prevRows) => prevRows.filter((row) => row.id !== idr)); })
      .catch(handleDelError);


  };

  // ouverture de la boite de dialogue de confirmation      
  const deleteUser =
    (id) => () => {
      setIdr(id);
      setOpen(true);
    };

  const columns = [
    // { field: 'id', headerName: 'id', width: 150 },
    { field: 'nom', headerName: 'nom', editable: true, width: 150 },
    { field: 'prenom', headerName: 'prenom', editable: true, width: 150 },
    { field: 'departement', headerName: 'departement', editable: true, width: 150 },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }
        return [
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,

          <GridActionsCellItem
            icon={<DeleteIcon sx={{ color: red[500], fontSize: 30 }} />}
            label="Delete"
            onClick={deleteUser(id)}
          />,
        ];
      },
    },
  ];

  const handleEditClick = (id) => () => {
    console.log(id);
    console.log(rowModesModel);
    //        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit }, });
    //setRowModesModel({[id]: { mode: GridRowModes.Edit, ignoreModifications: false } });
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: 'nom' },
    });
    console.log(rowModesModel);
  };

  const handleSaveClick = (id) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };

  const handleCancelClick = (id) => () => {

    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });
    console.log(rowModesModel);

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow.isNew || typeof (id) !== "number") {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const handleRowEditStart = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const handleRowEditStop = (params, event) => {
    event.defaultMuiPrevented = true;
  };

  const processRowUpdate = (newRow, oldRow) => {
    setMessageErreur('');
    console.log("procerowupdte");
    const updatedRow = { ...newRow, isNew: false };
    console.log(updatedRow);
    if (typeof (updatedRow.id) === "number") {
      console.log("modif");
      modifyEleveur(updatedRow.id, updatedRow.nom, updatedRow.prenom, updatedRow.departement)
        .then((res) => {
          console.log(res);
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        })
        .catch((res) => {
          console.log(res.message); setMessageErreur(res.message);
          setRows(rows.map((row) => (row.id === newRow.id ? oldRow : row)));

          setRowModesModel({ [updatedRow.id]: { mode: GridRowModes.Edit, } });
        });
      ;



    } else {

      console.log("create");
      createEleveur(updatedRow.nom, updatedRow.prenom, updatedRow.departement)
        .then((res) => {
          console.log(res);
          updatedRow.id = res.id;
          setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
        })
        .catch((res) => {
          console.log(res.message); setMessageErreur(res.message);
          setRowModesModel({ [updatedRow.id]: { mode: GridRowModes.Edit } });
        });


    };

    return updatedRow;
  };





  return (
    <>
      {allFetch && (

        <Paper elevation={10} sx={{ m: 5 }}>
          <div style={{ height: 500, width: '100%' }}>

            <DataGrid
              columns={columns}
              rows={rows}
              editMode="row"
              rowHeight={30}
              rowModesModel={rowModesModel}
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEditStop}
              processRowUpdate={processRowUpdate}
              localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
              experimentalFeatures={{ newEditingApi: true }}
              components={{
                Toolbar: EditToolbar,
              }}
              componentsProps={{
                toolbar: { setRows, setRowModesModel },
              }}
              sx={{ m: 2 }}

            />
            {messageErreur ? <p>{messageErreur}</p> : null}
            <Dialog
              open={open}
              keepMounted
              onClose={handleCloseIgnore}
              aria-describedby="alert-dialog-slide-description"

            >
              <DialogTitle>{"Suppression d'un éleveur"}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-slide-description">
                  Les reines ne seront pas supprimées, mais l'éleveur ne
                  sera plus mentionné.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseIgnore}>Annuler</Button>
                <Button onClick={handleCloseAgree}>Supprimer</Button>
              </DialogActions>
            </Dialog>
          </div>
        </Paper>
      )
      }
    </>
  )
};
// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>

export default ListEleveur;