import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';


import { } from '../services/accessApiRuchers';
import { getListRace } from '../services/accessApiRuchers';
import { modifyRecolte, createRecolte, getLastRecolte, getListRecolte } from '../services/accessApiRuchers';


import { useDispatch, useSelector } from 'react-redux'
import { reineStore } from '../../store/reineslice'
import { treeStore } from '../../store/treeslice'

//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';
import CustomInput1 from '../../components/CustomInput1';
import CustomInput2 from '../../components/CustomInput2';
import CustomButton from '../../components/CustomButton';


import { setAffModRecolte, setAffCreeRecolte, listColoniesdeApi } from '../../store/displayRucherslice';
import { laRecolteStore, laDerniereRecolteStore, laRecolteRevoke, lesRecoltesStore } from '../../store/ruchersslice'

import { geoConicConformalRaw } from 'd3';
import { Construction } from '@mui/icons-material';


//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';
import i18n from '../services/i18n';
import { prepDate, dispDate } from '../../services/prepDate';
import dayjs from 'dayjs';

//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function ModRecolte(props) {
  const {
    ...inputProps
  } = props

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(true);
  const Moment = require('moment');
  const { cPickers } = useSelector(state => state.cPicker);

  let contenuPickers = Object.assign({}, cPickers);

  const Recolte = useSelector(state => state.ruchers).laRecolte;
  const colonie = useSelector(state => state.ruchers).laColonie;
  var modeModif = false;
  var modeDuplic = false;
  modeModif = useSelector(state => state.displayRucher).affModRecolte;

  const Initialisation = () => {
    console.log('init');
    const init = new Object();

    if (modeModif) {
      init.dateS = prepDate(Recolte.date);
      init.nbkgmiel = Recolte.nbkgmiel;
      Recolte.miel ? init.miel = Recolte.miel : init.miel = '';
      Recolte.lieu ? init.lieu = Recolte.lieu : init.lieu = '';

    }
    else {
      init.nbkgmiel = '';
      init.miel = "";

      if (contenuPickers.date) {
        init.dateS = prepDate(contenuPickers.date);
      } else {
        init.dateS = new Date();
      }
    }
    return init

  };

  const ValidationSchema = yup.object().shape({
    nbkgmiel: yup
      .string()
      .required('obligatoire')
      .matches(/^\d{1,2}((\.|\,)\d{0,1})?$/, i18n.t('récoltes.errnbkg')),
  });


  const FormonSubmit = (data) => {
    const mRecolte = new Object();
    mRecolte.date = dayjs(data.dateS).format('YYYY-MM-DD');
    mRecolte.nbkgmiel = data.nbkgmiel.replace(',', '.');
    mRecolte.miel = data.miel;
    mRecolte.lieu = data.lieu;

    mRecolte.colonie = colonie.id;
    contenuPickers.date = data.date;

    if (modeModif) {
      mRecolte.id = Recolte.id;
      modifyRecolte(mRecolte)
        .then(() => {
          Promise.all([getLastRecolte(JSON.stringify(mRecolte.colonie)), getListRecolte(JSON.stringify(mRecolte.colonie))])
            .then(([localaRecolte, lesRecoltes]) => {
              dispatch(laDerniereRecolteStore(localaRecolte));
              dispatch(laRecolteRevoke());
              dispatch(lesRecoltesStore(lesRecoltes));
            })
            .catch((err) => { console.log('erreur !!! mod'); console.log(err) });
          dispatch(setAffModRecolte(false));
          dispatch(laRecolteStore(mRecolte))
        })
      // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher })});
    } else {
      createRecolte(mRecolte)
        .then((item) => {
          Promise.all([getLastRecolte(JSON.stringify(mRecolte.colonie)), getListRecolte(JSON.stringify(mRecolte.colonie))])
            .then(([localaRecolte, lesRecoltes]) => {
              dispatch(laDerniereRecolteStore(localaRecolte));
              dispatch(laRecolteRevoke());
              dispatch(lesRecoltesStore(lesRecoltes));
            })
            .catch(console.log('erreur !!!'));
          dispatch(laRecolteStore(mRecolte)); dispatch(setAffCreeRecolte(false));
        })
      // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher})});
    };

  };



  return (
    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Box sx={{
          width: 500, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2,
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>



                <Typography sx={{ fontSize: 30, }} align='center'>{i18n.t('récoltes.colonie', { colonie: colonie.nom })} </Typography>
                <Divider />
                <p></p>
                <Grid container>
                  <Grid xs={6} sx={{ pt: 1, pl: 1, color: grey[600], fontSize: 15 }} display="flex">
                    <Typography style={{ flex: 1 }}>{i18n.t('récoltes.date')}</Typography>
                  </Grid>
                  <Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                      <DesktopDatePicker
                        value={values.dateS}
                        onChange={(newValue) => { setFieldValue("dateS", newValue || values.dateS); }}
                        renderInput={(params) =>
                          <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>



                <Field component={CustomInput} name="nbkgmiel" label={i18n.t('récoltes.kgMiel')}
                  placeholder={i18n.t('récoltes.kgMiel')} />


                <Field component={CustomInput} name="miel" label={i18n.t('récoltes.miel')} placeholder={i18n.t('récoltes.miel')} />

                <Field component={CustomInput} name="lieu" label={i18n.t('récoltes.lieu')} placeholder={i18n.t('récoltes.lieu')} />


                <Grid style={{ flex: 1 }}></Grid>


                <Grid container display="flex" justifyContent="flex-end">
                  <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffModRecolte(false)); dispatch(setAffCreeRecolte(false)) }} >
                      <RestoreIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
              </>
            )}

          </Formik>

        </Box>
      </Modal>
    </>);
}

//<Button onClick={() => dispatch(toggleAffReine()) } >Annuler</Button>


export default ModRecolte;
