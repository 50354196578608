
import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { getDetColonie } from '../services/accessApiRuchers';
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';

import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

import EditIcon from '@mui/icons-material/Edit';
import { red, amber, green } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';


import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';


import { randomId } from '@mui/x-data-grid-generator';

import i18n from '../services/i18n';


import CustomTextStd from '../../components/CustomTextStd';
import CustomTitre from '../../components/CustomTitre';
import CustomTextStd2 from '../../components/CustomTextStd2';
import ResumeVisite from './ResumeVisite';

import { delVisite, getLastVisite, getListVisite } from '../services/accessApiRuchers';



// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import { setAffColonie, setAffDelVisite, setAffModVisite, setAffCreeVisite, setAffLesVisites, setAffVisite } from '../../store/displayRucherslice'
import { laColonieStore, lesVisitesStore, laDerniereVisiteStore, laVisiteRevoke } from '../../store/ruchersslice'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
// pas de edit toolbar


const DelVisite = (props) => {
  const {
    ...inputProps
  } = props

  const dispatch = useDispatch()

  const [open, setOpen] = useState(true);
//  const [laVisite, setLavisite] = useState(visite);
  //console.log(laVisite);
  const [allFetch, setAllFetch] = useState(false);
  const { affDelVisite } = useSelector(state => state.displayRucher);
  const laVisite = useSelector(state => state.ruchers).laVisite;



  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const { user } = useSelector(state => state.user);

  const langue = user.langage;



  const handleCloseIgnore = () => {
    dispatch(setAffDelVisite(false));
  };


  const handleDelError = (res) => {
    if (res.error !== 204) {
      console.log('pb suppression');
    }
  };

  const handleCloseAgree = () => {
    delVisite(laVisite)
      .then(() => {
        Promise.all([getLastVisite(JSON.stringify(laVisite.colonie)), getListVisite(JSON.stringify(laVisite.colonie))])
        .then(([localeVisite, lesVisites]) => {
          dispatch(laDerniereVisiteStore(localeVisite));
          dispatch(laVisiteRevoke()); 
          dispatch(lesVisitesStore(lesVisites)); 

          dispatch(setAffVisite(false)); 
          dispatch(setAffDelVisite(false))
        })})
      .catch(console.log('erreur !!!'));
  };




  return (

    <>
      <Dialog
        open={affDelVisite}
        keepMounted
        onClose={handleCloseIgnore}
        aria-describedby="alert-dialog-slide-description"
        maxWidth='sm'
        fullWidth
      >
        <DialogTitle>{"Suppression d'une visite"}</DialogTitle>
        <DialogContent>
        <ResumeVisite visite={laVisite} langue={langue} />
        </DialogContent>

          <DialogContentText id="alert-dialog-slide-description">
            Confirmez-vous cette Suppression
          </DialogContentText>
        <DialogActions>
          <Button onClick={handleCloseIgnore}>Annuler</Button>
          <Button onClick={handleCloseAgree}>Supprimer</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DelVisite;