import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';


import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SortIcon from '@mui/icons-material/Sort';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import MedicationLiquidIcon from '@mui/icons-material/MedicationLiquid';
import BugReportIcon from '@mui/icons-material/BugReport';
import { GiHoneyJar, GiDrippingHoney } from 'react-icons/gi';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { red, amber, grey, orange, green } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';


import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';

import CustomTextStd2 from '../../components/CustomTextStd2';

import {
  getLastRecolte, getLastVisite, getLastSoin, getLastComptage, getLastObservation, getLastDemenagement, getDetColonie,
  getListTypPb, getListTypCR, getListTypMedoc, getListUniNourri, getOptionsVisite, getFirstVisite,
  getFirstColonie, listColoniesdeApi, getListRuchersDeApi, listColoniesDelegation, getColoniesDuRucher
} from '../services/accessApiRuchers';


import { randomId } from '@mui/x-data-grid-generator';

// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import {
  setAffColonie, setAffCreeRucher, setAffCreeColonie, setAffLesColoniesDuRucher,
  setAffNewMultiSoin, setAffNewMultiComptage, setAffNewMultiRecolte, setAffModRucher, setAffDelRucher,
  setAffTrieRucher
} from '../../store/displayRucherslice'
import {
  leRucherStore,
  laColonieStore, laDerniereVisiteStore, laVisiteStore,
  leDernierSoinStore, leSoinStore,
  laDerniereRecolteStore, laRecolteStore,
  laDerniereObservationStore, lObservationStore,
  lesColoniesDeApiStore, lesRuchersTravailStore,
  apiProprietaireStore, lesColoniesDelegationStore,
  lesColoniesDuRucherStore
} from '../../store/ruchersslice'
import { DisplaySettingsRounded } from '@mui/icons-material';
import i18n from '../services/i18n';
import { dispDate, dispDate6 } from '../services/prepDate';
import { bSante } from '../services/bSante';

const useStyles = makeStyles({
  root: {
    '& Div': {
      padding: 0,
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function AfficheLesRuchersdeApi() {

  const { user } = useSelector(state => state.user);
  const [afficheListe, setAfficheListe] = useState(true)
  const langue = user.langage;
  let itemApi = user.id;


  const leslignes = (items) => {

    var lignes = [];
    var ind = 0;
    for (var rucher of items) {
      lignes.push({
        'id': rucher.id, 'rucher': rucher.nom,
        'nbCol': rucher.get_nb_colonies,
      })
      ind++;
    };
    return lignes
  };


  const leslignesDelegation = (items) => {
    var lignes = [];
    var ind = 0;
    for (var rucher of items) {
      lignes.push({
        'id': rucher.id, 'rucher': rucher.nom,
        'nbCol': rucher.get_nb_colonies,
        'Apiculteur': rucher.get_apiculteur_nom,
        'api': rucher.apiculteur,

      });
      ind++;
    };
    return lignes
  };
  const dispatch = useDispatch()
  const [valTab, setValTab] = useState(0);

  const [allFetch, setAllFetch] = useState();
  const [rows, setRows] = useState();
  const [rowsDelegation, setRowsDelegation] = useState();
  const [idDuRucher, setIdDuRucher] = useState(null);
  const [douJeViensState, setDouJeViensState] = useState(null);

  const { lesRuchersDeApi, lesRuchersDelegation } = useSelector(state => state.ruchers);

  useEffect(() => {
    let mounted = true;
    setAllFetch(false);
    if (mounted) {
      setRows(leslignes(lesRuchersDeApi));
      setRowsDelegation(leslignesDelegation(lesRuchersDelegation));
      setAllFetch(true);
    }
    return () => { mounted = false; setAllFetch(false); };

  }, [lesRuchersDeApi, lesRuchersDelegation]);

  const [idr, setIdr] = useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [messageErreur, setMessageErreur] = useState();

  const handleChange = (event, newValue) => {
    setValTab(newValue);
  };

  const storeRucher = () => {
    let proprietaireDuRucher = 0;

    let rucher = new Object();

    if (douJeViensState == 'delegat') {
      rucher = lesRuchersDelegation.find(r => r.id == idDuRucher);
      proprietaireDuRucher = rucher.apiculteur;

    }
    else {
      proprietaireDuRucher = itemApi;
      rucher = lesRuchersDeApi.find(r => r.id == idDuRucher);
    };
    dispatch(apiProprietaireStore(proprietaireDuRucher));

    dispatch(leRucherStore(rucher));

  };


  const handleLoadingError = (res) => {
    console.log('erreur');
    console.log(res);
  };

  const nouveauRucher = () => {
    dispatch(apiProprietaireStore(itemApi));
    dispatch(setAffCreeRucher(true));

  };


  const TrieLesRuchersDeApi = () => {
    dispatch(apiProprietaireStore(itemApi));
    dispatch(setAffTrieRucher(true));
  };

  const nouvelleColonie = () => {
    getListRuchersDeApi(itemApi)
      .then((ruchers) => {
        dispatch(apiProprietaireStore(itemApi));

        dispatch(lesRuchersTravailStore(ruchers));
        dispatch(setAffCreeColonie(true));
      })

  };


  const handleSoinClickSsId = () => {
    console.log(idDuRucher);
    storeRucher();

    Promise.all([getColoniesDuRucher(JSON.stringify(idDuRucher)),])
      .then(([lesColonies]) => {
        dispatch(lesColoniesDuRucherStore(lesColonies));
        dispatch(setAffNewMultiSoin(true));
      })
  };

  const handleComptageClickSsId = () => {
    console.log(idDuRucher);
    storeRucher();

    Promise.all([getColoniesDuRucher(JSON.stringify(idDuRucher)),])
      .then(([lesColonies]) => {
        dispatch(lesColoniesDuRucherStore(lesColonies));
        dispatch(setAffNewMultiComptage(true));
      })
  };

  const handleRecolteClick = (idRucher) => {

    Promise.all([getColoniesDuRucher(JSON.stringify(idRucher)),])
      .then(([lesColonies]) => {
        dispatch(lesColoniesDuRucherStore(lesColonies));
        dispatch(setAffNewMultiRecolte(true));
      })
  };

  const handleRecolteClickSsId = () => {
    storeRucher();

    Promise.all([getColoniesDuRucher(JSON.stringify(idDuRucher)),])
      .then(([lesColonies]) => {
        dispatch(lesColoniesDuRucherStore(lesColonies));
        dispatch(setAffNewMultiRecolte(true));
      })
  };


  const handleModRucherClickSsId = () => {
    let proprietaireDuRucher = 0;

    let rucher = new Object();

    if (douJeViensState == 'delegat') {
      rucher = lesRuchersDelegation.find(r => r.id == idDuRucher);
      proprietaireDuRucher = rucher.apiculteur;

    }
    else {
      proprietaireDuRucher = itemApi;
      rucher = lesRuchersDeApi.find(r => r.id == idDuRucher);
    };
    dispatch(apiProprietaireStore(proprietaireDuRucher));

    dispatch(leRucherStore(rucher));
    dispatch(setAffModRucher(true));
  };


  const handleDelRucherClickSsId = () => {
    let proprietaireDuRucher = 0;

    let rucher = new Object();

    if (douJeViensState == 'delegat') {
      rucher = lesRuchersDelegation.find(r => r.id == idDuRucher);
      proprietaireDuRucher = rucher.apiculteur;

    }
    else {
      proprietaireDuRucher = itemApi;
      rucher = lesRuchersDeApi.find(r => r.id == idDuRucher);
    };
    dispatch(apiProprietaireStore(proprietaireDuRucher));

    dispatch(leRucherStore(rucher));
    dispatch(setAffDelRucher(true));
  };

  const handleEditClick = (idRucher, douJeviens) => {

    let proprietaireDuRucher = 0;
    let rucher = new Object();

    if (douJeviens == 'delegat') {
      rucher = lesRuchersDelegation.find(r => r.id == idRucher);
      const colo = rowsDelegation.find(c => c.id == idRucher);
      proprietaireDuRucher = colo.api;
    }
    else {
      rucher = lesRuchersDeApi.find(r => r.id == idRucher);
      proprietaireDuRucher = itemApi;
    }

    Promise.all([getColoniesDuRucher(JSON.stringify(idRucher)),])
      .then(([lesColonies]) => {
        dispatch(lesColoniesDuRucherStore(lesColonies));
        dispatch(apiProprietaireStore(proprietaireDuRucher));
        dispatch(leRucherStore(rucher));

        dispatch(setAffLesColoniesDuRucher(true));
        dispatch(setAffColonie(false));
        setAfficheListe(false);
        setMessageErreur('');
      })
      .catch((res) => handleLoadingError(res));

  };

  const [anchorEl, setAnchorEl] = React.useState(null);


  const handleClick = (event, id, douJeViens) => {
    console.log('handleclick');
    console.log(id);
    setAnchorEl(event.currentTarget);
    setIdDuRucher(id);
    setDouJeViensState(douJeViens);
  };

  const open = Boolean(anchorEl);


  const handleClose = () => {
    setAnchorEl(null);
  };

  const columns = [
    // { field: 'id', headerName: 'id', width: 150 },
    { field: 'rucher', headerName: 'Rucher', editable: false, flex: 150 },
    { field: 'nbCol', headerName: 'Nb Colo.', editable: false, flex: 100 },

    {
      field: 'actions',
      type: 'actions',
      flex: 60,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [

          <GridActionsCellItem
            icon={<SearchIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id, 'proprio')}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<MoreVertIcon />}
            label="more"
            className="textPrimary"
            onClick={(event) => { handleClick(event, id, 'proprio') }}
            color="inherit"
          />,

        ];
      },
    },];


  const columnsDelegation = [
    // { field: 'id', headerName: 'id', width: 150 },
    { field: 'rucher', headerName: 'Rucher', editable: true, width: 200 },
    { field: 'nbCol', headerName: 'Colo.', editable: false, width: 60 },
    { field: 'Apiculteur', headerName: 'Api', editable: false, width: 250 },

    {
      field: 'actions',
      type: 'actions',
      width: 60,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id, 'delegat')}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<MoreVertIcon />}
            label="more"
            className="textPrimary"
            onClick={(event) => { handleClick(event, id, 'delegat') }}
            color="inherit"
          />,
        ];
      },
    },];

  const setRefresh = () => {
    Promise.all([listColoniesdeApi(itemApi), listColoniesDelegation(itemApi)])
      .then(([coloniesdeApi, coloniesDelegation]) => {
        dispatch(lesColoniesDeApiStore(coloniesdeApi));
        dispatch(lesColoniesDelegationStore(coloniesDelegation));
      })
  }

  const classes = useStyles();



  return (

    <>
      {allFetch && (
        <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >
          <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <Box sx={{ flex: 9, alignContent: 'center' }}>  Les Ruchers   </Box>
            <Box sx={{ flex: 1 }}>
              {afficheListe ?
                <IconButton onClick={() => { setAfficheListe(!afficheListe); }} >
                  <ExpandLessIcon sx={{ fontSize: 30, color: grey[900] }} />
                </IconButton>
                :
                <IconButton onClick={() => { setAfficheListe(!afficheListe); }} >
                  <ExpandMoreIcon sx={{ fontSize: 30, color: grey[900] }} />
                </IconButton>
              }
            </Box>
          </Box>

          {afficheListe && (
            <>
              <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs value={valTab} onChange={handleChange} aria-label="basic tabs example">
                  <Tab label="Mes Ruchers" {...a11yProps(0)} />
                  <Tab label="Mes Délégations" {...a11yProps(1)} />
                </Tabs>
              </Box>
              <TabPanel value={valTab} index={0} className={classes.root}>
                <div style={{ height: 300, width: '100%' }}  >
                  <DataGrid
                    columns={columns}
                    rows={rows}
                    editMode="row"
                    rowHeight={30}
                    rowModesModel={rowModesModel}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    experimentalFeatures={{ newEditingApi: true }}
                    sx={{ m: 0 }}
                    componentsProps={{
                      panel: { sx: { top: '-70px !important' }, }
                    }}
                  />
                </div>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                  <Button variant="text" endIcon={<AddCircleOutlineIcon />} onClick={() => { nouveauRucher(); }}>
                    Nouveau Rucher
                  </Button>
                  <Button variant="text" endIcon={<SortIcon />} onClick={() => { TrieLesRuchersDeApi(); }}>
                    Trier les ruchers
                  </Button>
                </Box>
              </TabPanel>
              <TabPanel value={valTab} index={1} className={classes.root}>
                <div style={{ height: 300, width: '100%' }}>
                  <DataGrid
                    columns={columnsDelegation}
                    rows={rowsDelegation}
                    editMode="row"
                    rowHeight={30}
                    rowModesModel={rowModesModel}
                    localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                    experimentalFeatures={{ newEditingApi: true }}
                    sx={{ m: 1 }}
                    componentsProps={{
                      panel: { sx: { top: '-70px !important', }, }
                    }}
                  />
                </div>
              </TabPanel>
              <Menu
                anchorEl={anchorEl}
                keepMounted onClose={handleClose}
                open={open}>
                <MenuItem>

                  <Button variant="text" startIcon={<MedicationLiquidIcon sx={{ fontSize: 20, color: orange[700] }} />}
                    onClick={() => { handleClose(); handleSoinClickSsId(); }}>
                    Soigner/Nourrir
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button variant="text" startIcon={<BugReportIcon sx={{ fontSize: 20, color: orange[700] }} />}
                    onClick={() => { handleClose(); handleComptageClickSsId(); }}>
                    Compter varroas
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button variant="text" startIcon={<GiHoneyJar size={20} style={{ fill: 'chocolate' }} />}
                    onClick={() => { handleClose(); handleRecolteClickSsId(); }}>
                    Recolter
                  </Button>
                </MenuItem>
                <MenuItem>
                  <Button variant="text" startIcon={<EditIcon sx={{ fontSize: 20, color: green[700] }} />}
                    onClick={() => { handleClose(); handleModRucherClickSsId(); }}>
                    Modification
                  </Button>
                </MenuItem>
                {valTab == 2 &&
                  <MenuItem>
                    <Button variant="text" startIcon={<DeleteIcon sx={{ fontSize: 20, color: red[700] }} />}
                      onClick={() => { handleClose(); handleDelRucherClickSsId(); }}>
                      Suppression
                    </Button>
                  </MenuItem>}

              </Menu>
            </>
          )}
          {messageErreur ? <p>{messageErreur}</p> : null}


        </Paper>
      )}
    </>
  )
};
// <Button type="submit" onClick={() => { dispatch(toggleAffReine()); dispatch(setAffReine(true)) }} >Modifier</Button>

export default AfficheLesRuchersdeApi;