import React, { useState, useEffect, useLayoutEffect } from 'react';

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Button, Container, ButtonGroup, Dropdown } from "react-bootstrap";
import { DetReineParNom, retrieveApiculteurTotal, reclameReine } from '../services/accesApi';
import { useDispatch, useSelector } from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { grey, amber, green, red } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import { makeStyles } from "@mui/styles";
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';


//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';

import { toggleAffReine, setAffModReine, setAffDelReine, setmodReine, } from '../../store/displayslice'

import CustomTextStd from '../../components/CustomTextStd';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';

//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
};

const useStyles = makeStyles({
  cookieAlert: {
    "& .MuiAlert-message": {
      fontSize: 32
    }
  }
});

function AfficheReine() {

  const dispatch = useDispatch()
  const classes = useStyles();
  const { reine } = useSelector(state => state.reine)
  const { user } = useSelector(state => state.user);
  const [afficheSnack, setAfficheSnack] = useState(false);

  const demandeEdition = () => {

    reclameReine(reine.id, reine.editeur, user.id)
      .then(() => {
        console.log("ok");
        setAfficheSnack(true)
      }
      );
  }
  //console.log(reine);

  const handleCloseSnack = () => {


    setAfficheSnack(false);
  };
  return (
    <>
      <Snackbar open={afficheSnack} autoHideDuration={6000} onClose={handleCloseSnack}>
        <Alert onClose={handleCloseSnack} severity="success" sx={{ width: '100%' }} className={classes.cookieAlert}>
          La demande est bien partie !
        </Alert>
      </Snackbar>
      <Container>
        <Box sx={style}>
          <Grid container display="flex" alignItems="center">
            <Grid xs={6}>
              <h4>Reine: {reine.nom}</h4>
            </Grid>
            <Grid xs={6}>
              <CustomTextStd label="Née en  :" labD={6} fieldD={6}>{reine.anneeNaissance}</CustomTextStd>
            </Grid>
          </Grid>
          <CustomTextStd label="Eleveur :" labD={3} fieldD={7}>{reine.eleveur_nom}</CustomTextStd>

          <Grid container display="flex" alignItems="center">
            <Grid xs={6}>
              <CustomTextStd label="Mère :" labD={6} fieldD={6}>{reine.maman_nom}</CustomTextStd>
            </Grid>
            <Grid xs={6}>
              <CustomTextStd label="Père :" labD={6} fieldD={6}>{reine.papa_nom}</CustomTextStd>
            </Grid>
          </Grid>

          <Grid container display="flex" alignItems="center">
            <Grid xs={6}>
              <CustomTextStd label="Fécondation :" labD={6} fieldD={6}>{reine.typeFecondation_nom}</CustomTextStd>
            </Grid>
            <Grid xs={6}>
              <CustomTextStd label="à :">{reine.lieuFecondation}</CustomTextStd>
            </Grid>
          </Grid>
          <CustomTextStd label="Race :">{reine.race_nom}</CustomTextStd>

          <CustomTextStd label="Destination :">{reine.destination}</CustomTextStd>
          <CustomTextStd label="population :">{reine.population}</CustomTextStd>
          <CustomTextStd label="Groupement :">{reine.Groupement}</CustomTextStd>
          <CustomTextStd label="Commentaire :">{reine.commentaire}</CustomTextStd>
          {reine.ABiometrie &&
            <>
              <Divider variant="middle" />
              <Grid container display="flex" alignItems="flex-end">
                <Grid xs={6}>
                  <Typography>Biométrie</Typography>
                </Grid>
                <Grid xs={6}>
                  <CustomTextStd label="Taux conforme" labD={8} fieldD={4}>{reine.TauxConforme}</CustomTextStd>

                </Grid>
              </Grid>
              <Grid container display="flex" alignItems="flex-end">
                <Grid xs={6}>
                  <CustomTextStd label="Indice cubital" labD={8} fieldD={4}>{reine.IndiceCubital}</CustomTextStd>
                </Grid>
                <Grid xs={6}>
                  <CustomTextStd label="Ecart type :" labD={8} fieldD={4}>{reine.IndiceCubitalEc}</CustomTextStd>
                </Grid>
              </Grid>
              <Grid container display="flex" alignItems="flex-end">
                <Grid xs={6}>
                  <CustomTextStd label="Transg Discoidale" labD={8} fieldD={4}>{reine.TransgressionDiscoidale}</CustomTextStd>
                </Grid>
                <Grid xs={6}>
                  <CustomTextStd label="Ecart type :" labD={8} fieldD={4}>{reine.TransgressionDiscoidaleEc}</CustomTextStd>
                </Grid>
              </Grid>
            </>
          }
          {reine.AAnaMithocondriale &&
            <>
              <Divider variant="middle" />
              <Grid container display="flex" alignItems="flex-end">
                <Grid xs={6}>

                  <Typography>Analyse Mitochondriale</Typography>
                </Grid>
                <Grid xs={6}>
                  <CustomTextStd label="Taux hybridation " labD={8} fieldD={4}>{reine.TauxHybridation}</CustomTextStd>
                </Grid>
              </Grid>
              <Grid container display="flex" alignItems="flex-end">
                <Grid xs={6}>
                  <CustomTextStd label="Haplotype " labD={8} fieldD={4}>{reine.haplotype}</CustomTextStd>
                </Grid>
                <Grid xs={6}>
                  <CustomTextStd label="Région" labD={8} fieldD={4}>{reine.Region}</CustomTextStd>
                </Grid>
              </Grid>

            </>
          }
          {reine.ATesteVsh &&
            <>
              <Divider variant="middle" />
              <Grid container display="flex" alignItems="flex-end">
                <Grid xs={6}>
                  <Typography>Vsh</Typography>
                </Grid>
                <Grid xs={6}>
                  <CustomTextStd label="Taux Vsh" labD={8} fieldD={4}>{reine.TauxVsh}</CustomTextStd>
                </Grid>
              </Grid>
              <Divider variant="middle" />
            </>
          }
          {(user && user.id == reine.editeur) ?
            <>

              <Grid container display="flex" justifyContent="flex-end">
                <Tooltip title={<Typography fontSize={20}>Modifier</Typography>} placement="top" sx={{ fontSize: 200 }}>
                  <IconButton onClick={() => { dispatch(setAffModReine(true)) }} sx={{ color: green[700] }}>
                    <EditIcon sx={{ fontSize: 40, }} />
                  </IconButton>
                </Tooltip>
                <Tooltip title={<Typography fontSize={20}>Supprimer</Typography>} placement="top" sx={{ fontSize: 200 }}>
                  <IconButton onClick={() => { dispatch(setAffDelReine(true)) }} sx={{ color: red[700] }}>
                    <DeleteIcon sx={{ fontSize: 40, }} />
                  </IconButton>
                </Tooltip>
              </Grid>

            </>
            : <><Stack direction="row" alignItems="center" spacing={2}>
              <br></br><h5>Edité par {reine.editeur_nom}</h5>
              {user &&

                <Tooltip title={<Typography fontSize={20}>Demander à pouvoir éditer la reine</Typography>} placement="top" sx={{ fontSize: 200 }}>

                  <IconButton onClick={() => { demandeEdition() }} sx={{ color: green[700] }}>
                    <FileDownloadIcon sx={{ fontSize: 40, }} />
                  </IconButton>
                </Tooltip>}</Stack></>
          }
          {!user &&
            <><Typography>Identifiez-vous pour enregistrer des reines      </Typography>
            </>
          }
        </Box>
      </Container>
    </>
  );
}

// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>

export default AfficheReine;