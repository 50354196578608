import React, { useState, useEffect, } from 'react';



import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';
import { ColorLensOutlined } from '@mui/icons-material';
import { grey, amber, green } from '@mui/material/colors';

//import ListEleveur from '../components/listEleveur'

import { useSelector, useDispatch } from 'react-redux'
import MenuBar from '../../components/MenuBar'
import { login, retrieveApiculteurTotal, postPasswordReset } from '../../userModule/services/authentification';
import { userStore } from '../../store/userslice';
import {   leThemeStore, leThemeRevoke, lesThemesStore, lesThemesRevoke,
  lEntreeStore, lEntreeRevoke, lesEntreesStore, lesEntreesRevoke,

 } from '../../store/journalslice';
 import {     setAffTheme, setAffModTheme, setAffCreeTheme, setAffDelTheme, setAffLesThemes,
  setAffEntree, setAffModEntree, setAffCreeEntree, setAffDelEntree, setAffLesEntrees, } from '../../store/displayJournalslice';

import { setTitreBar, setAffLogin } from '../../store/displayslice';
import {
  getListTheme
} from '../services/accessApiJournaux';

import AfficheLesThemes from '../components/AfficheLesThemes';
import ModTheme from '../components/ModTheme';
import DelTheme from '../components/DelTheme';
import ModEntree from '../components/ModEntree';
import DelEntree from '../components/DelEntree';

import ListLesEntrees from '../components/ListLesEntrees';


function JournalScreen() {
  const dispatch = useDispatch();
  const [rucherLoadingErrorMessage, setRucherLoadingErrorMessage] = useState('');

  //const [ lRuchers , setLRuchers] = useState ([]);
  const [allFetch, setAllFetch] = useState();

  const { user } = useSelector(state => state.user);
  const { affTheme, affModTheme, affCreeTheme, affDelTheme, affLesEntrees,
  affModEntree, affCreeEntree, affDelEntree } = useSelector(state => state.displayJournal)


  var langue = '';


  dispatch(setTitreBar('JOURNAUX'));


  const handleRucherLoadingError = (res) => {
    if (res.error === 401) {
      setRucherLoadingErrorMessage("Connectez vous pour accéder à cette fonction");
    } else {
      setRucherLoadingErrorMessage(res.message);
    };
  };

  useEffect(() => {
    let mounted = true;

    console.log('use effect');
    if (user) {
      langue = user.langage;
    } else {
      //navigate("/reine");
      const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;

      if (token) {
        Promise.all([retrieveApiculteurTotal(),])
          .then(async ([userValues, ]) => {
            console.log(userValues);
            dispatch(userStore(userValues));
            //dispatch(apiProprietaireStore (userValues.id));
          })
      }
      else {
        dispatch(setAffLogin(true));
      }
    };
      if (typeof (user) !== 'undefined' && user != null) {
 
        let mounted = true;
        let itemApi = user.id;
        setAllFetch(false);
        Promise.all([getListTheme()])
          .then(async ([themes]) => {
            if (mounted) {
              dispatch(lesThemesStore(themes));console.log(themes);
              setAllFetch(true);


            }
          })
          .catch(handleRucherLoadingError);
  
      return () => { mounted = false; setAllFetch(false); };
    }
  }, [user])



  return (
    <>
      <MenuBar />
      <Grid2 container>

        <Grid2 xs={4} >

          {allFetch && <AfficheLesThemes />}
          <p>Test</p>
        </Grid2>
        <Grid2 xs={8} >
          <Paper elevation={10} sx={{ bgcolor: amber[100] }}>
          {affLesEntrees && <ListLesEntrees />}

          {affCreeTheme && <ModTheme />}
            {affModTheme && <ModTheme />}
            {affDelTheme && <DelTheme />}

            {affCreeEntree && <ModEntree />}
            {affModEntree && <ModEntree />}
            {affDelEntree && <DelEntree />}


          </Paper>

        </Grid2>
      </Grid2>


    </>
  );
}


export default JournalScreen;


