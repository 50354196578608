import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';


import i18n from '../services/i18n';
import { dispDate } from '../services/prepDate';
import { bSante } from '../services/bSante';

import CustomTextStd2 from '../../components/CustomTextStd2';
import CustomTextStd from '../../components/CustomTextStd';
import { styles } from '../../components/Styles';



const ResumeObservation = (props) => {
   const {
      observation, langue,
      ...inputProps
   } = props

   const { cPickers } = useSelector(state => state.cPicker);
   const contenuPickers = cPickers;
   //console.log(observation);



   return (
      <>


         {contenuPickers.apiculteur.utiliseObservation && (observation.date ? (
            <Box style={{ flexDirection: 'row', display: 'flex' }}>
               <Box style={{ flex: 3, flexDirection: 'column' }}>
                  <CustomTextStd2 label={i18n.t('colonie.date')} contenu={dispDate(observation.date, langue)} />
               </Box>
               <Box style={{ flex: 3, flexDirection: 'column', backgroundColor: bSante(observation.sante) }}>
                  <CustomTextStd2 label={i18n.t('colonie.sante')} contenu='' />
               </Box>
            </Box>
         )
            : (<Typography>{i18n.t('colonie.pasDeObservation')}</Typography>))}

         {contenuPickers.apiculteur.utiliseObservation && (observation.observations ? (
            <Box style={{ flexDirection: 'row', display: 'flex' }}>
               <Typography style={{ flex: 1 }}>{i18n.t('colonie.observation')}</Typography>
               <Typography style={{ flex: 5, borderColor: 'black', borderWidth: StyleSheet.hairlineWidth, borderRadius: 5 }}>{observation.observations}</Typography>

            </Box>
         ) : null)}


      </>
   )

}

export default ResumeObservation;
