import React, { useState } from 'react'
import { Container, Row, Col, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { grey, amber, red } from '@mui/material/colors';

import CustomInputSsLabel from '../../components/CustomInputSsLabel';

import i18n from '../services/i18n';


const SaisieTravail = (props) => {
  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched, setFieldValue }, label, labD = 4, fieldD = 8, required = false, index,
    travail: { nomFaire, nomDefaire, nomCommentaire, fait, deFait, aNomDefaire, aCommentaire },
    ...inputProps
  } = props

  //const [trav, setTral] = useState(fait, deFait);

  const hasError = errors[name] && touched[name]
  //console.log(props);
  //console.log(value.travauxFaits[index].fait);
  return (
    <>
      {/*    <View style={styles.container}> */}
      <ToggleButtonGroup  aria-label="text formatting" >
        <Box sx={{ display: 'flex', flexDirection: 'row', width: 1, alignItems:'center' }}>
          <Box sx={{ flex: 1 }}>
            <ToggleButton sx={{ width: 1, height: 35,  "&.MuiToggleButton-root.Mui-selected": {
                backgroundColor: amber[600]
              }, "&.MuiToggleButton-root.Mui-selected.MuiSvgIcon-root": {
                color: "red"
              } }} value="fait" selected={value.travauxFaits[index].fait}
              onClick={(val) => {
                value.travauxFaits[index].fait = !value.travauxFaits[index].fait;
                setFieldValue('fait', value.travauxFaits[index].fait)
              }
              }>{nomFaire}
            </ToggleButton>
          </Box>
          <Box sx={{ flex: 1 }}>
          {aNomDefaire && <ToggleButton sx={{ width: 1,height: 35,   "&.MuiToggleButton-root.Mui-selected": {
                backgroundColor: amber[600]
              }, "&.MuiToggleButton-root.Mui-selected.MuiSvgIcon-root": {
                color: "red"
              }}} value={deFait} selected={value.travauxFaits[index].deFait}
              onClick={(val) => {
                value.travauxFaits[index].deFait = !value.travauxFaits[index].deFait;
                setFieldValue('deFait', value.travauxFaits[index].deFait)
              }}
            >{nomDefaire}
            </ToggleButton> } 
          </Box>
          <Box sx={{flex: 2}}>
            {(aCommentaire) &&
              <Field xs={6} component={CustomInputSsLabel} name={'travauxFaits['+index+'].commentaire'} 
              label={nomCommentaire} placeholder={nomCommentaire} />
            }
          </Box>
   

        </Box>
      </ToggleButtonGroup>

    </>
  )
}

let styles = {
  textInput: {
    height: 25,
    width: '100%',
    margin: 0,
    backgroundColor: 'white',
    borderColor: amber[800],
    borderWidth: 1,
    borderRadius: 5,
    textAlignVertical: 'top',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  label: {
    flex: 1
  },

  errorText: {
    fontSize: 10,
    color: 'red',
  },
  errorInput: {
    borderColor: 'red',
  }
}


export default SaisieTravail;
