import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';

import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2

import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import ArchiveIcon from '@mui/icons-material/Archive';
import EditIcon from '@mui/icons-material/Edit';
import ArchiveTwoToneIcon from '@mui/icons-material/ArchiveTwoTone';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { red, amber, green, orange, grey } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import ListAltIcon from '@mui/icons-material/ListAlt';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';

import { randomId } from '@mui/x-data-grid-generator';

import i18n from '../services/i18n';
import { dispDate } from '../services/prepDate';
import { bSante } from '../services/bSante';


import {
  getListVisite, getListSoin, getListComptage, getListRecolte, getListRuchersDeApi, getListObservation,
  getListTravauxFaits
} from '../services/accessApiRuchers';

import CustomTextStd2 from '../../components/CustomTextStd2';
import CustomButton from '../../components/CustomButton';
import { headerStyles, styles } from '../../components/Styles';
import ResumeVisite from './ResumeVisite';
import ResumeObservation from './ResumeObservation';
import ResumeSoin from './ResumeSoin';
import ResumeComptage from './ResumeComptage';
import ResumeRecolte from './ResumeRecolte';


// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import {
  setAffColonie, setAffCreeColonie, setAffModColonie, setAffDelColonie, setAffModDelColonie,

  setAffCreeVisite, setAffModVisite, setAffVisite, setAffLesVisites,
  setAffLesObservations, setAffCreeObservation, setAffModObservation,
  setAffLesSoins, setAffCreeSoin, setAffModSoin,
  setAffLesComptages, setAffCreeComptage, setAffModComptage,
  setAffLesRecoltes, setAffCreeRecolte, setAffModRecolte
} from '../../store/displayRucherslice'
import {
  laColonieStore, lesVisitesStore, lesSoinsStore, lesComptagesStore, lesRecoltesStore, lesObservationsStore,
  lesTravauxFaitsStore,
  leSoinStore, leComptageStore, laVisiteStore, laRecolteStore, lesRuchersTravailStore, lObservationStore
} from '../../store/ruchersslice'
import { Grid } from '@mui/material';

// pas de edit toolbar

function AfficheColonie() {
  const dispatch = useDispatch()

  const [allFetch, setAllFetch] = useState(true);

  //const { laColonie } = useSelector(state => state.ruchers);
  const laColonie = useSelector(state => state.ruchers).laColonie;
  const laColoniePhoto = useSelector(state => state.ruchers).laColoniePhoto;
  console.log(laColoniePhoto);
  const visite = useSelector(state => state.ruchers).laDerniereVisite;
  const soin = useSelector(state => state.ruchers).leDernierSoin;
  const comptage = useSelector(state => state.ruchers).leDernierComptage;
  const recolte = useSelector(state => state.ruchers).laDerniereRecolte;
  const observation = useSelector(state => state.ruchers).laDerniereObservation;
  const apiProprio = useSelector(state => state.ruchers).apiProprietaire;


  const { cPickers } = useSelector(state => state.cPicker);
  const contenuPickers = cPickers;
  const { user } = useSelector(state => state.user);

  const langue = user.langage;

  const colonie = laColonie;
  const idColonie = laColonie.id;
  const [messageErreur, setMessageErreur] = useState();





  const typeRuche = (colonie) => {
    const lacolo = contenuPickers.typRuc.find(typr => typr.id === colonie.typeruche);
    if ((typeof (lacolo) != "undefined")) { return lacolo.modeleruche };
    return '';
  };

  const libMarquage = (colonie) => {
    const laMarque = contenuPickers.listMarc.find(mar => mar.id === colonie.marquage);
    if (typeof (laMarque) != "undefined") { return laMarque.couleur };
    return '';
  };

  const libRace = (colonie) => {
    if (colonie.race != 'null') {
      const laRace = contenuPickers.listRac.find(rac => rac.id === colonie.race);
      if (typeof (laRace) != "undefined") { return laRace.race };
    }
    return '';
  };

  const libFiliation = (colonie) => {
    if (allFetch) {
      if ((typeof contenuPickers.optCol.actions != "undefined") && (typeof colonie != "undefined")) {
        const nom = contenuPickers.optCol.actions.PUT.filiation.choices.find(fil => fil.value == colonie.filiation);
        if (typeof nom != "undefined") {
          let dispname = nom.display_name;
          return dispname
        };
      }
    }
    return '';
  };




  const handleLoadingError = (res) => {
    console.log('erreur');
    console.log(res);
  };


  const supprimeColonie = () => {
    dispatch(setAffDelColonie(true));
  };

  const archiveColonie = () => {
    getListRuchersDeApi(apiProprio)
      .then((ruchers) => {
        dispatch(lesRuchersTravailStore(ruchers));

        dispatch(setAffModDelColonie(true));
      })
  };

  const NewVisite = () => {
    //  navigation.navigate('modVisite', {colonie: colonie, contenuPickers: contenuPickers, 
    //   nomRucher : nomRucher, idRucher: idRucher, visite: new Object(), ModeModif: false ,
    //   doujeviens : 'colonie', coloniesDuRucher: coloniesDuRucher, apiProprietaire: apiProprietaire});
  };

  const ModVisite = () => {
    //   navigation.navigate('modVisite', {colonie: colonie, contenuPickers: contenuPickers, 
    //       nomRucher : nomRucher, visite: visite , idRucher: idRucher, ModeModif: true , 
    //       doujeviens : 'colonie', coloniesDuRucher: coloniesDuRucher, apiProprietaire: apiProprietaire})
  };

  const listVisite = () => {
    getListVisite(JSON.stringify(colonie.id))
      .then((items) => {
        dispatch(lesVisitesStore(items));
        dispatch(setAffLesVisites(true));
      });
  };

  const listObservation = () => {
    getListObservation(JSON.stringify(colonie.id))
      .then((items) => {
        dispatch(lesObservationsStore(items));
        dispatch(setAffLesObservations(true));
      })
  };

  const listSoin = () => {
    getListSoin(JSON.stringify(colonie.id))
      .then((items) => {
        dispatch(lesSoinsStore(items));
        dispatch(setAffLesSoins(true));
      })
  };

  const listComptage = () => {
    getListComptage(JSON.stringify(colonie.id))
      .then((items) => {
        dispatch(lesComptagesStore(items));
        dispatch(setAffLesComptages(true));
      })
  };

  const listRecolte = () => {
    getListRecolte(JSON.stringify(colonie.id))
      .then((items) => {
        dispatch(lesRecoltesStore(items));
        dispatch(setAffLesRecoltes(true));
      })
  };

  const handleCreeColonie = () => {
    getListRuchersDeApi(apiProprio)
      .then((ruchers) => {
        dispatch(lesRuchersTravailStore(ruchers));
        dispatch(setAffCreeColonie(true));
      })
  };

  const handleDupColonie = () => {
    getListRuchersDeApi(apiProprio)
      .then((ruchers) => {
        dispatch(lesRuchersTravailStore(ruchers));
        dispatch(setAffCreeColonie(true));
        dispatch(setAffModColonie(true));
      })
  };

  const handleModColonie = () => {
    getListRuchersDeApi(apiProprio)
      .then((ruchers) => {
        dispatch(lesRuchersTravailStore(ruchers));
        dispatch(setAffModColonie(true));
      })
  };


  const handleEditVisite = (locVisite) => {
    getListTravauxFaits(locVisite.id)
      .then((travsFaits) => {
        dispatch(lesTravauxFaitsStore(travsFaits));
        dispatch(laVisiteStore(locVisite));
        dispatch(setAffModVisite(true));
      })
  };
  //  navigation.replace('listVisite', {colonie: colonie, contenuPickers: contenuPickers, nomRucher : nomRucher, 
  //   idRucher: idRucher, coloniesDuRucher: coloniesDuRucher, apiProprietaire: apiProprietaire});

  const affVisite = (locVisite) => {

    getListTravauxFaits(locVisite.id)
      .then((travsFaits) => {
        dispatch(lesTravauxFaitsStore(travsFaits));
        dispatch(setAffVisite(true));
      })
  };
  return (
    <>
      {allFetch && (
        <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }}>
          <Box style={styles.titreCard}>
            <Typography sx={styles.TextTitreCard}>Colonie {colonie.nom} - à - {colonie.nomRucher} </Typography>
          </Box>
          <Grid2 sx={{ display: 'flex', flexDirection: 'row', p: 1 }} container >
            <Grid2 sx={{ display: 'flex', flexDirection: 'column', p: 1 }} xs={4}>
              <Paper>
                {/* <Image source={{ uri: `data:image/jpeg;base64,${laColoniePhoto}` }} style={{ width: 150, height: 150 }} /> 
              <img src={"data:image/jpeg;base64," + laColoniePhoto} /> */}
                {laColoniePhoto &&
                  <Box display="flex" justifyContent="center" alignItems="center">
                    <img width='150' height='150' src={`data:image/jpeg;base64,${laColoniePhoto}`} />
                  </Box>}
                <CustomTextStd2 label={i18n.t('colonie.format')} contenu={typeRuche(colonie)} />
                <CustomTextStd2 label={i18n.t('colonie.emplacement')} contenu={colonie.emplacement} />

                <CustomTextStd2 label={i18n.t('colonie.enruchement')} contenu={dispDate(colonie.date, langue)} />
                <CustomTextStd2 label={i18n.t('colonie.marquage')} contenu={libMarquage(colonie)} />
                <CustomTextStd2 label={i18n.t('colonie.race')} contenu={libRace(colonie)} />
                <CustomTextStd2 label={i18n.t('colonie.filiation')} contenu={libFiliation(colonie)} />
                <CustomTextStd2 label={i18n.t('colonie.numreine')} contenu={colonie.numreine} />
                <CustomTextStd2 label={i18n.t('colonie.nomreine')} contenu={colonie.nomreine} />
                <CustomTextStd2 label={i18n.t('colonie.orig reine')} contenu={colonie.originereine} />
                <CustomTextStd2 label={i18n.t('colonie.fécondation')} contenu={colonie.fecondationreine} />
                <CustomTextStd2 label={i18n.t('colonie.mère')} contenu={colonie.ancetrereine} />
                {(colonie.motifsuppression ?
                  <Box style={{ display: 'flex', flexDirection: 'row' }}>
                    <Typography style={{ flex: 4, color: 'red' }}>{colonie.motifsuppression}</Typography>
                    <Typography style={{ flex: 4, color: 'grey' }}>{colonie.datesuppression}</Typography>
                  </Box>
                  : null)}

                <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <Box sx={{ justifyContent: 'flex-start' }}>
                    <Tooltip title={<Typography fontSize={20}>Supprimer Colonie</Typography>} placement="top" sx={{ fontSize: 200 }}>
                      <IconButton onClick={() => { supprimeColonie() }} sx={{ color: red[700] }} >
                        <DeleteForeverIcon sx={{ fontSize: 30 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography fontSize={20}>Archiver Colonie</Typography>} placement="top" sx={{ fontSize: 200 }}>
                      <IconButton onClick={() => { archiveColonie() }} sx={{ color: orange[300] }} >
                        <ArchiveTwoToneIcon sx={{ fontSize: 30 }} />
                      </IconButton>
                    </Tooltip>

                  </Box>
                  <Box sx={{ justifyContent: 'flex-end' }}>
                    <Tooltip title={<Typography fontSize={20}>Nouvelle Colonie</Typography>} placement="top" sx={{ fontSize: 200 }}>
                      <IconButton onClick={() => { handleCreeColonie() }} sx={{ color: green[700] }} >
                        <AddCircleOutlineIcon sx={{ fontSize: 30 }} />
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={<Typography fontSize={20}>Duplication Colonie</Typography>} placement="top" sx={{ fontSize: 200 }}>
                      <IconButton onClick={() => { handleDupColonie() }} sx={{ color: green[700] }} >
                        <ContentCopyIcon sx={{ fontSize: 30 }} />
                      </IconButton>
                    </Tooltip>
                    {colonie.motifsuppression == null &&
                      <Tooltip title={<Typography fontSize={20}>Modifier</Typography>} placement="top" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { handleModColonie() }} sx={{ color: green[700] }}>
                          <EditIcon sx={{ fontSize: 30, }} />
                        </IconButton>
                      </Tooltip>}
                  </Box>
                </Box>
              </Paper>
            </Grid2>
            <Grid2 sx={{ display: 'flex', flexDirection: 'column', p: 1 }} xs={8}>
              <Paper>
                {/*   ----------------------- Visite -------------------------*/}

                <Box style={{ flexDirection: 'row', backgroundColor: grey[100] }}>
                  <Box style={styles.traitBleu} />
                  <Typography style={{ alignSelf: 'center', paddingHorizontal: 5, fontSize: 14 }}>{i18n.t('colonie.dernierevisite')}</Typography>
                  <Box style={styles.traitBleu} />
                </Box>
                <Grid2 container style={{ flexDirection: 'row', backgroundColor: grey[100] }}>

                  <Grid2 xs={11}>
                    <Card
                      onClick={() => { affVisite(visite) }} style={{ backgroundColor: grey[100] }}
                    >
                      <CardActionArea >
                        <ResumeVisite visite={visite} langue={langue} />
                      </CardActionArea>

                    </Card>
                  </Grid2>

                  <Grid2 sx={{ display: 'flex', flexDirection: 'column' }} xs={1}>
                    {(visite.date) ? (

                      <Tooltip title={<Typography fontSize={20}>Lister</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { listVisite() }} sx={{ color: green[700] }} >
                          <ListAltIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    ) : <Typography></Typography>}
                    {(colonie.motifsuppression == null && visite.date) ?
                      <Tooltip title={<Typography fontSize={20}>Modifier</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { handleEditVisite(visite) }} sx={{ color: green[700] }} >
                          <EditIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>

                      : <Typography></Typography>}
                    {(colonie.motifsuppression == null) ?
                      <Tooltip title={<Typography fontSize={20}>Créer</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { dispatch(setAffCreeVisite(true)) }} sx={{ color: green[700] }} >
                          <AddCircleOutlineIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                      : <Typography></Typography>}



                  </Grid2>
                </Grid2>

                {/*   ----------------------- Observation ------------&& ((contenuPickers.apiculteur.utiliseObservation)-------------*/}
                {(observation.date) &&
                  <>

                    <Box style={{ flexDirection: 'row' }}>
                      <Box style={styles.traitBleu} />
                      <Typography style={{ alignSelf: 'center', paddingHorizontal: 5, fontSize: 14 }}>{i18n.t('colonie.dernièreObservation')}</Typography>
                      <Box style={styles.traitBleu} />
                    </Box>
                    <Grid2 container style={{ flexDirection: 'row' }}>

                      <Grid2 xs={11}>
                        <ResumeObservation observation={observation} langue={langue} />
                      </Grid2>

                      <Grid2 sx={{ display: 'flex', flexDirection: 'column' }} xs={1}>
                        {(observation.date) ? (

                          <Tooltip title={<Typography fontSize={20}>Lister</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                            <IconButton onClick={() => { listObservation() }} sx={{ color: green[700] }} >
                              <ListAltIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                          </Tooltip>
                        ) : <Typography></Typography>}
                        {(colonie.motifsuppression == null && observation.date) ?
                          <Tooltip title={<Typography fontSize={20}>Modifier</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                            <IconButton onClick={() => { dispatch(lObservationStore(observation)); dispatch(setAffModObservation(true)) }} sx={{ color: green[700] }} >
                              <EditIcon sx={{ fontSize: 30 }} />
                            </IconButton>
                          </Tooltip>

                          : <Typography></Typography>}
                      </Grid2>
                    </Grid2>
                  </>}
                {/*   ----------------------- Soin -------------------------*/}
                <Box style={{ flexDirection: 'row', backgroundColor: grey[100] }}>
                  <Box style={styles.traitBleu} />
                  <Typography style={{ alignSelf: 'center', paddingHorizontal: 5, fontSize: 14 }}>{i18n.t('colonie.dernierSoin')}</Typography>
                  <Box style={styles.traitBleu} />
                </Box>
                <Grid2 container style={{ flexDirection: 'row', backgroundColor: grey[100] }}>

                  <Grid2 xs={11}>
                    <ResumeSoin soin={soin} langue={langue} />
                  </Grid2>

                  <Grid2 sx={{ display: 'flex', flexDirection: 'column' }} xs={1}>
                    {(soin.date) ? (

                      <Tooltip title={<Typography fontSize={20}>Lister</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { listSoin() }} sx={{ color: green[700] }} >
                          <ListAltIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    ) : <Typography></Typography>}
                    {(colonie.motifsuppression == null && soin.date) ?
                      <Tooltip title={<Typography fontSize={20}>Modifier</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { dispatch(leSoinStore(soin)); dispatch(setAffModSoin(true)) }} sx={{ color: green[700] }} >
                          <EditIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>

                      : <Typography></Typography>}
                    {(colonie.motifsuppression == null) ?
                      <Tooltip title={<Typography fontSize={20}>Créer</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { dispatch(setAffCreeSoin(true)) }} sx={{ color: green[700] }} >
                          <AddCircleOutlineIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                      : <Typography></Typography>}
                  </Grid2>
                </Grid2>
                {/*   ----------------------- Comptage -------------------------*/}
                <Box style={{ flexDirection: 'row', backgroundColor: grey[100] }}>
                  <Box style={styles.traitBleu} />
                  <Typography style={{ alignSelf: 'center', paddingHorizontal: 5, fontSize: 14 }}>{i18n.t('colonie.dernierComptage')}</Typography>
                  <Box style={styles.traitBleu} />
                </Box>
                <Grid2 container style={{ flexDirection: 'row', backgroundColor: grey[100] }}>

                  <Grid2 xs={11}>
                    <ResumeComptage comptage={comptage} langue={langue} />
                  </Grid2>

                  <Grid2 sx={{ display: 'flex', flexDirection: 'column' }} xs={1}>
                    {(comptage.date) ? (

                      <Tooltip title={<Typography fontSize={20}>Lister</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { listComptage() }} sx={{ color: green[700] }} >
                          <ListAltIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    ) : <Typography></Typography>}
                    {(colonie.motifsuppression == null && comptage.date) ?
                      <Tooltip title={<Typography fontSize={20}>Modifier</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { dispatch(leComptageStore(comptage)); dispatch(setAffModComptage(true)) }} sx={{ color: green[700] }} >
                          <EditIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>

                      : <Typography></Typography>}
                    {(colonie.motifsuppression == null) ?
                      <Tooltip title={<Typography fontSize={20}>Créer</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { dispatch(setAffCreeComptage(true)) }} sx={{ color: green[700] }} >
                          <AddCircleOutlineIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                      : <Typography></Typography>}
                  </Grid2>
                </Grid2>


                {/*   ----------------------- Recolte -------------------------*/}
                <Box style={{ flexDirection: 'row' }}>
                  <Box style={styles.traitBleu} />
                  <Typography style={{ alignSelf: 'center', paddingHorizontal: 5, fontSize: 14 }}>{i18n.t('colonie.dernièreRécolte')}</Typography>
                  <Box style={styles.traitBleu} />
                </Box>
                <Grid2 container style={{ flexDirection: 'row' }}>

                  <Grid2 xs={11}>
                    <ResumeRecolte recolte={recolte} langue={langue} />
                  </Grid2>

                  <Grid2 sx={{ display: 'flex', flexDirection: 'column' }} xs={1}>
                    {(recolte.date) ? (

                      <Tooltip title={<Typography fontSize={20}>Lister</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { listRecolte() }} sx={{ color: green[700] }} >
                          <ListAltIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                    ) : <Typography></Typography>}
                    {(colonie.motifsuppression == null && recolte.date) ?
                      <Tooltip title={<Typography fontSize={20}>Modifier</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { dispatch(laRecolteStore(recolte)); dispatch(setAffModRecolte(true)) }} sx={{ color: green[700] }} >
                          <EditIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>

                      : <Typography></Typography>}
                    {(colonie.motifsuppression == null) ?
                      <Tooltip title={<Typography fontSize={20}>Créer</Typography>} placement="right-start" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { dispatch(setAffCreeRecolte(true)) }} sx={{ color: green[700] }} >
                          <AddCircleOutlineIcon sx={{ fontSize: 30 }} />
                        </IconButton>
                      </Tooltip>
                      : <Typography></Typography>}




                  </Grid2>
                </Grid2>

              </Paper>
            </Grid2>





          </Grid2>

          {messageErreur ? <p>{messageErreur}</p> : null}
        </Paper>
      )}
    </>
  )
};
// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>

export default AfficheColonie;