import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';


import i18n from '../services/i18n';
import { dispDate } from '../services/prepDate';
import { bSante } from '../services/bSante';

import CustomTextStd2 from '../../components/CustomTextStd2';
import CustomTextStd from '../../components/CustomTextStd';
import {styles} from '../../components/Styles';



const ResumeRecolte = (props) => {
   const {
      recolte, langue,
      ...inputProps
   } = props

   const { cPickers } = useSelector(state => state.cPicker);
   const contenuPickers = cPickers;
   //console.log(recolte);

   const libUniNourri = (unite) => {
      const laUnite = contenuPickers.listUniNourri.find (item => item.id === unite);
      if (typeof (laUnite) != "undefined")
         {return laUnite.nom};
      return '';
  };  

  const libMedicament = (medoc) => {
//      console.log('lib medoc');
//      console.log(contenuPickers.listMedoc);
      const leMedoc = contenuPickers.listMedoc.find (item => item.id === medoc);
      if (typeof (leMedoc) != "undefined")
         {return leMedoc.nom};
      return '';
  };

   return (
         <>
            
     {recolte.nbkgmiel  ?  (
      <>
      <Box style={{flexDirection: 'row',   display: 'flex' }}>  
         <Box style={{flex:1,  flexDirection: 'column'}}>
            <CustomTextStd2 label={i18n.t('colonie.date')} contenu={dispDate(recolte.date, langue)} />   
         </Box> 
         <Box style={{flex:1,  flexDirection: 'column'}}>
            <CustomTextStd2 label="Kg:" contenu={recolte.nbkgmiel} /> 
         </Box>
         </Box>

         <Box style={{flexDirection: 'row',   display: 'flex' }}>  

         <Box style={{flex:2,  flexDirection: 'column'}}>
            <CustomTextStd2 label={i18n.t('récoltes.miel')} contenu={recolte.miel} /> 
         </Box> 
         <Box style={{flex:2,  flexDirection: 'column'}}>
            <CustomTextStd2 label={i18n.t('récoltes.lieu')} contenu={recolte.lieu} /> 
         </Box> 
      </Box>
      </>
       )
      : (<Typography>{i18n.t('colonie.pasDeRecolte')}</Typography>)}  


         </>
   )

}

export default ResumeRecolte;
