import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';


import i18n from '../services/i18n';
import { dispDate } from '../services/prepDate';
import { bSante } from '../services/bSante';

import CustomTextStd2 from '../../components/CustomTextStd2';
import CustomTextStd from '../../components/CustomTextStd';
import { styles } from '../../components/Styles';



const ResumeComptage = (props) => {
  const {
    comptage, langue,
    ...inputProps
  } = props

  const { cPickers } = useSelector(state => state.cPicker);
  const contenuPickers = cPickers;
  //console.log(comptage);

  const referenceLibelle = (comptage) => {
    const laMethode = contenuPickers.listTypComptage.find(item => item.id === comptage);
    if (typeof (laMethode) != "undefined") { return laMethode.libReference };
    return false;
  };

  const estPourCent = (comptage) => {
    const laMethode = contenuPickers.listTypComptage.find(item => item.id === comptage);
    if (typeof (laMethode) != "undefined") { return laMethode.estPourCent };
    return false;
  };


  const libMethodeComptage = (comptage) => {
    const laMethode = contenuPickers.listTypComptage.find(item => item.id === comptage);
    if (typeof (laMethode) != "undefined") { return laMethode.nom };
    return '';
  };


  const abeilleComptage = (comptage) => {
    const laMethode = contenuPickers.listTypComptage.find(item => item.id === comptage);
    if (typeof (laMethode) != "undefined") { return laMethode.indNombreAbeilles };
    return false;
  };



  return (
    <>

      {comptage.date ? (
        <>
          <Box style={{ flex: 3, flexDirection: 'column' }}>
            <Box style={{ flex: 3, flexDirection: 'column' }}>
              <CustomTextStd2 label={i18n.t('colonie.date')} contenu={dispDate(comptage.date, langue)} />
            </Box>
            <Typography style={{ flex: 1 }}>{libMethodeComptage(comptage.methode)}</Typography>
            <Box style={{ flexDirection: 'row', display: 'flex' }} alignItems={'center'}>
                <Box style={{ flex: 3, flexDirection: 'column', display: 'flex' }} >
                  <CustomTextStd2 label={referenceLibelle(comptage.methode)} contenu={comptage.nombreabeilles}
                    labD={9} fieldD={3}
                  />
                </Box>
              
              <Box style={{ flex: 3, flexDirection: 'column' }}>
                <CustomTextStd2 label={i18n.t('comptage.nombrevarroas')} contenu={comptage.nombrevarroas}
                  labD={9} fieldD={3} />
              </Box>

              {(comptage.nombreabeilles != 0) &&

                (estPourCent(comptage.methode) ?
                  <>
                    <Box style={{ flex: 2, flexDirection: 'column' }}>

                      <CustomTextStd2 label='%'
                        contenu={(comptage.nombrevarroas / comptage.nombreabeilles * 100).toFixed(2)} />

                    </Box>

                  </>
                  :
                  <>
                    <Box style={{ flex: 2, flexDirection: 'column' }}>

                      <CustomTextStd2 label='Nb/J'
                        contenu={(comptage.nombrevarroas / comptage.nombreabeilles ).toFixed(2)} />
                    </Box>
                  </>
                )}

            </Box>
          </Box >
        </>

      )
        : (<Typography>{i18n.t('colonie.pasDeComptage')}</Typography>)}


    </>
  )

}

export default ResumeComptage;
