import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';
import dayjs from 'dayjs';

import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';


import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

import { makeStyles } from '@mui/styles';


import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import SyncIcon from '@mui/icons-material/Sync';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';

import { red, amber, grey, green } from '@mui/material/colors';

import Modal from '@mui/material/Modal';
import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Unstable_Grid2';

import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';



import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';

import CustomTextStd2 from '../../components/CustomTextStd2';
import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';
import CustomButton from '../../components/CustomButton';


import {
  getLastRecolte, getLastVisite, getLastSoin, getLastObservation, getLastDemenagement, getDetColonie,
  getListTypPb, getListTypCR, getListTypMedoc, getListUniNourri, getOptionsVisite, getFirstVisite,
  getFirstColonie, listColoniesdeApi, getListRuchersDeApi, listColoniesDelegation, creeMultSoin
} from '../services/accessApiRuchers';


import { randomId } from '@mui/x-data-grid-generator';

// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import { setAffColonie, setAffCreeRucher, setAffCreeColonie, setAffNewMultiSoin } from '../../store/displayRucherslice'
import {
  laColonieStore, laDerniereVisiteStore, laVisiteStore,
  leDernierSoinStore, leSoinStore,
  laDerniereRecolteStore, laRecolteStore,
  laDerniereObservationStore, lObservationStore,
  lesColoniesDeApiStore, lesRuchersTravailStore,
  apiProprietaireStore, lesColoniesDelegationStore
} from '../../store/ruchersslice'
import { DisplaySettingsRounded } from '@mui/icons-material';
import i18n from '../services/i18n';
import { dispDate, dispDate6 } from '../services/prepDate';
import { bSante } from '../services/bSante';

const useStyles = makeStyles({
  root: {
    '& Div': {
      padding: 0,
    },
  },
});


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function NewMultiSoin() {
  const dispatch = useDispatch()

  const { user } = useSelector(state => state.user);
  const langue = user.langage;
  let itemApi = user.id;
  const [visible, setVisible] = useState(true);

  const { cPickers } = useSelector(state => state.cPicker);
  const { lesColoniesDuRucher, lesColoniesDelegation, leRucher } = useSelector(state => state.ruchers);


  let contenuPickers = Object.assign({}, cPickers);

  const typeRuche = (colonie) => {
    const lacolo = contenuPickers.typRuc.find (typr => typr.id === colonie.typeruche);
    if ((typeof (lacolo) != "undefined"))
         {return lacolo.modeleruche};
    return '';
    };

  const Initialisation = () => {
    console.log('init');
    const init = new Object();

    let lesColonies = [];
    for (let colonie of lesColoniesDuRucher) {
      if (colonie.motifsuppression == null) {
        const laColo = new Object();
        laColo.id = colonie.id;
        laColo.nom = colonie.nom;
        laColo.typeruche = colonie.typeruche;
        laColo.choix = true;
        lesColonies.push(laColo);
      }
    }
    init.colonies = lesColonies;
    init.dateS= new Date();
    init.qtmedicament = '';
    init.medicament = '';
    init.qtnourrissment = '';
    init.unitenourrissement = '';
    init.typnourrissement = '';

    init.switch = true;
    return init ;

  }

  const ValidationSchema = yup.object().shape({
    qtmedicament: yup
      .string().ensure()
      .matches(/^\s*$|^\d{1,2}((\.|\,)\d{0,2})?$/, i18n.t('soins.errorMedi')),
    qtnourrissment: yup
      .string().ensure()
      .matches(/^$|^\d{1,4}((\.|\,)\d{0,1})?$/, i18n.t('soins.errorNourri')),
    typnourrissement: yup
      .string()
      .max(20, 'trop long'),
    medicament: yup
      .string(),
    unitenourrissement: yup
      .string(),
    typnourrissement: yup
      .string(),
  });


  const FormonSubmit = (data) => {
    const mSoin = new Object();
    let laDate = dayjs(data.dateS).format('YYYY-MM-DD');

    let qtmedicament = data.qtmedicament.replace(',', '.');
    let medicament = data.medicament;
    let qtnourrissment = data.qtnourrissment.replace(',', '.') || null;
    let typnourrissement = data.typnourrissement;
    { data.medicament == "" || data.medicament <= 0 ? medicament = null : medicament = data.medicament };
    let unitenourrissement = null;
    { data.unitenourrissement == "" || data.unitenourrissement <= 0 ? unitenourrissement = null : unitenourrissement = data.unitenourrissement };
    { data.typnourrissement == "" ? typnourrissement = null : typnourrissement = data.typnourrissement };
  
    //mSoin.colonie = colonie.id;
    contenuPickers.date = data.date;
    let lesSoins = [];

    data.colonies.forEach((colonie) => {

      if (colonie.choix == true) {
        const leSoin = new Object();
        leSoin.colonie = colonie.id;
        leSoin.date = laDate;
        leSoin.qtmedicament = qtmedicament;
        leSoin.medicament = medicament;
        leSoin.qtnourrissment = qtnourrissment;
        leSoin.unitenourrissement = unitenourrissement;
        leSoin.typnourrissement = typnourrissement;
        
        lesSoins.push(leSoin);
      }
    });
    console.log(lesSoins);
    creeMultSoin(lesSoins)
      .then(() => { dispatch(setAffNewMultiSoin(false)) })
  }

  return (
    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Box sx={{
          width: 500, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2,
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>



                <Typography sx={{ fontSize: 30, }} align='center'>Soins du Rucher {leRucher.nom}</Typography>
                <Divider />
                <p></p>
                <Grid container>
                  <Grid xs={6} sx={{ pt: 1, pl: 1, color: grey[600], fontSize: 15 }} display="flex">
                    <Typography style={{ flex: 1 }}>{i18n.t('soins.soinDu')}</Typography>
                  </Grid>
                  <Grid xs={6}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                      <DesktopDatePicker
                        value={values.dateS}
                        onChange={(newValue) => { setFieldValue("dateS", newValue || values.dateS); }}
                        renderInput={(params) =>
                          <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>


                <Grid container>
                  <Typography >{i18n.t('soins.traitement')} :</Typography>
                </Grid>
                <Grid container>
                  <Grid xs={4}>
                    <Field component={CustomInput} name='qtmedicament' placeholder={i18n.t('soins.qtéMédoc')} />
                  </Grid>
                  <Grid xs={4}>

                    <Field component={CustomPicker}
                      liste={contenuPickers.listMedoc} itemKey='id' itemLabel='nom'
                      ModeModif={false} name='medicament'
                      selectedValue={values.medicament}
                      langue={i18n.locale}
                      handleChange={(itemIndex) => handleChange('medicament')(itemIndex)}
                    />

                  </Grid>

                  <Grid style={{ flex: 1 }}></Grid>
                </Grid>

                <Grid container>
                  <Typography >{i18n.t('soins.nourrissement')} :</Typography>
                </Grid>
                <Grid container>
                  <Grid xs={4}>
                    <Field component={CustomInput} name='qtnourrissment' placeholder={i18n.t('soins.quantitéNourrissement')} />
                  </Grid>
                  <Grid xs={4}>
                    <Field component={CustomPicker}
                      liste={contenuPickers.listUniNourri} itemKey='id' itemLabel='nom'
                      ModeModif={false} name='unitenourrissement'
                      selectedValue={values.unitenourrissement}
                      langue={i18n.locale}
                      handleChange={(itemIndex) => handleChange('unitenourrissement')(itemIndex)}
                    />
                  </Grid>
                  <Grid xs={4}>
                    <Field component={CustomInput} name='typnourrissement' placeholder={i18n.t('soins.typeNourrissement')} />
                  </Grid>
                </Grid>


                <Box><p>{"\n"}</p></Box>
                <CustomSwitch label={'A quelles colonies? Tout sélectionner'} vSwitch={values.switch}
                  onChange={(value) => {
                    values.colonies.map((colonie, index) => (
                      colonie.choix = value.target.checked
                    ));
                    setFieldValue('switch', value.target.checked)
                  }} />
                  <Box  style={{flexDirection: 'column',   display: 'flex'}} >

                {values.colonies.map((colonie, index) => (
                  <Box key={colonie.id} style={{display: 'flex'}}>
                  <Box  style={{flex :8}} >

                    <CustomSwitch label={colonie.nom + ' ' + typeRuche(colonie)} 
                    vSwitch={values.colonies[index].choix}
                    
                    onChange={event => setFieldValue('colonies[' + index + '].choix', !values.colonies[index].choix)}/>          


                  </Box>
                  <Box style={{flex:4}}></Box>
                  </Box>
                ))}
                </Box>
                <Grid container display="flex" justifyContent="flex-end">
                  <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffNewMultiSoin(false)) }} >
                      <RestoreIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
              </>
            )}

        </Formik>

      </Box>
    </Modal>
    </>
  )
};
// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>

export default NewMultiSoin;