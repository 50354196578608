
import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';

import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';


import EditIcon from '@mui/icons-material/Edit';
import { red, amber, green } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';

import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';


import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';


import { randomId } from '@mui/x-data-grid-generator';

//import i18n from '../services/i18n';


import CustomTextStd from '../../components/CustomTextStd';
import CustomTitre from '../../components/CustomTitre';
import CustomTextStd2 from '../../components/CustomTextStd2';

import { delReine, listAncetresReine, listFillesdeReine, getListReines } from '../services/accesApi';



// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import { setAffDelReine, setAffReine, setAffDisplayReine, setAffGenealogie, setAffFilles } from '../../store/displayslice'


const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};
// pas de edit toolbar


const DelReine = (props) => {
  const {
    ...inputProps
  } = props
  const { reine } = useSelector(state => state.reine)

console.log(reine);

  const dispatch = useDispatch()

  const soin = useSelector(state => state.ruchers).leSoin;

  const [open, setOpen] = useState(true);
  const [leSoin, setLeSoin] = useState(soin);
  const [allFetch, setAllFetch] = useState(false);
  const { affDelReine } = useSelector(state => state.display);
  const [ErrorMessage, setErrorMessage] = useState('');


  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const { user } = useSelector(state => state.user);

  const langue = user.langage;

  const handleCloseIgnore = () => {
    dispatch(setAffDelReine(false));
  };

  const handleDelError = (res) => {
    console.log(res);
    console.log(res.message);
    setErrorMessage(JSON.parse(res.message).message);
    if (res.error !== 204) {
      console.log('pb suppression');
    }
  };

  const handleCloseAgree = () => {
      delReine(reine)
      .then(() => {
  
          dispatch(setAffReine(false));
          dispatch(setAffDelReine(false));
          dispatch(setAffDisplayReine(false));
          dispatch(setAffGenealogie('NON'));
          dispatch(setAffFilles('NON'));
  
      })
      .catch((res) => handleDelError(res))
    };



  return (

    <>
      <Dialog
        open={affDelReine}
        keepMounted
        onClose={handleCloseIgnore}
        aria-describedby="alert-dialog-slide-description"
        maxWidth='sm'
        fullWidth

          >
        <Container>

            <DialogTitle>{"Suppression de la  reine " + reine.nom + " de " + reine.eleveur_nom}</DialogTitle>


            <DialogContent dividers>
            {ErrorMessage ? (
                        <>
                          <Divider variant="middle" />
                          <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                          >
                            <ReportProblemIcon sx={{ fontsize: 20, color: red[700] }} />
                            <Typography fontSize={20} sx={{ color: red[700] }}>{ErrorMessage}</Typography>
                          </Stack>
                        </>
                      ) : null}            
              </DialogContent>
              <DialogContentText id="alert-dialog-slide-description">
                Confirmez-vous cette Suppression ?
              </DialogContentText>
            <DialogActions>
              <Button onClick={handleCloseIgnore}>Annuler</Button>
              <Button onClick={handleCloseAgree}>Supprimer</Button>
            </DialogActions>
        </Container>
      </Dialog>
    </>
  );
};

export default DelReine;