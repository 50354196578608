import React, { useState, useRef, useEffect } from 'react';

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Button, Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import RefreshIcon from '@mui/icons-material/Refresh';

import makeStyles from '@mui/styles/makeStyles';


import { DetReineParNom, listAncetresReine, listFillesdeReine, getListReines } from '../services/accesApi';
import { useRaffrichiReines } from '../services/useRaffraichiReines';

import { useDispatch, useSelector } from 'react-redux';
import { reineStore, reineRevoke, lesFillesMStore, lesFillesPStore, lesReinesStore } from '../../store/reineslice'
import { treeStore, treeRevoke } from '../../store/treeslice'
import {
  toggleAffReine, setAffDisplayReine, setAffGenealogie, setAffFilles, setAffCreeReine,
  setAffTabListeReines
} from '../../store/displayslice'

//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';


import CustomInput from '../../components/CustomInput';

const useStyles = makeStyles({
  root: {
    '& Div': {
      padding: 0,
    },
  },
});

// Pour la gestion des tabulations
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch', heigth: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


function ChercheReine() {
  const dispatch = useDispatch()
  const [allFetch, setAllFetch] = useState();
  const [rows, setRows] = useState();
  const [rowsApi, setRowsApi] = useState();

  const [rowModesModel, setRowModesModel] = React.useState({});
  const [afficheListe, setAfficheListe] = useState(true)


  const { reine } = useSelector(state => state.reine);
  const { lesReines } = useSelector(state => state.reine);
  //const lesReines = [];

  const { affTabListeReines } = useSelector(state => state.display);
  const [valTab, setValTab] = useState(0);
  const classes = useStyles();
  //const reines = useSelector(state => state.reine).lesReines;

  const { user } = useSelector(state => state.user);
  const [ErrorMessage, setErrorMessage] = useState('');
  const formRef = useRef();
  // forçage rerener
  const [render, rerender] = useState(false);

  const handleChangeTabs = (event, newValue) => {
    console.log(newValue);
    //    setAfficheListe(true);

    dispatch(setAffTabListeReines(true));
    setValTab(newValue);

  };

  const leslignes = (items) => {
    console.log(items);
    var lignes = [];
    var ind = 0;
    for (var reine of items) {
      lignes.push({
        'id': reine.id, 'apiculteurNom': reine.eleveur_nom,
        'nom': reine.nom,
        'raceNom': reine.race_nom,
        'anneeNaissance': reine.anneeNaissance,
        'destination': reine.destination,
        'tauxConforme': reine.TauxConforme,
        'tauxVSH': reine.TauxVsh,
        'haplotype': reine.haplotype,

      })
      ind++;
    };
    return lignes
  };

  const leslignesApi = (items) => {
    console.log(items);
    var lignes = [];
    var ind = 0;
    for (var reine of items) {
      if   (user && user.id == reine.editeur) {

      lignes.push({
        'id': reine.id, 'apiculteurNom': reine.eleveur_nom,
        'nom': reine.nom,
        'raceNom': reine.race_nom,
        'anneeNaissance': reine.anneeNaissance,
        'destination': reine.destination,
        'tauxConforme': reine.TauxConforme,
        'tauxVSH': reine.TauxVsh,
        'haplotype': reine.haplotype,

      })
      ind++;
    }};
    return lignes
  };

  useEffect(() => {

    let mounted = true;
    setAllFetch(false);
          setRows(leslignes(lesReines));
          setRowsApi(leslignesApi(lesReines));

    return () => { mounted = false; setAllFetch(false); };

  }, [lesReines]);

  const handleLoadingError = (res) => {

    if (res.error === 404) {
      dispatch(setAffDisplayReine(false));
      dispatch(setAffGenealogie('NON'));
      dispatch(setAffFilles('NON'));      
      setErrorMessage('Reine non trouvée');


    } else {
      console.log("else");
      console.log(res.message);
      console.log(typeof (res.message));
      console.log(res.message);
      setErrorMessage(JSON.parse(res.message).message);
    };
  };

  const FormonSubmit = (data) => {
    console.log('n submit');
    /*      Promise.all([useRaffrichiReines(data.nom)])
          .then(() => {
            dispatch(setAffReine('AFFI'));
            dispatch(setAffGenealogie('OUI')) ;
            dispatch(setAffFilles('OUI')) ;        
          }); */

    /*      useRaffrichiReines(data.nom);        
          dispatch(setAffReine('AFFI'));
          dispatch(setAffGenealogie('OUI')) ;
          dispatch(setAffFilles('OUI')) ; */
    Promise.all([DetReineParNom(data.nom), listAncetresReine(data.nom), listFillesdeReine(data.nom, true), listFillesdeReine(data.nom, false)])
      .then(([laReine, lesAncetres, lesFillesM, lesFillesP]) => {
        dispatch(reineStore(laReine));
        dispatch(treeStore(lesAncetres));
        dispatch(lesFillesMStore(lesFillesM));
        dispatch(lesFillesPStore(lesFillesP));

        dispatch(setAffDisplayReine(true));
        dispatch(setAffGenealogie('OUI'));
        dispatch(setAffFilles('OUI'));
        setErrorMessage('');
      })
      .catch((res) => handleLoadingError(res))
      ;


  };


  const Initialisation = () => {
    const init = new Object();
    init.nom = "";
    return init
  };

  const ValidationSchema = yup.object().shape({
    nom: yup
      .string()
      .min(1, 'tropCourt')
      .max(20, 'tropLong')
      .required('nomObligatoire'),
  });


  const handleRefresh = () => {
    getListReines()
      .then(items => {
          //  setLesEleveurs(items);
          dispatch(lesReinesStore(items));
          setRows(leslignes(items))
          setRowsApi(leslignesApi(items))
        })
  }  ;

  const appelleCreineReine = () => {
    if (formRef.current) formRef.current.values.init = "";
    rerender(!render);
    dispatch(setAffCreeReine(true));
  };

  const handleSearchClick = (idReine) => {
    console.log(idReine);
    const ind = rows.map(e => e.id).indexOf(idReine);
    let nom = rows[ind].nom;
    Promise.all([DetReineParNom(nom), listAncetresReine(nom), listFillesdeReine(nom, true), listFillesdeReine(nom, false)])
      .then(([laReine, lesAncetres, lesFillesM, lesFillesP]) => {
        dispatch(reineStore(laReine));
        dispatch(treeStore(lesAncetres));
        dispatch(lesFillesMStore(lesFillesM));
        dispatch(lesFillesPStore(lesFillesP));
        setAfficheListe(false);

        dispatch(setAffDisplayReine(true));
        dispatch(setAffGenealogie('OUI'));
        dispatch(setAffFilles('OUI'));
        setErrorMessage('');
      })
      .catch((res) => handleLoadingError(res))
      ;

  };

  const handleSearchClickApi = (idReine) => {
    console.log(idReine);
    const ind = rowsApi.map(e => e.id).indexOf(idReine);
    //let indTravFaits = lesTravFaits.map(e => e.id).indexOf(idReine);

    console.log(rows[ind]);
    let nom = rowsApi[ind].nom;
    Promise.all([DetReineParNom(nom), listAncetresReine(nom), listFillesdeReine(nom, true), listFillesdeReine(nom, false)])
      .then(([laReine, lesAncetres, lesFillesM, lesFillesP]) => {
        dispatch(reineStore(laReine));
        dispatch(treeStore(lesAncetres));
        dispatch(lesFillesMStore(lesFillesM));
        dispatch(lesFillesPStore(lesFillesP));
        setAfficheListe(false);

        dispatch(setAffDisplayReine(true));
        dispatch(setAffGenealogie('OUI'));
        dispatch(setAffFilles('OUI'));
        setErrorMessage('');
      })
      .catch((res) => handleLoadingError(res))
      ;

  };  

  const columns = [
    // { field: 'id', headerName: 'id', width: 150 },
    //    { field: 'nom', headerName: 'nom', editable: false, flex: 25 },
    { field: 'nom', headerName: 'nom', editable: false, minWidth: 75, flex: 0.12 },
    { field: 'anneeNaissance', headerName: 'Née', editable: false, minWidth: 60, flex: 0.07 },
    { field: 'apiculteurNom', headerName: 'Apiculteur', editable: false, minWidth: 100, flex: 0.15 },
    { field: 'raceNom', headerName: 'Race', editable: false, minWidth: 75, flex: 0.1 },
    { field: 'destination', headerName: 'destin.', editable: false, minWidth: 75, flex: 0.1 },
    { field: 'tauxConforme', headerName: 'Bio Conf', editable: false, minWidth: 60, flex: 0.1 },
    { field: 'tauxVSH', headerName: 'VSH', editable: false, minWidth: 50, flex: 0.1 },
    { field: 'haplotype', headerName: 'Type', haplotype: false, minWidth: 50, flex: 0.08 },
    {
      field: 'actions',
      type: 'actions',
      width: 40,
      getActions: ({ id }) => {
        return [
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="Edit" className="textPrimary"
            onClick={() => handleSearchClick(id)}
            color="inherit"
          />,
        ];
      },
    },];

    const columnsApi = [
      // { field: 'id', headerName: 'id', width: 150 },
      //    { field: 'nom', headerName: 'nom', editable: false, flex: 25 },
      { field: 'nom', headerName: 'nom', editable: false, minWidth: 75, flex: 0.12 },
      { field: 'anneeNaissance', headerName: 'Née', editable: false, minWidth: 60, flex: 0.07 },
      { field: 'apiculteurNom', headerName: 'Apiculteur', editable: false, minWidth: 100, flex: 0.15 },
      { field: 'raceNom', headerName: 'Race', editable: false, minWidth: 75, flex: 0.1 },
      { field: 'destination', headerName: 'destin.', editable: false, minWidth: 75, flex: 0.1 },
      { field: 'tauxConforme', headerName: 'Bio Conf', editable: false, minWidth: 60, flex: 0.1 },
      { field: 'tauxVSH', headerName: 'VSH', editable: false, minWidth: 50, flex: 0.1 },
      { field: 'haplotype', headerName: 'Type', haplotype: false, minWidth: 50, flex: 0.08 },
      {
        field: 'actions',
        type: 'actions',
        width: 40,
        getActions: ({ id }) => {
          return [
            <GridActionsCellItem
              icon={<SearchIcon />}
              label="Edit" className="textPrimary"
              onClick={() => handleSearchClickApi(id)}
              color="inherit"
            />,
          ];
        },
      },];    


  return (
    <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }}>
      <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <h4>Rechercher une Reine</h4>
        {user &&
          <Tooltip title={<Typography fontSize={20}>Nouvelle reine</Typography>} placement="top" sx={{ fontSize: 200 }}>
            <IconButton onClick={() => { appelleCreineReine() }} sx={{ color: green[700] }} >
              <AddCircleOutlineIcon sx={{ fontSize: 40 }} />
            </IconButton>
          </Tooltip>}
      </Box>
      <Box sx={{ borderBottom: 1, borderColor: 'divider', display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
        <Box sx={{ flex: 9 }}>

          <Tabs value={valTab} onChange={handleChangeTabs} aria-label="basic tabs example">
            <Tab label="Par Nom" {...a11yProps(0)} />
            <Tab label="Mes reines" {...a11yProps(1)} />
            <Tab label="Recherche Multi Critères" {...a11yProps(2)} onClick={() => { setAfficheListe(true) }} />

          </Tabs>
        </Box>
        <Box sx={{ flex: 1 }}>
          <IconButton onClick={() => { handleRefresh() }} >
            <RefreshIcon sx={{ fontSize: 40, color: grey[900] }} />
          </IconButton>
        </Box>
        <Box sx={{ flex: 1 }}>
          {afficheListe ?
            <IconButton onClick={() => { setAfficheListe(!afficheListe); }} >
              <ExpandLessIcon sx={{ fontSize: 40, color: grey[900] }} />
            </IconButton>
            :
            <IconButton onClick={() => { setAfficheListe(!afficheListe); }} >
              <ExpandMoreIcon sx={{ fontSize: 40, color: grey[900] }} />
            </IconButton>
          }
        </Box>
      </Box>
      <TabPanel value={valTab} index={0} className={classes.root}>

        <Formik
          initialValues={Initialisation()}
          validationSchema={ValidationSchema}
          onSubmit={values => FormonSubmit(values)}
          innerRef={formRef}

        >
          {({
            handleSubmit, isValid, values, setFieldValue, setFieldTouched,
            errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
          }) =>
          (
            <>
              <Form>
                <Grid container display="flex" justifyContent="flex-start">

                  <Field component={CustomInput} name="nom" label={'Nom'} placeholder={'Nom'} />
                  {ErrorMessage ? (
                    <Typography color="red">{ErrorMessage}</Typography>
                  ) : null}
                  <Tooltip title={<Typography fontSize={20}>Rechercher</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton type="submit" >
                      <SearchIcon sx={{ fontSize: 40 }} />
                    </IconButton>
                  </Tooltip>
                </Grid>

                {!user &&
                  <Typography>Identifiez-vous pour enregistrer des reines      </Typography>}
              </Form>
            </>
          )}
        </Formik>
      </TabPanel>
      <TabPanel value={valTab} index={1} className={classes.root}>


        {user && affTabListeReines && afficheListe && (<><div style={{ height: 600, width: '400' }}>

          <DataGrid
            columns={columns}
            rows={rowsApi}
            editMode="row"
            rowHeight={30}
            rowModesModel={rowModesModel}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            experimentalFeatures={{ newEditingApi: false }}
            sx={{ m: 0, }}
            componentsProps={{
              panel: { sx: { top: '-70px !important' }, }
            }}
          />
        </div></>)}
      </TabPanel>
      <TabPanel value={valTab} index={2} className={classes.root}>


        {affTabListeReines && afficheListe && (<><div style={{ height: 600, width: '400' }}>

          <DataGrid
            columns={columns}
            rows={rows}
            editMode="row"
            rowHeight={30}
            rowModesModel={rowModesModel}
            localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
            experimentalFeatures={{ newEditingApi: false }}
            sx={{ m: 0, }}
            componentsProps={{
              panel: { sx: { top: '-70px !important' }, }
            }}
          />
        </div></>)}
      </TabPanel>
    </Paper>

  );
}


export default ChercheReine;
