import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { useDispatch, useSelector } from 'react-redux';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import { makeStyles } from '@mui/styles';
import Tooltip from '@mui/material/Tooltip';


import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext, gridTopLevelRowCountSelector
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import SortIcon from '@mui/icons-material/Sort';


import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import PrintIcon from '@mui/icons-material/Print';
import SyncIcon from '@mui/icons-material/Sync';
import { red, amber, grey, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';


import Dialog from '@mui/material/Dialog';
import Button from '@mui/material/Button';


import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';

import CustomTextStd2 from '../../components/CustomTextStd2';

import {
  getLastRecolte, getLastVisite, getLastSoin, getLastComptage, getLastObservation, getLastDemenagement, getDetColonie,
  getListTypPb, getListTypCR, getListTypMedoc, getListUniNourri, getOptionsVisite, getFirstVisite,
  getFirstColonie, listColoniesdeApi, getListRuchersDeApi, listColoniesDelegation, getListTravauxAFaire,
  getLastHausseVisites, getColoniePhoto, prepaVisite
} from '../services/accessApiRuchers';


import { randomId } from '@mui/x-data-grid-generator';

// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import { setAffColonie, setAffCreeRucher, setAffCreeColonie, setAffTrieColonie } from '../../store/displayRucherslice'
import {
  laColonieStore, laColoniePhotoStore, laDerniereVisiteStore, laVisiteStore,
  lesTravauxAFaireStore,
  leDernierSoinStore, leSoinStore,
  leDernierComptageStore, leComptageStore,
  laDerniereRecolteStore, laRecolteStore,
  laDerniereObservationStore, lObservationStore,
  lesColoniesDeApiStore, lesRuchersTravailStore,
  apiProprietaireStore, lesColoniesDelegationStore,
  lesHausseVisitesStore,
} from '../../store/ruchersslice'
import { DisplaySettingsRounded } from '@mui/icons-material';
import i18n from '../services/i18n';
import { dispDate, dispDate6 } from '../services/prepDate';
import { bSante } from '../services/bSante';

// padding 0 à l'intérieur des tabulations
const useStyles = makeStyles({
  root: {
    '& Div': {
      padding: 0,
    },
  },
  grey: {
    color: grey[500],
    backgroundColor: grey[100],
  },
  black: {
    color: "black",
  }
});


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function AfficheLesColoniesduRucher() {

  const { user } = useSelector(state => state.user);
  const langue = user.langage;
  let itemApi = user.id;


  const leslignes = (items) => {
    var lignes = [];
    var ind = 0;
    for (var colonie of items) {
      lignes.push({
        'id': colonie.id, 'colonie': colonie.nom, 'rucher': colonie.nomRucher,
        'sante': colonie.get_last_sante,
        'date': (colonie.get_last_date_visite) ? dispDate6(colonie.get_last_date_visite, langue) : "",
        'apiId': colonie.get_apiculteur,
        'motSuppression': colonie.motifsuppression
      })
      ind++;
    };
    console.log(lignes);
    return lignes
  };


  const dispatch = useDispatch()
  const [valTab, setValTab] = useState(0);

  const [allFetch, setAllFetch] = useState();
  const [rows, setRows] = useState();
  const [affMessage, setAffMessage] = useState(false);


  const { lesColoniesDuRucher, lesColoniesDelegation, apiProprietaire, leRucher } = useSelector(state => state.ruchers);
  console.log(lesColoniesDuRucher);

  useEffect(() => {
    let mounted = true;
    setAllFetch(false);
    if (mounted) {
      setRows(leslignes(lesColoniesDuRucher));
      setAllFetch(true);
      //console.log(lesColoniesDeApi);
    }
    return () => { mounted = false; setAllFetch(false); };

  }, [lesColoniesDuRucher]);

  const [idr, setIdr] = useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const [messageErreur, setMessageErreur] = useState();

  const handleChange = (event, newValue) => {
    setValTab(newValue);
  };



  const handleLoadingError = (res) => {
    console.log('erreur');
    console.log(res);
  };

  const nouveauRucher = () => {
    dispatch(setAffCreeRucher(true));
  };

  const nouvelleColonie = () => {
    getListRuchersDeApi(apiProprietaire)
      .then((ruchers) => {

        dispatch(lesRuchersTravailStore(ruchers));
        dispatch(setAffCreeColonie(true));
      })

  };

  const TrieLesColoniesDuRucher = () => {
    dispatch(setAffTrieColonie(true));
  };

  const editePrepaVisite = () => {
    prepaVisite(leRucher.id);
    setAffMessage(true);
  };

  const handleClose = () => {
    setAffMessage(false);
  };

  const handleEditClick = (idColonie, douJeviens) => {


    console.log(douJeviens);
    Promise.all([getDetColonie(JSON.stringify(idColonie)), getLastRecolte(JSON.stringify(idColonie)), getLastVisite(JSON.stringify(idColonie)),
    getLastSoin(JSON.stringify(idColonie)), getLastComptage(JSON.stringify(idColonie)), getLastObservation(JSON.stringify(idColonie)), getLastDemenagement(JSON.stringify(idColonie)),
    getListTravauxAFaire(idColonie), getLastHausseVisites(idColonie), getColoniePhoto(idColonie)])
      .then(([laColonie, itemRecolte, itemVisite, itemSoin, itemComptage, itemObservation, itemDemenagement, lesTravAF, hausseVisite, iPhoto]) => {
        dispatch(laColonieStore(laColonie)); dispatch(laColoniePhotoStore(iPhoto.photo));
        dispatch(laDerniereVisiteStore(itemVisite)); dispatch(laVisiteStore(itemVisite));
        dispatch(lesTravauxAFaireStore(lesTravAF));
        dispatch(setAffColonie(true));
        dispatch(leDernierSoinStore(itemSoin)); dispatch(leSoinStore(itemSoin));
        dispatch(leDernierComptageStore(itemComptage)); dispatch(leComptageStore(itemComptage));
        dispatch(laDerniereObservationStore(itemObservation)); dispatch(lObservationStore(itemObservation));
        dispatch(laDerniereRecolteStore(itemRecolte)); dispatch(laRecolteStore(itemRecolte));
        dispatch(apiProprietaireStore(apiProprietaire));
        dispatch(lesHausseVisitesStore(hausseVisite));

        setMessageErreur('');
        //console.log('fini !!!!');
      })
      .catch((res) => handleLoadingError(res));

    //console.log(idColonie)
  };


  const columns = [
    // { field: 'id', headerName: 'id', width: 150 },
    { field: 'colonie', headerName: 'Colonie', editable: true, flex: 100 },
    { field: 'rucher', headerName: 'Rucher', editable: true, flex: 150 },
    { field: 'date', headerName: 'Visitée le', editable: true, flex: 80 },
    {
      field: 'sante',
      headerName: 'sante',
      flex: 50,

      renderCell: (params) => (
        <Box sx={{ width: 30 }} bgcolor={bSante(params.row.sante)} >
          &nbsp;
        </Box>
      ),
    },
    {
      field: 'actions',
      type: 'actions',
      width: 80,
      getActions: ({ id }) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;
        return [
          <GridActionsCellItem
            icon={<SearchIcon />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(id, 'proprio')}
            color="inherit"
          />,
        ];
      },
    },];



  const setRefresh = () => {
    Promise.all([listColoniesdeApi(itemApi), listColoniesDelegation(itemApi)])
      .then(([coloniesdeApi, coloniesDelegation]) => {
        dispatch(lesColoniesDeApiStore(coloniesdeApi));
        dispatch(lesColoniesDelegationStore(coloniesDelegation));
      })
  }

  const classes = useStyles();



  return (

    <>
      {allFetch && (
        <>
          <Dialog
            open={affMessage}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth='sm'
            fullWidth

          >
            <Container>
              <DialogTitle>{"Statistiques"}</DialogTitle>
              <DialogContentText id="alert-dialog-slide-description">
                La demande de stats est partie. Surveillez vos mails et votre dossier "indésirables"
              </DialogContentText>
              <DialogActions>
                <IconButton
                  onClick={() => { handleClose() }}
                  sx={{ color: green[700] }} >
                  <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </DialogActions>
            </Container>
          </Dialog>
          <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Typography>Les Colonies à {leRucher.nom}</Typography>
            </Box>

            <div style={{ height: 400, width: '100%' }}  >
              <DataGrid
                columns={columns}
                rows={rows}
                editMode="row"
                rowHeight={30}
                rowModesModel={rowModesModel}
                localeText={frFR.components.MuiDataGrid.defaultProps.localeText}
                getRowClassName={(params) => { return params.row.motSuppression == null ? classes.black : classes.grey }}

                experimentalFeatures={{ newEditingApi: true }}
                sx={{ m: 0 }}
                componentsProps={{
                  panel: { sx: { top: '-70px !important' }, }
                }}
              />
            </div>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>

              <Button variant="text" endIcon={<AddCircleOutlineIcon />} onClick={() => { nouvelleColonie(); }}>
                Nouvelle Colonie
              </Button>
              <Button variant="text" endIcon={<SortIcon />} onClick={() => { TrieLesColoniesDuRucher(); }}>
                Trier les Colonies
              </Button>
              <Tooltip title={<Typography fontSize={20}>Préparer visite</Typography>} placement="top" sx={{ fontSize: 200 }}>

                <IconButton
                  onClick={() => { editePrepaVisite() }}
                  sx={{ color: green[700] }} >
                  <PrintIcon sx={{ fontSize: 40 }} />
                </IconButton>
              </Tooltip>
            </Box>
            {messageErreur ? <p>{messageErreur}</p> : null}


          </Paper>
        </>
      )}
    </>
  )
};
// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>

export default AfficheLesColoniesduRucher;