import { ConstructionOutlined } from '@mui/icons-material';
import { post, get, put, del } from '../../services/fetch';
//import { post, get, put, options } from '../../services/fetch';
//import { setIdApiculteur } from '../../rucherModule/services/idApiculteur';
//import { setLangue } from '../../rucherModule/services/getLangue';

export const login = (username, password) => {
  localStorage.setItem('token', null);

  return post('/api-token-auth/', {
     username, password });
};


export const createAccount = (username, email, password1, password2) => {
//    console.log(username);
//    console.log(password1);
  return post ('/dj-rest-auth/registration/', { username, email, password1, password2 }
  );
};

/*
export const changePassword = ( oldpassword, new_password1, new_password2) => {
//    console.log(oldpassword);
//    console.log(password1);
  return post ('/dj-rest-auth/password/change/', { oldpassword, new_password1, new_password2 }
  );
};

*/
// a remplacer par retrieve
export const retrieveApiculteur = () => {
  get ('/RetrieveApiculteur')
  .then( (item) => {
//    setIdApiculteur(item.id);
//    setLangue(item.langage);
    console.log(item);
    return item; });
};

export const postPasswordReset = (email) => {
  return post ('/password_reset/', {email})
}

export const confirmPassword = (token, password) => {
  console.log(token);
  return post ('/password_reset/confirm/', {token, password})
}


export const retrieveApiculteurTotal = () => {
  return get ('/RetrieveApiculteurV2');
};

/*

export const getOptionsApiculteur = () => {
  return options ('/RetrieveApiculteurV2');
};
*/

export const modApiculteur = (idApi, nom, langage, utiliseMeteo, utiliseIntervenant,
  utiliseNbElts, utilisePoids, utiliseCadresCouvain, utiliseRuelles, utiliseComportement, utiliseTenueCadre,
  utiliseSante, utiliseObservation, UtileseChampLibre1, nomChampLibre1,
  UtileseChampLibre2, nomChampLibre2) => {
  return put ('/DetApiculteurV2/' + idApi, {nom, langage, utiliseMeteo, utiliseIntervenant,
    utiliseNbElts, utilisePoids, utiliseCadresCouvain, utiliseRuelles, utiliseComportement, utiliseTenueCadre,
    utiliseSante, utiliseObservation, UtileseChampLibre1, nomChampLibre1,
    UtileseChampLibre2, nomChampLibre2});
};
/*

export const getDetApiculteur = (idApiculteur) => {
  return get('/DetApiculteurV2/' + idApiculteur );
};
 */

export const getListDelegue = () => {
  return get('/ListDelegue');
};

export const createDelegue = async (apiculteur , nomdelegue) => {
  return post('/NewDelegue', {
    apiculteur, nomdelegue}
   )
};
    
export const delDelegue = async (delegue) => {
   const Moment = require('moment');

   
   return del ('/DetDelegue/' + delegue.id );
};
