
// style des liens non opérents


import React, { useState, useEffect, useLayoutEffect } from 'react';
import * as d3 from 'd3';
import Tree from 'react-d3-tree';


import { Formik, Field, Form, 
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { grey, amber, green } from '@mui/material/colors';


import { Button, Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';

import { DetReineParNom, listAncetresReine, listFillesdeReine } from '../services/accesApi';
import '../services/style.css';
import {useDispatch, useSelector} from 'react-redux'
//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';
import {reineStore, reineRevoke, lesFillesMStore, lesFillesPStore} from '../../store/reineslice'
import {treeStore, treeRevoke} from '../../store/treeslice'
import {toggleAffReine, setAffReine, setAffGenealogie} from '../../store/displayslice'

import CustomInput from '../../components/CustomInput';

//import {toggleAffReine, setaffReine, setmodReine, setAffReine, affGenealogie} from '../../store/displayslice'

//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';

//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';


function AfficheGenealogie() {

    const dispatch = useDispatch()   

    const { reine } = useSelector(state => state.reine)
    const { arbreAncetre } = useSelector(state => state.arbreGene)
    const { affGenealogie } = useSelector(state => state.display)

    const RechReine = (nom) => {
       Promise.all([DetReineParNom(nom),listAncetresReine(nom), listFillesdeReine(nom,true), listFillesdeReine(nom,false)]) 
          .then(([laReine, lesAncetres, lesFillesM, lesFillesP]) => {
            dispatch(reineStore(laReine));
            dispatch(treeStore(lesAncetres));
            dispatch(lesFillesMStore(lesFillesM));
            dispatch(lesFillesPStore(lesFillesP));
  
            //dispatch(setAffReine('AFFI'))
            //dispatch(setAffGenealogie('OUI'))          
          });
        
  
      };
  
const renderRectSvgNode = ({ nodeDatum, toggleNode }) => (
  <g>
    { nodeDatum.name != "-" && <rect fill="gold" width="20" height="20" x="-10" y="-10" onClick={()=> {RechReine(nodeDatum.name)}} />}
    <text  x="-15" y="-5" strokeWidth="1" fontSize="smaller" textAnchor="end">
      {nodeDatum.name}
    </text>
    {nodeDatum.typeFecondation_nom && (
      <text fill="black" x="15" dy="-5" strokeWidth="1" fontSize="smaller">
        {nodeDatum.typeFecondation_nom}
      </text>
    )}
    {nodeDatum.lieuFecondation && (
      <text fill="black" x="15" dy="15" strokeWidth="1" fontSize="smaller">
        {nodeDatum.lieuFecondation}
      </text>
    )}    
  </g>
);
const getDynamicPathClass = ({ source, target }, orientation) => {
  if (!target.children) {
    // Target node has no children -> this link leads to a leaf node.
    return 'linktoleaf';
  }

  // Style it as a link connecting two branch nodes by default.
  return 'linktobranch';
};
return(
<>
<Box sx={{bgcolor : amber[100]}}>
<h4>Généalogie de : {reine.nom}</h4>
<div id="treeWrapper" style={{ width: '50em', height: '38em' }}>
      <Tree data={arbreAncetre.data}
         pathFunc="step"
         depthFactor = {150}
         separation={{siblings:1, nonSiblings: 1}}
         nodeSize={{x:10, y:37}}
         renderCustomNodeElement={renderRectSvgNode}
         pathClassFunc={getDynamicPathClass}         
         dimensions={{ height: 50, width: 50 }}
         translate={{ x: 100, y: 295 }}
         zoomable={false}
         styles={{
          links: {
             
              stroke: 'red',
              strokeWidth: "2px",
            },
     }}
       />
    </div>
  </Box>
</>
)
}
// <div id="treeWrapper" style={{ width: '50em', height: '20em' }}>
    
// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>
    
export default AfficheGenealogie;