import React, { useState } from 'react'
import { Container, Row, Col, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CheckIcon from '@mui/icons-material/Check';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';


import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { grey, amber, red, deepOrange, green } from '@mui/material/colors';
import { prepDate, dispDate } from '../../services/prepDate';

import CustomInput from '../../components/CustomInput';

import i18n from '../services/i18n';


const SaisieTravailEnAttente = (props) => {
  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched, setFieldValue }, label, labD = 4, fieldD = 8, required = false, index,
    travail: { nomFaire, nomDefaire, nomCommentaire, fait, deFait, aNomDefaire, aCommentaire, faire, commentaire,
      deFaire, dateS, id
    },
    onClickFait, onClickAbnd, setField,
    ...inputProps
  } = props

  //const [trav, setTral] = useState(fait, deFait);

  const hasError = errors[name] && touched[name]
  console.log(props);
  //console.log(value.travauxFaits[index].fait);
  return (
    <>
      {/*    <View style={styles.container}> */}
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {(faire) ? (
          <Box border={1} borderRadius={1} sx={{ flex: 2, borderColor: amber[700] }}>
            <Typography align='center' sx={{ m: 0, color: deepOrange[900] }}>{nomFaire}</Typography>
          </Box>
        ) : <Box sx={{ flex: 2 }}></Box>}
        {(deFaire) ? (
          <Box border={1} borderRadius={1} sx={{ flex: 2, borderColor: amber[700] }}>
            <Typography align='center' sx={{ m: 0, color: deepOrange[900] }}>{nomDefaire}</Typography>

          </Box>
        ) : <Box sx={{ flex: 2 }}></Box>}
        {(aCommentaire) ? (
          <Box sx={{ flex: 2 }}>
            <Typography align='left' sx={{ m: 0, color: deepOrange[900] }}>&nbsp; {nomCommentaire + ' ' + commentaire}</Typography>

          </Box>
        ) : <Box sx={{ flex: 2 }}></Box>}
        {(dateS) ? (
          <Box sx={{ flex: 2 }}>
            <Typography align='left' sx={{ m: 0, color: deepOrange[900] }}> avant le {dispDate(dateS)}</Typography>

          </Box>
        ) : <Box sx={{ flex: 2 }}></Box>}
        <Box sx={{ flex: 1}}>
          <Tooltip title={<Typography fontSize={20}>Enregistrer Travail</Typography>} followCursor placement="right-start" sx={{ fontSize: 200 }}>
            <IconButton onClick={() => { onClickFait(id, setField) }} sx={{ color: green[700] }}>
              <CheckIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>
          <Tooltip title={<Typography fontSize={20}>Abandonner Travail</Typography>} followCursor placement="right-start" sx={{ fontSize: 200 }}>
            <IconButton onClick={() => { onClickAbnd(id, setField) }} sx={{ color: grey[700] }}>
              <CancelPresentationIcon sx={{ fontSize: 20 }} />
            </IconButton>
          </Tooltip>

        </Box>
      </Box>

    </>
  )
}

let styles = {
  textInput: {
    height: 25,
    width: '100%',
    margin: 0,
    backgroundColor: 'white',
    borderColor: amber[800],
    borderWidth: 1,
    borderRadius: 5,
    textAlignVertical: 'top',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  label: {
    flex: 1
  },

  errorText: {
    fontSize: 10,
    color: 'red',
  },
  errorInput: {
    borderColor: 'red',
  }
}


export default SaisieTravailEnAttente;
