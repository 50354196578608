import React, { useState, useEffect, useLayoutEffect } from 'react';

import { Formik, Field, Form, 
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Button, Container, ButtonGroup, Dropdown } from "react-bootstrap";
import { DetReineParNom, listAncetresReine, listFillesdeReine } from '../services/accesApi';
import { useRaffrichiReines } from '../services/useRaffraichiReines';
//import { raffrichiReines } from '../services/raffrichiReines';
import {reineStore, reineRevoke, lesFillesMStore, lesFillesPStore} from '../../store/reineslice'
import {treeStore, treeRevoke} from '../../store/treeslice'

import {useDispatch, useSelector} from 'react-redux'
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import { grey, amber, green } from '@mui/material/colors';
import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';


//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';

import {toggleAffReine, setaffReine, setmodReine, setAffReine} from '../../store/displayslice'

import CustomTextStd from '../../components/CustomTextStd';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';

//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
};

function AfficheFilles() {

    const dispatch = useDispatch()   

    const { reine } = useSelector(state => state.reine)

    const { lesFillesM } = useSelector(state => state.reine)
    const { lesFillesP } = useSelector(state => state.reine)
    const { user } = useSelector(state => state.user);


    const RechReine = (nom) => {

      //useRaffrichiReines(nom);        
      Promise.all([DetReineParNom(nom),listAncetresReine(nom), listFillesdeReine(nom, true), listFillesdeReine(nom, false)]) 
      .then(([laReine, lesAncetres, fillesM, fillesP]) => {
        dispatch(reineStore(laReine));
        dispatch(treeStore(lesAncetres));
        dispatch(lesFillesMStore(fillesM));
        dispatch(lesFillesPStore(fillesP));
        //setLesFillesM(fillesM);
        //setLesFillesP(fillesP);
        //dispatch(setAffReine('AFFI'))
        //dispatch(setAffGenealogie('OUI'))          
      })
      .catch(console.log('errreur    '))
      ;       
 
     };    

return(
<>

<Box sx={{bgcolor : amber[100]}}>

<h4>les Filles de {reine.nom}</h4>
{ (lesFillesM.length> 0) && 
<>
<h5>En tant que mère</h5>
<Grid display="flex"  container>
{lesFillesM.map (fille => (
  <>
    <Grid xs={2}>
      <Link component="button" variant="body2" key={fille.nom} onClick={()=> {RechReine(fille.nom)}}>
        {fille.nom } &nbsp;
      </Link>
    </Grid>  
  </>
))}
</Grid>
</>
}
{ (lesFillesP.length> 0) && 
<>
<h5>En tant que Ruche à Males</h5>
<Grid display="flex"  container>
{lesFillesP.map (fille => (
  <>
    <Grid xs={2}>
      <Link component="button" variant="body2" key={fille.nom} onClick={()=> {RechReine(fille.nom)}}>
        {fille.nom } &nbsp;
      </Link>
    </Grid>  
  </>
))}
</Grid>
</>
}
</Box>

</>
);
};
export default AfficheFilles;