import React, { useState, useEffect } from 'react';

// npm install @dnd-kit/core @dnd-kit/utilities @dnd-kit/sortable


import {
  DndContext,
  useSensors,
  useSensor,
  PointerSensor,
  KeyboardSensor,
  closestCenter,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  useSortable,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";



import { useDispatch, useSelector } from 'react-redux'
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Grid from '@mui/material/Unstable_Grid2';
import Container from '@mui/material/Container';
import Divider from '@mui/material/Divider';

import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import IconButton from '@mui/material/IconButton';

import { makeStyles } from '@mui/styles';

import { amber, grey, green } from '@mui/material/colors';



import CustomTitre from '../../components/CustomTitre';


import {
  modMultiRuchers

} from '../services/accessApiRuchers';


import {
  setAffTrieRucher
} from '../../store/displayRucherslice'
import {
  lesRuchersDeApiStore
} from '../../store/ruchersslice'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 700,
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};



function TrieLesRuchersdeApi() {

  const [visible, setVisible] = useState(true);

  const dispatch = useDispatch()


  const { lesRuchersDeApi } = useSelector(state => state.ruchers);
  const [lesRuchers, setLesRucher] = useState(lesRuchersDeApi);

  function handleDragEnd(event) {
    const { active, over } = event;
    if (active?.id !== over?.id) {
      setLesRucher((prev) => {
        const activeIndex = prev.findIndex((item) => item.id === active?.id);
        const overIndex = prev.findIndex((item) => item.id === over?.id);
        return arrayMove(prev, activeIndex, overIndex);
      });
    }
  }



  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );




  function SortableItem({ item }) {
    const {
      attributes,
      listeners,
      setNodeRef,
      setActivatorNodeRef,
      transform,
      transition,
    } = useSortable({
      id: item.id,
    });
    const style = {
      transform: CSS.Transform.toString(transform),
      transition,
    };


    return (
      <Box
        ref={setNodeRef}
        style={style}
        {...attributes}
        {...listeners}
        node={setActivatorNodeRef}
      >
        <Card variant="outlined" sx={{ padding: 0, backgroundColor: amber[50], borderColor: amber[800], border: 1, borderRadius: '10px', }}
          onClick={() => { }}
        >
          <CardActionArea >
            <CardContent sx={{ padding: 0, m: 1 }} >
              <p>{item.nom + ' - situé à - ' + item.emplacement}</p>

            </CardContent>
          </CardActionArea>
        </Card>
      </Box>
    );
  }

  const miseAJourOrdre = () => {
    console.log(lesRuchers);
    //let lesRuchersCopy = lesRuchers.map((x) => x);
    var lesRuchersCopy = [];
    var lOrdre = 0;
    for (let rucher of lesRuchers) {
      let rucherCopy = { ...rucher };
      lesRuchersCopy[lOrdre] = rucherCopy;
      rucherCopy.ordre = lOrdre;
      lOrdre++;

    };
    console.log(lesRuchersCopy);

    modMultiRuchers(lesRuchersCopy)
      .then(() => {
        dispatch(lesRuchersDeApiStore(lesRuchersCopy));
        dispatch(setAffTrieRucher(false));

      })
  }


  return (

    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Container>

          <Box sx={style}>
            <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
              <Box sx={{ flex: 9 }}>
                <CustomTitre titre='Trie des ruchers' />
              </Box>
              <Box sx={{ flex: 1 }}>
                <IconButton onClick={() => { dispatch(setAffTrieRucher(false)) }} >
                  <CancelPresentationIcon sx={{ fontSize: 30, color: grey[900] }} />
                </IconButton>
              </Box>
            </Box>
            <Divider />


            <Box sx={{
              display: "flex",
              flexDirection: "column",
              height: 400, overflow: "hidden", overflowY: "scroll",
            }}>


              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
              >
                <SortableContext items={lesRuchers} strategy={verticalListSortingStrategy}>
                  <ul>
                    {lesRuchers.map((rucher) => (
                      <Box sx={{ m: 1 }} key={rucher.id} >
                        <Box style={{ flexDirection: 'row', flex: 0, display: 'flex' }}>
                          <Box style={{ flex: 11, flexDirection: 'column' }}>

                            <SortableItem key={rucher.id} item={rucher} />
                          </Box>
                        </Box>
                      </Box>

                    ))}
                  </ul>
                </SortableContext>
              </DndContext>


            </Box>
            <Grid container display="flex" justifyContent="flex-end">
              <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                <IconButton onClick={() => { dispatch(setAffTrieRucher(false)); }} >
                  <RestoreIcon sx={{ fontSize: 40, }} />
                </IconButton>
              </Tooltip>
              <IconButton onClick={() => { miseAJourOrdre(); }} sx={{ color: green[700] }} >
                <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
              </IconButton>
            </Grid>
          </Box>
        </Container>
      </Modal>





    </>
  )
};
// <Button type="submit" onClick={() => { dispatch(toggleAffReine()); dispatch(setAffReine(true)) }} >Modifier</Button>

export default TrieLesRuchersdeApi;