import React, {useState, useEffect,} from 'react';


import { useParams } from 'react-router-dom';

import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';

import ChercheReine from '../components/chercheReine'
import AfficheReine from '../components/AfficheReine'
import ModifieReine from '../components/ModifieReine'
import DelReine from '../components/DelReine'
import AfficheGenealogie from '../components/AfficheGenealogie'
import AfficheFilles from '../components/AfficheFilles'
import {useDispatch, useSelector} from 'react-redux'
import { getListTypFecondation } from '../../genealogieModule/services/accesApi';
import {typfecondationStore} from '../../store/typfecondationslice'
import MenuBar from '../../components/MenuBar'
import { grey, amber, green } from '@mui/material/colors';
import { DetReineParNom, listAncetresReine, listFillesdeReine, getListReines } from '../services/accesApi';
import {reineStore, reineRevoke, lesFillesMStore, lesFillesPStore, lesReinesStore} from '../../store/reineslice';
import { login, retrieveApiculteurTotal, postPasswordReset } from '../../userModule/services/authentification';

import {treeStore, treeRevoke} from '../../store/treeslice'
import {setTitreBar, setAffDisplayReine, setAffGenealogie, setAffFilles} from '../../store/displayslice'
import { userStore } from '../../store/userslice';


function ReineScreen() {
  const { affCreeReine, affModReine, affDelReine, affDisplayReine, affGenealogie, affFilles } = useSelector(state => state.display)  
  const [ allFetch, setAllFetch] = useState (false);
 const [ ErrorMessage, setErrorMessage ] = useState('');
 const { user } = useSelector(state => state.user);

 const dispatch = useDispatch()   

 let { nomReine } = useParams();
 dispatch(setTitreBar('REINES'));


 const handleLoadingError = (res) => {
  if (res.error === 404) {
    setErrorMessage('Reine non trouvée');


  } else {
      console.log("else");
      console.log(res.message);
      console.log(typeof(res.message));
      console.log(res.message);
      setErrorMessage(JSON.parse(res.message).message);
    };
  };

 useEffect(() => {
  if (!user)  {
    var token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;

    if (token) {
      Promise.all([retrieveApiculteurTotal(),])
        .then(async ([userValues, ]) => {
          console.log(userValues);
          dispatch(userStore(userValues));
          //dispatch(apiProprietaireStore (userValues.id));
        })


    }
    
  };

   
  let mounted = true;
 

  Promise.all([getListTypFecondation(),getListReines()])
               .then(([typFecValues, reines]) => {if(mounted) {
                        dispatch(typfecondationStore(typFecValues));
                        dispatch(lesReinesStore(reines));


                         if(mounted) {setAllFetch(true);}}
        
        });
        if (nomReine) {
        Promise.all([DetReineParNom(nomReine),listAncetresReine(nomReine), listFillesdeReine(nomReine,true), listFillesdeReine(nomReine,false)]) 
        .then(([laReine, lesAncetres, lesFillesM, lesFillesP]) => {
          dispatch(reineStore(laReine));
          dispatch(treeStore(lesAncetres));
          dispatch(lesFillesMStore(lesFillesM));
          dispatch(lesFillesPStore(lesFillesP));

          dispatch(setAffDisplayReine(true));
          dispatch(setAffGenealogie('OUI')) ;
          dispatch(setAffFilles('OUI')) ;

          setErrorMessage('');
        })
        .catch((res) => handleLoadingError(res));};
//         .catch(handleLoadingError);  
          
      return () => {mounted = false;setAllFetch(true);};
    
  }, [user]);


return (
  <>

  { allFetch ? (
    <>
  <MenuBar />
<Grid2 container>  

  <Grid2 xs={8} md={8} lg={6}>
    <Grid2  >
        <ChercheReine></ChercheReine>
    </Grid2>
    <Grid2   >
      <Paper elevation={10} sx={{m:1, bgcolor:amber[100]}}>

        { (affDisplayReine ) && <AfficheReine></AfficheReine>}
        { (affModReine ) && <ModifieReine></ModifieReine>}
        { (affCreeReine ) && <ModifieReine></ModifieReine>}
        { (affDelReine ) && <DelReine></DelReine>}
      </Paper>
    </Grid2>
  </Grid2>
  <Grid2 xs={4} md={4} lg={6}  >
    <Paper elevation={10} sx={{m:1}}>
      { (affGenealogie == 'OUI') && <AfficheGenealogie></AfficheGenealogie>}
    </Paper>
    <Paper elevation={10} sx={{m:1}}>
      { (affFilles == 'OUI') && <AfficheFilles></AfficheFilles>}
    </Paper>
  </Grid2> 
</Grid2> 
</>)
: <></> }
</>
);
}


export default ReineScreen;


