import { get, put, post, del} from '../../services/fetch';

import {useSelector, useDispatch} from 'react-redux'


export const DetReineParNom = (nom) => {
    return get('/DetReineParNom?nom=' + nom);
  };

  export const getListTypFecondation = () => {
    return get('/ListTypFecondation');
  };

  export const modifyReine = async (data) => {
    console.log ("entrée mod Reine");
    let nom = data.nom;
    let anneeNaissance = data.anneeNaissance;
    let maman_nom ;
    let papa_nom ;
    let Groupement ;
    let destination ;
    let population ;
    let commentaire = data.commentaire;
    let typeFecondation = data.typeFecondation;
    let race = data.race;

    let eleveur = data.eleveur;
    let ABiometrie = data.ABiometrie;
    let IndiceCubital;
    let IndiceCubitalEc;
    let TransgressionDiscoidale;
    let TransgressionDiscoidaleEc;
    let TauxConforme;
    let lieuFecondation;
    let AAnaMithocondriale = data.AAnaMithocondriale;
    let haplotype;
    let Region;
    let TauxHybridation;
    let ATesteVsh = data.ATesteVsh;
    let TauxVsh;
  
   
    data.maman_nom === "" ? maman_nom = null :  maman_nom = data.maman_nom ;
    data.papa_nom === "" ? papa_nom = null : papa_nom = data.papa_nom ;
    data.Groupement === "" ? Groupement = null : Groupement = data.Groupement ;
    data.destination === "" ? destination = null : destination = data.destination ;
    data.population === "" ? population = null : population = data.population ;
    data.eleveur === 0 ? eleveur = null : eleveur = data.eleveur ;
    data.IndiceCubital === "" ? IndiceCubital = null : IndiceCubital = data.IndiceCubital ;
    data.IndiceCubitalEc === "" ? IndiceCubitalEc = null : IndiceCubitalEc = data.IndiceCubitalEc ;
    data.TransgressionDiscoidale === "" ? TransgressionDiscoidale = null : TransgressionDiscoidale = data.TransgressionDiscoidale ;
    data.TransgressionDiscoidaleEc === "" ? TransgressionDiscoidaleEc = null : TransgressionDiscoidaleEc = data.TransgressionDiscoidaleEc ;
    data.TauxConforme === "" ? TauxConforme = null : TauxConforme = data.TauxConforme ;
    data.lieuFecondation === "" ? lieuFecondation = null : lieuFecondation = data.lieuFecondation ;
    data.haplotype === "" ? haplotype = null : haplotype = data.haplotype ;
    data.Region === "" ? Region = null : Region = data.Region ;
    data.TauxHybridation === "" ? TauxHybridation = null : TauxHybridation = data.TauxHybridation ;
    data.TauxVsh === "" ? TauxVsh = null : TauxVsh = data.TauxVsh ;
        

   return put('/DetReine/' + data.id , {nom, anneeNaissance, maman_nom, papa_nom, Groupement, destination,
    population, commentaire, typeFecondation, race, lieuFecondation, eleveur, ABiometrie, IndiceCubital, IndiceCubitalEc,
    TransgressionDiscoidale, TransgressionDiscoidaleEc, TauxConforme, AAnaMithocondriale, haplotype, Region,
    TauxHybridation, ATesteVsh, TauxVsh });
 };
 
 export const creeReine = async (data) => {
  console.log ("entrée cree Reine");
  let nom = data.nom;
  let anneeNaissance = data.anneeNaissance;
  let maman_nom ;
  let papa_nom ;
  let Groupement ;
  let destination ;
  let population ;
  let eleveur;
  let commentaire = data.commentaire;
  let typeFecondation = data.typeFecondation;
  let race = data.race;
  let ABiometrie = data.ABiometrie;
  let IndiceCubital;
  let IndiceCubitalEc;
  let TransgressionDiscoidale;
  let TransgressionDiscoidaleEc;
  let TauxConforme;
  let lieuFecondation;
  let AAnaMithocondriale;
  let haplotype;
  let Region;
  let TauxHybridation;
  let ATesteVsh = data.AtesteVSH;
  let TauxVsh;

  data.maman_nom === "" ? maman_nom = null :  maman_nom = data.maman_nom ;
  data.papa_nom === "" ? papa_nom = null : papa_nom = data.papa_nom ;
  data.Groupement === "" ? Groupement = null : Groupement = data.Groupement ;
  data.destination === "" ? destination = null : destination = data.destination ;
  data.population === "" ? population = null : population = data.population ;
  data.eleveur === 0 ? eleveur = null : eleveur = data.eleveur ;
  data.IndiceCubital === "" ? IndiceCubital = null : IndiceCubital = data.IndiceCubital ;
  data.IndiceCubitalEc === "" ? IndiceCubitalEc = null : IndiceCubitalEc = data.IndiceCubitalEc ;
  data.TransgressionDiscoidale === "" ? TransgressionDiscoidale = null : TransgressionDiscoidale = data.TransgressionDiscoidale ;
  data.TransgressionDiscoidaleEc === "" ? TransgressionDiscoidaleEc = null : TransgressionDiscoidaleEc = data.TransgressionDiscoidaleEc ;
  data.TauxConforme === "" ? TauxConforme = null : TauxConforme = data.TauxConforme ;
  data.lieuFecondation === "" ? lieuFecondation = null : lieuFecondation = data.lieuFecondation ;
  data.haplotype === "" ? haplotype = null : haplotype = data.haplotype ;
  data.Region === "" ? Region = null : Region = data.Region ;
  data.TauxHybridation === "" ? TauxHybridation = null : TauxHybridation = data.TauxHybridation ;
  data.TauxVsh === "" ? TauxVsh = null : TauxVsh = data.TauxVsh ;
  



  

 return post('/NewReine', {nom, anneeNaissance, maman_nom, papa_nom, Groupement, destination,
  population, commentaire, typeFecondation,race , lieuFecondation, eleveur, ABiometrie, IndiceCubital, IndiceCubitalEc,
  TransgressionDiscoidale, TransgressionDiscoidaleEc, TauxConforme, AAnaMithocondriale, haplotype, Region,
  TauxHybridation, ATesteVsh, TauxVsh});
};

export const listAncetresReine = (nom) => {
  return get('/listAncetresReine?nom=' + nom);
};

export const listFillesdeReine = (nom, type) => {
  console.log('Api filles');
  console.log(type);
  return get('/ListFillesdeReine?nom=' + nom + '&type=' + type);
};

export const delReine =  (reine) => {
  //   const Moment = require('moment');

  //   apiculteur = await getIdApiculteur();

  return del('/DetReine/' + reine.id);
};

export const getListEleveur = () => {
  return get('/listEleveur');
};

export const getListReines = () => {
  return get('/listReines');
};

export const getListEleveurCommence = (debut) => {
  return get('/listEleveur?com_nom_prenom=' + debut );
};

export const createEleveur = async (nom, prenom, departement) => {
  return post('/NewEleveur', {
    nom, prenom, departement }
   )
};

export const modifyEleveur = async (id, nom, prenom, departement) => {
  return put('/DetEleveur/' + id , {
    nom, prenom, departement }
   )
};

export const delEleveur = async (idEleveur) => {
  return del ('/DetEleveur/' + idEleveur );
};

export const reclameReine = async (reine,  apiReine, apiDemandeur) => {
  return put ('/ReclameReine', {reine, apiDemandeur, apiReine});
};