import React, { useState, useEffect, } from 'react';
import { useNavigate } from "react-router-dom";

import Link from '@mui/material/Link';
import Paper from '@mui/material/Paper';
import Divider from '@mui/material/Divider';

import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import ModeEmploiGenealogie from './Docs/sfanm site généalogique.docx';
import EncodageReines from './Docs/sfanm codage des pedigrees.docx';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { styled } from "@mui/material/styles";


import { useSelector, useDispatch } from 'react-redux';
import MenuBar from './components/MenuBar';
import CustomCard from './components/CustomCard';

import { setTitreBar } from './store/displayslice';

import { login, retrieveApiculteurTotal, postPasswordReset } from './userModule/services/authentification';
import { userStore } from './store/userslice';

const CardContentNoPadding = styled(CardContent)(`
  padding-left: 0;   padding-right: 0;
  &:last-child {
    padding-bottom: 0;
  }
`);


function HomeScreen() {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  /* const {user} = useSelector(state => state.user);
var langue = '';
if (user) {
  langue = user.langage ;
} else
{
  console.log('appel navigate');
  navigate("/reine");
} */
  const { user } = useSelector(state => state.user);
  const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;

  useEffect(() => {
    console.log('homme sreeen - useeffect');
    if (!user) {
      if (token) {
        Promise.all([retrieveApiculteurTotal(),])
          .then(async ([userValues,]) => {
            console.log(userValues);
            dispatch(userStore(userValues));
          })
      }


    }

  }, []);


  dispatch(setTitreBar('RUCHERS'));
  const [color, changeColor] = useState("#fff8e1");

  document.body.style.backgroundColor = color;
  document.body.style.margin = 0;


  return (
    <>
      <MenuBar />
      <Grid2 container>

        <Grid2 xs={4} >
          <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >
            <div style={{ height: 400, width: '100%' }}>
              <Typography variant="h4" align="center">
                Gestion des ruchers
              </Typography>

              <Grid2 container alignItems="stretch" sx={{ m: 1 }}>
                <CustomCard label='Colonies' destination='/colonies' largeur={4} variant='h5' />
                <CustomCard label='Ruchers' destination='/ruchers' largeur={4} variant='h5' />
                <CustomCard label='Délégués' destination='/delegues' largeur={4} variant='h5' />
              </Grid2>
              <Grid2 container alignItems="stretch" sx={{ m: 1 }}>
                <CustomCard label='statistiques' destination="/edition" param='stats' largeur={4} variant='h6' />
                <CustomCard label='registre élevage' destination='/edition' param='registre' largeur={4} variant='h6' />
              </Grid2>
              <Grid2 container alignItems="stretch" sx={{ m: 1 }}>
                <CustomCard label='Journal' destination='/journal' largeur={4} variant='h6' />
              </Grid2>
              <p>Nécessite de créer un compte pour pouvoir enregistrer toute l'activité</p>
            </div>
          </Paper>
        </Grid2>
        <Grid2 xs={4} >
          <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >
            <div style={{ height: 400, width: '100%' }}>
              <Typography variant="h4" align="center">
                Généalogie
              </Typography>

              <Stack direction="row" spacing={2} alignItems="center">
                <CustomCard label='Généalogie' destination='/reine' largeur={4} variant='h6' />

              </Stack>
              <a href={ModeEmploiGenealogie} download="Genealogie.docx">Télécharger le mode de'mploi</a>
              <br></br>
              <a href={EncodageReines} download="Encodage.docx">Télécharger les règles d'encodage</a>

              <p>Il est nécessaire de créer un compte pour pouvoir créer et modifier les caractéristiques des reines</p>
              <p>l'accès en consultation est libre</p>
              <br />

            </div>
          </Paper>
        </Grid2>

        <Grid2 xs={4} >
          <Paper elevation={10} sx={{ m: 1, bgcolor: amber[100] }} >
            <div style={{ height: 400, width: '100%' }}>
              <Typography variant="h4" align="center">
                Biométrie
              </Typography>

              <Stack direction="row" spacing={2} alignItems="center">
                <CustomCard label='Biométrie' destination='/biometrie' largeur={4} variant='h5' />


              </Stack>
              <p>Il est nécessaire de créer un compte </p>
              <br />

            </div>
          </Paper>
        </Grid2>

      </Grid2>

    </>
  );
}


export default HomeScreen;


