import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Stack from '@mui/material/Stack';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green, red } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


import { modifyReine, creeReine, DetReineParNom, listAncetresReine, getListEleveurCommence , getListReines} from '../services/accesApi';
import { getListRace } from '../../rucherModule/services/accessApiRuchers';

import { useDispatch, useSelector } from 'react-redux'
import { reineStore, lesReinesStore } from '../../store/reineslice'
import { treeStore } from '../../store/treeslice'

//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';

import { setAffDisplayReine , setAffModReine, setAffCreeReine} from '../../store/displayslice';
import { geoConicConformalRaw } from 'd3';
import { Construction } from '@mui/icons-material';


//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';

//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function ModifieReine(props) {
  const {
    ...inputProps
  } = props
  const dispatch = useDispatch()

  // pr autocomplte
  const previousController = useRef();
  const [open, setOpen] = useState(false);
  const [races, setRaces] = useState(false);
  const [allFetch, setAllFetch] = useState(false);

  //const [inputValue, setInputValue] = useState('0');


  let reine = new Object();
  reine = useSelector(state => state.reine).reine;
  const { affDisplayReine, affCreeReine, affModReine } = useSelector(state => state.display);

  console.log(reine);
  console.log(affCreeReine);
  console.log(affModReine);

  if (reine === null || affCreeReine) {
    console.log("y pass");
    reine = new Object();
    reine.eleveur = 0;
    reine.eleveur_nom = '';
    console.log(reine);
  }
  const { typFecondation } = useSelector(state => state.typFecondation);
  const [ErrorMessage, setErrorMessage] = useState('');

  const [visible, setVisible] = useState(true)
  const navigate = useNavigate();
  var modReine = false;
  //const reineInit = useRef(reine);
  const [value, setValue] = useState({ elev: reine.eleveur_nom, ident: reine.eleveur });
  //const [value, setValue] = useState('');
  const [options, setOptions] = useState([{ elev: reine.eleveur_nom, ident: reine.eleveur }]);
  const [inputValue, setInputValue] = useState(reine.eleveur_nom);
  var elev_nom = "Saisir le nom";
  var elev_id = 1;
  console.log(allFetch);

  useEffect(() => {

    let mounted = true;
    setAllFetch(false);
    getListRace()
      .then((racesLues) => { if (mounted) { setRaces(racesLues); setAllFetch(true); } })
      .catch(handleLoadingError);

    return () => { mounted = false; };

  }, [])

  const handleLoadingError = (res) => {
    //console.log(res);
    if (res.error === 401) {



      navigate('Login');
    } else {
      console.log("else");
      //console.log(res);
      console.log(res.message);
      //console.log(typeof(res.message));
      //console.log(res.message);
      setErrorMessage(JSON.parse(res.message).message);
    };
  };

  const FormonSubmit = (data) => {
    console.log('formsonsubmit');
    console.log(data);
    let mReine = {};
    mReine.id = reine.id;
    mReine.nom = data.nom;
    mReine.anneeNaissance = data.anneeNaissance;
    mReine.maman_nom = data.maman_nom;
    mReine.papa_nom = data.papa_nom;
    mReine.Groupement = data.Groupement;
    mReine.destination = data.destination;
    mReine.population = data.population;
    mReine.commentaire = data.commentaire;
    mReine.typeFecondation = data.typeFecondation;
    mReine.race = data.race;
    mReine.eleveur = data.eleveur;
    mReine.ABiometrie = data.ABiometrie;
    mReine.IndiceCubital = data.IndiceCubital.replace(',', '.');
    mReine.IndiceCubitalEc = data.IndiceCubitalEc.replace(',', '.');
    mReine.TransgressionDiscoidale = data.TransgressionDiscoidale.replace(',', '.');
    mReine.TransgressionDiscoidaleEc = data.TransgressionDiscoidaleEc.replace(',', '.');
    mReine.TauxConforme = data.TauxConforme;
    mReine.lieuFecondation = data.lieuFecondation;
    mReine.AAnaMithocondriale = data.AAnaMithocondriale;
    mReine.haplotype = data.haplotype;
    mReine.Region = data.Region;
    mReine.TauxHybridation = data.TauxHybridation.replace(',', '.');;
    mReine.ATesteVsh = data.ATesteVsh;
    mReine.TauxVsh = data.TauxVsh;


    if (modReine) {
      modifyReine(mReine)
        .then(() => Promise.all([DetReineParNom(mReine.nom), listAncetresReine(mReine.nom),getListReines()]))
        .then(([laReine, lesAncetres, reines]) => { dispatch(reineStore(laReine)); dispatch(treeStore(lesAncetres)); dispatch(lesReinesStore(reines));})
        .then(() => { dispatch(setAffModReine(false)); dispatch(setAffDisplayReine(true));setAffModReine(false);setAffCreeReine(false) })
        .catch((res) => handleLoadingError(res))
      // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher })});
    }
    else {
      creeReine(mReine)
        .then(() => Promise.all([DetReineParNom(mReine.nom), listAncetresReine(mReine.nom), getListReines()]))
        .then(([laReine, lesAncetres, reines]) => { dispatch(reineStore(laReine)); dispatch(treeStore(lesAncetres)); dispatch(lesReinesStore(reines) );})
        .then(() => { dispatch(setAffCreeReine(false)); dispatch(setAffDisplayReine(true));setAffModReine(false);setAffCreeReine(false) })
        .catch((res) => handleLoadingError(res))
      // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher })});
    }
  };

  const Initialisation = () => {
    console.log('ini');
    console.log(affModReine);
    const init = new Object();
    if (affModReine) {
      console.log('initialisation');
      modReine = true;
      init.nom = reine.nom;
      //setAcValue({elev: reine.eleveur_nom, ident: reine.eleveur});
      //setAcValue("aaaa");
      //init.eleveur={elev: reine.eleveur_nom, ident: reine.eleveur};
      init.eleveur = reine.eleveur;
      init.ident = reine.eleveur;
      init.elev = reine.eleveur_nom;
      //setOptions([{elev: reine.eleveur_nom, ident : reine.eleveur}]);


      init.anneeNaissance = reine.anneeNaissance;
      init.maman_nom = reine.maman_nom;
      init.papa_nom = reine.papa_nom;
      init.race = reine.race;
      init.Groupement = reine.Groupement;
      init.destination = reine.destination;
      init.population = reine.population;
      init.commentaire = reine.commentaire;
      init.typeFecondation = reine.typeFecondation;
      //init.ABiometrie = reine.ABiometrie;     
      init.IndiceCubital = reine.IndiceCubital;
      init.IndiceCubitalEc = reine.IndiceCubitalEc;
      init.TransgressionDiscoidale = reine.TransgressionDiscoidale;
      init.TransgressionDiscoidaleEc = reine.TransgressionDiscoidaleEc;
      init.TauxConforme = reine.TauxConforme;
      init.lieuFecondation = reine.lieuFecondation;
      //init.AAnaMithocondriale = reine.AAnaMithocondriale;  
      init.haplotype = reine.haplotype;
      init.Region = reine.Region;
      init.TauxHybridation = reine.TauxHybridation;
      //init.ATesteVsh = reine.ATesteVsh;  
      init.TauxVsh = reine.TauxVsh;

      reine.ABiometrie === null ? init.ABiometrie = false : init.ABiometrie = reine.ABiometrie;
      reine.AAnaMithocondriale === null ? init.AAnaMithocondriale = false : init.AAnaMithocondriale = reine.AAnaMithocondriale;
      reine.ATesteVsh === null ? init.ATesteVsh = false : init.ATesteVsh = reine.ATesteVsh;
      reine.IndiceCubital === null ? init.IndiceCubital = "" : init.IndiceCubital = reine.IndiceCubital;
      reine.IndiceCubitalEc === null ? init.IndiceCubitalEc = "" : init.IndiceCubitalEc = reine.IndiceCubitalEc;
      reine.TransgressionDiscoidale === null ? init.TransgressionDiscoidale = "" : init.TransgressionDiscoidale = reine.TransgressionDiscoidale;
      reine.TransgressionDiscoidaleEc === null ? init.TransgressionDiscoidaleEc = "" : init.TransgressionDiscoidaleEc = reine.TransgressionDiscoidaleEc;
      reine.TauxConforme === null ? init.TauxConforme = "" : init.TauxConforme = reine.TauxConforme;
      reine.TauxHybridation === null ? init.TauxHybridation = "" : init.TauxHybridation = reine.TauxHybridation;
      reine.TauxVsh === null ? init.TauxVsh = "" : init.TauxVsh = reine.TauxVsh;

    }
    else {
      modReine = false;
      init.eleveur = 0;
      init.ident = 0;
      init.elev = "";

      init.nom = "";
      init.anneeNaissance = "";
      init.maman_nom = "";
      init.papa_nom = "";
      init.race = "";
      init.Groupement = "";
      init.destination = "";
      init.population = "";
      init.commentaire = "";
      init.typeFecondation = "";
      init.ABiometrie = false;
      init.IndiceCubital = "";
      init.IndiceCubitalEc = "";
      init.TransgressionDiscoidale = "";
      init.TransgressionDiscoidaleEc = "";
      init.TauxConforme = "";
      init.lieuFecondation = "";
      init.AAnaMithocondriale = false;
      init.haplotype = "";
      init.Region = "";
      init.TauxHybridation = "";
      init.ATesteVsh = false;
      init.TauxVsh = "";



    };
    console.log(init);
    return init
  };
  //fields= ('id', 'nom', 'anneeNaissance', 'Groupement', 'destination', 'maman', 'maman_nom', 'papa', \
  //'papa_nom', 'typeFecondation', 'typeFecondation_nom', 'population', 'commentaire')


  const ValidationSchema = yup.object().shape({
    nom: yup
      .string()
      .min(1, 'tropCourt')
      .max(20, 'tropLong')
      .required('nomObligatoire'),
    anneeNaissance: yup
      .number()
      .required('Année obligatoire')
      .min(1900)
      .max(2100),
    typeFecondation: yup
      .string()
      .required('Type fecondation obligatoire'),
    IndiceCubital: yup
      .string()
      .matches(/^\s*$|^\d{1,2}((\.|\,)\d{0,3})?$/, 'Nombre décimal'),
    IndiceCubitalEc: yup
      .string()
      .matches(/^\s*$|^\d{1,2}((\.|\,)\d{0,3})?$/, 'Nombre décimal'),
    TransgressionDiscoidale: yup
      .string()
      .matches(/^\s*$|^-?\d{1,2}((\.|\,)\d{0,3})?$/, 'Nombre décimal'),
    TransgressionDiscoidaleEc: yup
      .string()
      .matches(/^\s*$|^\d{1,2}((\.|\,)\d{0,3})?$/, 'Nombre décimal'),
    TauxConforme: yup
      .number()
      .integer('entier')
      .min(0, "doit être posiitf")
      .max(100, " <= 100)")
      .typeError('Nombre'),
    TauxHybridation: yup
      .string()
      .matches(/^\s*$|^\d{1,2}((\.|\,)\d{0,3})?$/, 'Nombre décimal'),


  });

  const getData = (searchTerm) => {
    getListEleveurCommence(searchTerm)
      .then((lesEleveurs) => {
        const updatedOptions = lesEleveurs.map((p) => {
          return { elev: p.nom + '.' + p.prenom, ident: p.id }
        });
        setOptions(updatedOptions);

      });
  }

  const onInputChange = (event, newInValue, reason) => {

    if (newInValue !== null && newInValue !== "") {
      getData(newInValue);
      setInputValue(newInValue);
    } else {
      setOptions([]);
      setInputValue(newInValue);
      setValue("");

    }
  };



  //     {/* value={{elev : values.elev, ident : values.ident}} */}
  //         inputValue={inputValue}

  return (
    <>
      {allFetch ? (
        <Modal
          open={visible}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
          <Box sx={{ width: 500 }} >
            <Formik
              initialValues={Initialisation()}
              validationSchema={ValidationSchema}
              onSubmit={values => FormonSubmit(values)}
            >
              {({
                handleSubmit, isValid, values, setFieldValue, setFieldTouched,
                errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
              }) => (
                <>
                  <Container>
                    <Box sx={style}>
                      <Grid container display="flex" alignItems="center">
                        <Grid xs={6}>
                          <Field component={CustomInput} name="nom" label={'Nom'} placeholder={'Nom'} labD={6} fieldD={6} required />
                        </Grid>
                        <Grid xs={6}>
                          <Field component={CustomInput} name="anneeNaissance" label={'née en'} placeholder={'Année nais'} labD={6} fieldD={6} required />
                        </Grid>
                      </Grid>

                      <Grid container display="flex" alignItems="flex-end">

                        <Grid xs={3} sx={{ pt: 1, pl: 1, color: grey[600] }}>Eleveur</Grid>
                        <Grid xs={7}>

                          <FormControl variant="filled" fullWidth sx={{ bgcolor: "white" }}>
                            <Tooltip title={<Typography fontSize={20}>Saisir le nom</Typography>} placement="top-start" >
                              <Autocomplete
                                noOptionsText={'Pas d"éleveur correspondant'}
                                id="eleveur"
                                name="eleveur"
                                options={options}
                                open={open}
                                onOpen={() => { setOpen(true); }}
                                onClose={() => { setOpen(false); }}
                                isOptionEqualToValue={(option, value) => { return (option.elev === value.elev || value.elev == "") }}
                                onChange={(event, text, reason) => {
                                  setValue({ elev: text !== null ? text.elev : "", ident: text !== null ? text.ident : 0 });
                                  setInputValue(text !== null ? text.elev : "");
                                  setFieldValue("eleveur", text !== null ? text.ident : "")
                                }}
                                onInputChange={onInputChange}
                                getOptionLabel={(option) => { return (option.elev ? option.elev : "") }}
                                value={value}
                                inputValue={inputValue}

                                renderInput={(params) => (<TextField {...params} variant="standard" />)}
                              />
                            </Tooltip>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid container display="flex" alignItems="flex-end">
                        <Grid xs={6}>
                          <Field component={CustomInput} name="maman_nom" label={'Mère'} placeholder={''} labD={6} fieldD={6} />
                        </Grid>
                        <Grid xs={6}>
                          <Field component={CustomInput} name="papa_nom" label={'Père'} placeholder={''} labD={6} fieldD={6} />
                        </Grid>
                      </Grid>

                      <Grid container display="flex" alignItems="flex-end">
                        <Grid xs={6}>
                          <Field component={CustomPicker}
                            label="Fécondation"
                            liste={typFecondation} itemLabel='nom' itemKey='id'
                            ModeModif={modReine}
                            selectedValue={values.typeFecondation}
                            name="typeFecondation"
                            handleChange={(itemIndex) => handleChange('typeFecondation')(itemIndex)}
                            labD={6} fieldD={6}
                            required
                          />

                        </Grid>
                        <Grid xs={6}>
                          <Field component={CustomInput} name="lieuFecondation" label={'à'} placeholder={''} labD={4} fieldD={8} />

                        </Grid>
                      </Grid>

                      <Grid xs={6}>
                        <Field component={CustomPicker}
                          label="Race"
                          liste={races} itemLabel='nom' itemKey='id' NomListObjet="race"
                          ModeModif={modReine}
                          selectedValue={values.race}
                          name="typeFecondation"
                          handleChange={(itemIndex) => handleChange('race')(itemIndex)}
                          labD={4} fieldD={8}
                          required

                        />

                      </Grid>


                      <Field component={CustomInput} name="Groupement" label={'Groupement'} placeholder={'Groupement'} />

                      <Field component={CustomInput} name="destination" label={'Destination'} placeholder={'destination'} />

                      <Field component={CustomInput} name="population" label={'Population'} placeholder={'Population'} />
                      <Field component={CustomInput} name="commentaire" label={'Commentaire'} placeholder={'Commentaire'} />
                      <Grid container display="flex" alignItems="flex-end">
                        <Grid xs={6}>

                          <CustomSwitch label={'Biométrie'} vSwitch={values.ABiometrie} onChange={event => {
                            setFieldValue('ABiometrie', event.target.checked)
                          }} />
                        </Grid>
                      </Grid>
                      {values.ABiometrie &&
                        <>
                          <Box >
                            <Grid container display="flex" alignItems="flex-end">
                              <Grid xs={6}>
                                <Field component={CustomInput} name="IndiceCubital" label={'Ind.Cubital'} placeholder={'Cubital'} labD={8} fieldD={4} />
                              </Grid>
                              <Grid xs={6}>
                                <Field component={CustomInput} name="IndiceCubitalEc" label={'Ecart type'} placeholder={'Ecart type'} labD={8} fieldD={4} />
                              </Grid>
                            </Grid>
                          </Box>
                          <Grid container display="flex" alignItems="flex-end">
                            <Grid xs={6}>

                              <Field component={CustomInput} name="TransgressionDiscoidale" label={'Trans Disco'} placeholder={'discoidal'} labD={8} fieldD={4} />
                            </Grid>
                            <Grid xs={6}>
                              <Field component={CustomInput} name="TransgressionDiscoidaleEc" label={'Ecart Type'} placeholder={'Ecart type'} labD={8} fieldD={4} />
                            </Grid>
                          </Grid>
                          <Field component={CustomInput} name="TauxConforme" label={'TauxConforme'} placeholder={'TauxConforme'} />
                        </>}
                      <Grid container display="flex" alignItems="flex-end">
                        <Grid xs={6}>
                          <CustomSwitch label={'Ana mitochondriale.'} vSwitch={values.AAnaMithocondriale}
                            onChange={event => { setFieldValue('AAnaMithocondriale', event.target.checked) }} />
                        </Grid>
                        <Grid xs={6}>
                          {values.AAnaMithocondriale &&
                            <Field component={CustomInput} name="TauxHybridation" label={'TauxHybridation'} placeholder={'TauxHybridation'} labD={8} fieldD={4} />
                          }

                        </Grid>
                      </Grid>
                      {values.AAnaMithocondriale &&
                        <Grid container display="flex" alignItems="flex-end">
                          <Grid xs={6}>

                            <Field component={CustomInput} name="haplotype" label={'Haplotype'} placeholder={'haplotype'} labD={8} fieldD={4} />
                          </Grid>
                          <Grid xs={6}>
                            <Field component={CustomInput} name="Region" label={'Région'} placeholder={'Région'} labD={8} fieldD={4} />
                          </Grid>
                        </Grid>

                      }
                      <Grid container display="flex" alignItems="flex-end">
                        <Grid xs={6}>
                          <CustomSwitch label={'Testé VSH'} vSwitch={values.ATesteVsh}
                            onChange={event => { setFieldValue('ATesteVsh', event.target.checked) }} />
                        </Grid>
                        <Grid xs={6}>
                          {values.ATesteVsh &&
                            <Field component={CustomInput} name="TauxVsh" label={'Taux Vsh'} placeholder={'Taux Vsh'} labD={8} fieldD={4} />
                          }

                        </Grid>
                      </Grid>



                      {ErrorMessage ? (
                        <>
                          <Divider variant="middle" />
                          <Stack
                            direction={{ xs: 'column', sm: 'row' }}
                            spacing={{ xs: 1, sm: 2, md: 4 }}
                          >
                            <ReportProblemIcon sx={{ fontsize: 20, color: red[700] }} />
                            <Typography fontSize={20} sx={{ color: red[700] }}>{ErrorMessage}</Typography>
                          </Stack>
                        </>
                      ) : null}
                      <Divider variant="middle" />

                      <Grid container display="flex" justifyContent="flex-end">
                        <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" >
                          <IconButton onClick={() => {dispatch(setAffModReine(false));
                          dispatch(setAffCreeReine(false))}} >
                            <RestoreIcon sx={{ fontSize: 40, }} />
                          </IconButton>
                        </Tooltip>
                        <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                          <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                        </IconButton>
                      </Grid>

                    </Box>
                  </Container>
                </>
              )}
            </Formik>
          </Box>
        </Modal>)
        : <></>}
    </>
  );
}

//<Button onClick={() => dispatch(toggleAffReine()) } >Annuler</Button>


export default ModifieReine;