import { get, options, put, post, del } from '../../services/fetch';


export const getListEntree = (idTheme) => { 
  return get('/ListEntree?idTheme=' + idTheme);
};

export const createEntree = async (Entree) => {
  let DateEntree = Entree.DateEntree;
  let ObjetEntree = Entree.ObjetEntree;
  let TexteEntree = null;
  Entree.TexteEntree == "" ? TexteEntree = null: TexteEntree = Entree.TexteEntree;
  let ThemeEntree = Entree.idTheme;
  return post ('/NewEntree' , {ThemeEntree, DateEntree, ObjetEntree, TexteEntree});
};

export const modifyEntree = async (Entree) => {
  console.log(Entree);
  let DateEntree = Entree.DateEntree;
  let ObjetEntree = Entree.ObjetEntree;
  let TexteEntree = null;

  Entree.TexteEntree == "" ? TexteEntree = null: TexteEntree = Entree.TexteEntree;
  let ThemeEntree = Entree.idTheme;
 return put('/DetEntree/' + Entree.id , {ThemeEntree, DateEntree, ObjetEntree, TexteEntree});
};

export const delEntree = async (entree) => {
  
  return del ('/DetEntree/' + entree.id );
};


export const getListTheme = () => {
  console.log('entree list theme');
  return get('/ListTheme');
};

export const createTheme = async (apiculteur, LibelleTheme) => {

  return post('/NewTheme', {
    apiculteur, LibelleTheme, }
   )
};


export const modifyTheme = async (apiculteur, idTheme, LibelleTheme) => {

  return put('/DetTheme/' + idTheme , {
    apiculteur, LibelleTheme, }
   )
};

export const delTheme = async (theme) => {
  
  return del ('/DetTheme/' + theme.id );
};
