import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';


import { modifyRucher, createRucher, getListRuchersDeApi, getListRuchersDelegue } from '../services/accessApiRuchers';

import { useDispatch, useSelector } from 'react-redux'
import { reineStore } from '../../store/reineslice'
import { treeStore } from '../../store/treeslice'

//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';

import { lesColoniesDeApiStore, lesRuchersTravailStore, lesRuchersDeApiStore, lesRuchersDelegationStore} from '../../store/ruchersslice'

import { setAffModRucher, setAffCreeRucher, listRuchersdeApi } from '../../store/displayRucherslice';
import { geoConicConformalRaw } from 'd3';
import { Construction } from '@mui/icons-material';


//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';
import i18n from '../services/i18n';
import dayjs from 'dayjs';


//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function ModRucher(props) {
  const {
    ...inputProps
  } = props

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(true);
  const rucher = useSelector(state => state.ruchers).leRucher;
  const apiProprio = useSelector(state => state.ruchers).apiProprietaire;

  var modeModif = false;
  var modeDuplic = false;
  modeModif = useSelector(state => state.displayRucher).affModRucher;
  const { user } = useSelector(state => state.user);
  let api = user.id;


  const Initialisation = () => {
    console.log('init');
    const init = new Object();
    if (modeModif) {
      init.nom = rucher.nom;
      init.emplacement = rucher.emplacement;
    }
    else {
      init.nom = "";
      init.emplacement = "";
    }
    return init;
  }

  const ValidationSchema = yup.object().shape({
    nom: yup
      .string()
      .min(1)
      .max(25, 'trop long')
      .required('nom obligatoire'),
    emplacement: yup
      .string()
      .min(1)
      .max(25, 'trop long')
      .required('emplacement obligatoire'),

  });


  const FormonSubmit = (data) => {
    const mRucher = new Object();
    mRucher.nom = data.nom;
    mRucher.emplacement = data.emplacement;
    if (modeModif) {
      mRucher.id = rucher.id;
      modifyRucher(apiProprio, mRucher)
        .then(() => {
          Promise.all([getListRuchersDeApi(api),getListRuchersDelegue(api)])
          .then(([ruchersDeApi,ruchersDelegation]) => {
            dispatch(lesRuchersTravailStore(ruchersDeApi));
            dispatch(lesRuchersDeApiStore(ruchersDeApi));
            dispatch(lesRuchersDelegationStore(ruchersDelegation));            
          dispatch(setAffCreeRucher(false))
          dispatch(setAffModRucher(false))
        } )  })
    } else {
      createRucher(apiProprio, mRucher)
        .then(() => {
          Promise.all([getListRuchersDeApi(apiProprio),getListRuchersDeApi(api),getListRuchersDelegue(api)])
          .then(([ruchersTrav, ruchersDeApi,ruchersDelegation]) => {
            dispatch(lesRuchersTravailStore(ruchersTrav));
            dispatch(lesRuchersDeApiStore(ruchersDeApi));
            dispatch(lesRuchersDelegationStore(ruchersDelegation));             
          dispatch(setAffCreeRucher(false))})
          dispatch(setAffModRucher(false))
        })
    }

    console.log('formon submit');
  };

  return (
    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Box sx={{
          width: 500, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2,
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>
                <Field component={CustomInput} name="nom" label={i18n.t('ruchers.nom')} placeholder={i18n.t('ruchers.nom')} 
                  required={true}      />

                <Field component={CustomInput} name="emplacement" label={i18n.t('ruchers.emplacement')} 
                placeholder={i18n.t('ruchers.emplacement')}  required={true}/>


                <Grid container display="flex" justifyContent="flex-end">
                  <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffModRucher(false)); dispatch(setAffCreeRucher(false)) }} >
                      <RestoreIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
              </>
            )}

          </Formik>

        </Box>
      </Modal>
    </>);
}

//<Button onClick={() => dispatch(toggleAffReine()) } >Annuler</Button>


export default ModRucher;
