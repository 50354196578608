import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';


import i18n from '../services/i18n';
import { dispDate } from '../services/prepDate';
import { bSante } from '../services/bSante';

import CustomTextStd2 from '../../components/CustomTextStd2';
import CustomTextStd from '../../components/CustomTextStd';
import { styles } from '../../components/Styles';



const ResumeSoin = (props) => {
   const {
      soin, langue,
      ...inputProps
   } = props
   console.log('soin xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx')
   console.log(soin);
   const { cPickers } = useSelector(state => state.cPicker);
   const contenuPickers = cPickers;
   //console.log(soin);

   const libUniNourri = (unite) => {
      const laUnite = contenuPickers.listUniNourri.find(item => item.id === unite);
      if (typeof (laUnite) != "undefined") { return laUnite.nom };
      return '';
   };

   const libMedicament = (medoc) => {
      //      console.log('lib medoc');
      //      console.log(contenuPickers.listMedoc);
      const leMedoc = contenuPickers.listMedoc.find(item => item.id === medoc);
      if (typeof (leMedoc) != "undefined") { return leMedoc.nom };
      return '';
   };



   const libNourrissement = (nourri) => {

      const leNourri = contenuPickers.listNourri.find(item => item.id === nourri);
      if (typeof (leNourri) != "undefined") { return leNourri.nom };
      return '';
   };

   return (
      <>
         {soin.date ? (
            <Box style={{ flexDirection: 'row', flex: 0, display: 'flex' }}>
               <Box style={{ flex: 3, flexDirection: 'column' }}>
                  <CustomTextStd2 label={i18n.t('colonie.date')} contenu={dispDate(soin.date, langue)} />
               </Box>
               <Box style={{ flex: 3, flexDirection: 'column' }}>
                  <Typography></Typography>
               </Box>
            </Box>
         )
            : (<Typography>{i18n.t('colonie.pasDeSoin')}</Typography>)}



         {soin.qtnourrissment ? (
            <Box style={{ flexDirection: 'row', display: 'flex' }} alignItems={'center'}>
               <Typography style={{ flex: 3 }}>{i18n.t('colonie.nourrissement')}</Typography>
               <Typography style={{ flex: 2 }}>{soin.qtnourrissment}</Typography>
               <Typography style={{ flex: 1 }}>{libUniNourri(soin.unitenourrissement)}</Typography>

               <Box style={{ flex: 3, flexDirection: 'column' }}>

                  {soin.nourrissement &&

                     <Typography style={{ flex: 1 }}>{libNourrissement(soin.nourrissement)}</Typography>}
                  {soin.typnourrissement &&
                     <Typography style={{ flex: 1 }}>{soin.typnourrissement}</Typography>}
               </Box>

            </Box >
         ) : null}
         <p></p>
         {
            soin.qtmedicament ? (
               <Box style={{ flexDirection: 'row', display: 'flex' }}>
                  <Typography style={{ flex: 3 }}>{i18n.t('colonie.traitement')}</Typography>
                  <Typography style={{ flex: 2 }}>{soin.qtmedicament}</Typography>
                  <Typography style={{ flex: 3 }}>{libMedicament(soin.medicament)}</Typography>
                  <Typography style={{ flex: 1 }}></Typography>

               </Box>
            ) : null
         }

      </>
   )

}

export default ResumeSoin;
