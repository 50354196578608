import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { getDetColonie } from '../services/accessApiRuchers';
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';

import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import EditIcon from '@mui/icons-material/Edit';
import RestoreIcon from '@mui/icons-material/Restore';
import CloseIcon from '@mui/icons-material/Close';

import { amber, green, red } from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';


import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';

import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';


import { randomId } from '@mui/x-data-grid-generator';

import i18n from '../services/i18n';

import CustomTextStd from '../../components/CustomTextStd';
import CustomTitre from '../../components/CustomTitre';
import { headerStyles, styles } from '../../components/Styles';
import { prepDate, dispDate } from '../../services/prepDate';
import AfficheTravail from './AfficheTravail';
import AfficheTravailAFaire from './AfficheTravailAFaire';

import DelVisite from './DelVisite';

import { bSante } from '../services/bSante';




// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import { setAffVisite, setAffModVisite, setAffDelVisite } from '../../store/displayRucherslice'
import { laColonieStore } from '../../store/ruchersslice'

// pas de edit toolbar
const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  height: '90%',
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};

function AffichelaVisite() {
  const dispatch = useDispatch()

  const [allFetch, setAllFetch] = useState(false);
  const [affDelete, setAffDelete] = useState(false);

  const { laVisite, laColonie, lesTravauxFaits, lesTravauxAFaire, lesHausseVisites } = useSelector(state => state.ruchers);
  console.log('afficher visite ----------------------------------------------');
  console.log(lesHausseVisites);

  const { affVisite, affDelVisite } = useSelector(state => state.displayRucher);
  const { cPickers } = useSelector(state => state.cPicker);
  let contenuPickers = Object.assign({}, cPickers);

  const listeTravauxPossibles = contenuPickers.listTravaux;

  // --- a partir des travaux faits, récupération des libellés
  let lesTravFaits = [];
  for (let travailFait of lesTravauxFaits) {
    const locTravail = new Object();
    locTravail.id = travailFait.id;
    locTravail.visite = travailFait.visite;
    locTravail.faire = travailFait.faire;
    locTravail.deFaire = travailFait.deFaire;
    locTravail.commentaire = travailFait.commentaire;
    let travPos = listeTravauxPossibles.find(elt => elt.id == travailFait.travail);
    if (travPos) {
      locTravail.nomFaire = travPos.nomFaire;
      locTravail.nomDefaire = travPos.nomDefaire;
      locTravail.nomCommentaire = travPos.nomCommentaire;
      locTravail.aCommentaire = travPos.aCommentaire;
    }
    lesTravFaits.push(locTravail);
  }

  // --- a partir des travaux à faire, récupération des libellés
  let lesTravAFaire = [];
  for (let travailAFaire of lesTravauxAFaire) {
    const locTravailAF = new Object();
    locTravailAF.id = travailAFaire.id;
    locTravailAF.colonie = travailAFaire.colonie;
    locTravailAF.faire = travailAFaire.faire;
    locTravailAF.deFaire = travailAFaire.deFaire;
    locTravailAF.commentaire = travailAFaire.commentaire;
    locTravailAF.dateT = travailAFaire.date;
    let travPos = listeTravauxPossibles.find(elt => elt.id == travailAFaire.travail);
    if (travPos) {
      locTravailAF.nomFaire = travPos.nomFaire;
      locTravailAF.nomDefaire = travPos.nomDefaire;
      locTravailAF.nomCommentaire = travPos.nomCommentaire;
      locTravailAF.aCommentaire = travPos.aCommentaire;
    }
    lesTravAFaire.push(locTravailAF);
  }




  const [messageErreur, setMessageErreur] = useState();
  ;

  useEffect(() => {
    let mounted = true;
    setAllFetch(false);
    if (mounted) {
      setAllFetch(true);
    }
    return () => { mounted = false; setAllFetch(false); };

  }, []);






  const handleLoadingError = (res) => {
    console.log('erreur');
    console.log(res);
  };



  const libCR = (pres_CR) => {
    const laCR = contenuPickers.TypCR.find(mar => mar.id === pres_CR);
    if (typeof (laCR) != "undefined") { return laCR.nom };
    return '';
  };

  const libPb = (Pb) => {
    const lePb = contenuPickers.TypPb.find(item => item.id === Pb);
    if (typeof (lePb) != "undefined") { return lePb.nomprobleme };
    return '';
  };

  const libComport = (comport) => {
    if (comport) {
      if (typeof contenuPickers.optVis.actions != "undefined") {
        const nom = contenuPickers.optVis.actions.PUT.comportement.choices.find(item => item.value === comport);
        if (typeof nom.display_name != "undefined") {
          var dispname = nom.display_name;
          return dispname
        };
      }
    } else {

      return '';
    }
  };

  const libReserves = (reserves) => {
    if (reserves) {
      if (typeof contenuPickers.optVis.actions != "undefined") {
        const nom = contenuPickers.optVis.actions.PUT.EtatReserves.choices.find(item => item.value === reserves);
        if (typeof nom.display_name != "undefined") {
          var dispname = nom.display_name;
          return dispname
        };
      }
    } else { return ''; }
  };

  const libTenueCadre = (tCadre) => {
    if (tCadre) {
      if (typeof contenuPickers.optVis.actions != "undefined") {
        const nom = contenuPickers.optVis.actions.PUT.tenuecadre.choices.find(item => item.value === tCadre);
        if (typeof nom.display_name != "undefined") {
          var dispname = nom.display_name;
          return dispname
        };
      }
    } else {
      return '';
    }
  };







  return (

    <>
      {allFetch && (
        <Modal open={affVisite} >
          <Container>
            <Box sx={style}>
              <CustomTitre titre={i18n.t('visite.visiteDe') + ' ' + laColonie.nom} />
              <p></p>
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                height: 0.9, overflow: "hidden", overflowY: "scroll",
              }}

              >
                <Grid container display="flex" alignItems="center">
                  <Grid xs={6}>
                    <CustomTextStd label={i18n.t('visite.date')} > {dispDate(laVisite.date)}</CustomTextStd>

                  </Grid>
                  <Grid xs={3} style={{ backgroundColor: bSante(laVisite.sante) }}>
                    <CustomTextStd label={i18n.t('visite.santé')}></CustomTextStd>
                  </Grid>
                  <Grid xs={3}>
                    <CustomTextStd label={i18n.t('visite.nbElts')} labD={6} fieldD={6}>{(laVisite.nbelts ? laVisite.nbelts : "")}</CustomTextStd>
                  </Grid>
                </Grid>
                <p></p>

                <Grid container display="flex" alignItems="center">
                  <Grid xs={6}>
                    <Typography >{i18n.t('visite.intervenant') + ': ' + (laVisite.intervenant ? laVisite.intervenant : "")}</Typography>
                  </Grid>
                  <Grid xs={6}>
                    <Typography ></Typography>
                  </Grid>
                </Grid>
                <p></p>
                <Grid container display="flex" alignItems="center">
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.météo') + ': ' + (laVisite.meteo ? laVisite.meteo : "")}</Typography>
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.celluleRoyale') + ': ' + libCR(laVisite.pres_CR)}</Typography>
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.poids') + ': ' + (laVisite.poids ? laVisite.poids : "")}</Typography>
                </Grid>
                <p></p>
                <Typography style={{ flex: 1, textDecorationLine: (laVisite.ouverture) ? 'none' : 'line-through' }}>{i18n.t('visite.ouverture')}</Typography>

                <Grid container display="flex" alignItems="center">
                  <Grid xs={4}>
                    <Typography style={{ flex: 1, textDecorationLine: (laVisite.pres_oeuf) ? 'none' : 'line-through' }}>{i18n.t('visite.oeufs')}</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography style={{ flex: 1, textDecorationLine: (laVisite.pres_couv_ouv) ? 'none' : 'line-through' }}>{i18n.t('visite.cOuvert')} </Typography>
                  </Grid>
                  <Grid xs={4}>
                    <Typography style={{ flex: 1, textDecorationLine: (laVisite.pres_couv_ferm) ? 'none' : 'line-through' }}>{i18n.t('visite.cFermé')} </Typography>
                  </Grid>
                </Grid>
                <p></p>
                <Grid container display="flex" alignItems="center">
                  <Typography style={{ flex: 1, textDecorationLine: (laVisite.pres_cel_male) ? 'none' : 'line-through' }}>{i18n.t('visite.cMâles')}</Typography>
                  <Typography style={{ flex: 1, textDecorationLine: (laVisite.pres_males) ? 'none' : 'line-through' }}>{i18n.t('visite.mâles')}</Typography>
                  <Typography style={{ flex: 1, textDecorationLine: (laVisite.pres_reine) ? 'none' : 'line-through' }}>{i18n.t('visite.reine')} </Typography>
                </Grid>
                <p></p>
                <Grid container display="flex" alignItems="center">
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.ruelles') + ': ' + (laVisite.ruelles ? laVisite.ruelles : "")}</Typography>
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.1erCadreCouvain') + ': ' + (laVisite.prem_cad_couvain ? laVisite.prem_cad_couvain : "")}</Typography>
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.dernierCadreCouvain') + ': ' + (laVisite.dern_cad_couvain ? laVisite.dern_cad_couvain : "")}</Typography>
                </Grid>
                <p></p>
                <Grid container display="flex" alignItems="center">
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.pb1') + ': ' + libPb(laVisite.problem1)}</Typography>
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.pb2') + ': ' + libPb(laVisite.problem2)}</Typography>
                </Grid>
                <p></p>
                <Grid container display="flex" alignItems="center">
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.comportement') + ': ' + libComport(laVisite.comportement)}</Typography>
                </Grid>
                <Grid container display="flex" alignItems="center">
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.tenueCadre') + ': ' + libTenueCadre(laVisite.tenuecadre)}</Typography>
                </Grid>
                {(lesHausseVisites && laVisite.nbelts > 1 && laVisite.HaussesDetaillees) &&
                  <>
                    {lesHausseVisites.slice(0, laVisite.nbelts - 1).map((hausse, index) => (
                      <Grid style={{ flex: 1, justifyContent: 'flex-start', flexDirection: 'column' }} key={index}>

                        <>
                          <Grid style={{ flexDirection: 'row' }}>
                            {index == 0 && <Typography style={{ fontWeight: 'bold' }}>Haut </Typography>}
                            <Grid style={styles.traitBleu} />
                          </Grid>
                        </>

                        <Typography>{"Nombre de cadres de la Hausse " + (laVisite.nbelts - 1 - index)?.toString()}</Typography>
                        <Grid container display="flex" alignItems="left">
                          <Typography style={{ flex: 1 }}>{"Total    : " + (hausse.NbCadres ? hausse.NbCadres : "")}</Typography>
                          <Typography style={{ flex: 1 }}>{"A bâtir  : " + (hausse.NbCadresABatir ? hausse.NbCadresABatir : "")}</Typography>
                          <Typography style={{ flex: 1 }}>{"En cours : " + (hausse.NbCadresMielEnCours ? hausse.NbCadresMielEnCours : "")}</Typography>
                        </Grid>
                        <Grid container display="flex" alignItems="center">

                          <Typography style={{ flex: 1 }}>{"Operculés : " + (hausse.NbCadresMielOpercule ? hausse.NbCadresMielOpercule : "")}</Typography>
                          <Typography style={{ flex: 1 }}>{"Récoltés  : " + (hausse.NbCadresRecoltes ? hausse.NbCadresRecoltes : "")}</Typography>
                          <Typography style={{ flex: 1 }}></Typography>
                        </Grid>
                        <Typography>{"commentaire : " + (hausse.observation ? hausse.observation : "")}</Typography>
                      </Grid>
                    ))}
                  </>
                }
                <Grid style={{ flexDirection: 'row' }}>
                  <Typography style={{ fontWeight: 'bold' }}>Corps</Typography>
                  <Grid style={styles.traitBleu} />
                </Grid>
                <Typography style={{ flex: 1 }}>{"Etat des réserves " + ': ' + libReserves(laVisite.EtatReserves)}</Typography>


                {laVisite.ouverture && <>

                  <Grid container display="flex" alignItems="center">
                    <Typography style={{ flex: 1 }}>{i18n.t('visite.ruelles') + ': ' + (laVisite.ruelles ? laVisite.ruelles : "")}</Typography>
                    {(contenuPickers.apiculteur.utiliseCadresCouvain && contenuPickers.apiculteur.utilisePremDernCadre) ?
                      <>

                        <Typography style={{ flex: 1 }}>{i18n.t('visite.1erCadreCouvain') + ': ' + (laVisite.prem_cad_couvain ? laVisite.prem_cad_couvain : "")}</Typography>
                        <Typography style={{ flex: 1 }}>{i18n.t('visite.dernierCadreCouvain') + ': ' + (laVisite.dern_cad_couvain ? laVisite.dern_cad_couvain : "")}</Typography>
                      </> :
                      <>
                        <Typography style={{ flex: 1 }}>{i18n.t('visite.nbCadreCouvain') + ': ' + (laVisite.dern_cad_couvain ? laVisite.dern_cad_couvain : "")}</Typography>
                        <Grid style={{ flex: 1 }}></Grid>
                      </>}
                  </Grid>
                </>}
                <Typography style={{ flex: 1 }}>{"Nombre cadres bâtir : " + (laVisite.NbCadresABatir ? laVisite.NbCadresABatir : "")}</Typography>

                <p></p>
                <Box style={{ flexDirection: 'row', flex: 0, display: 'flex', }} overflow="hidden"    >
                  <Typography style={{ flex: 1 }}>{i18n.t('visite.observation')}</Typography>
                  <Typography sx={{ flex: 5, borderColor: 'black', border: 1, borderRadius: 2 }} style={{
                    whiteSpace: 'pre-line',
                  }}>{laVisite.observations}</Typography>
                </Box>
                {/* Travaux réalisé */}
                <Box style={{ flexDirection: 'row', flex: 0, display: 'flex', }} overflow="hidden"    >
                  <Typography style={{ flex: 1 }} sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>{i18n.t('visite.réalisé')}</Typography>
                </Box>
                {laVisite.travauxrealises && <Typography sx={{
                  borderColor: 'black', border: 1, borderRadius: 2, display: "-webkit-box",
                  WebkitLineClamp: "2", WebkitBoxOrient: "vertical", minHeight: 24
                }} style={{
                  whiteSpace: 'pre-line',
                }}>{laVisite.travauxrealises}</Typography>}
                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  {lesTravFaits.map((travailFait, index) => (

                    <AfficheTravail travail={travailFait} index={index} />
                  ))}
                </Box>
                {/* Travaux à faire */}
                <Box style={{ flexDirection: 'row', flex: 0, display: 'flex', }} overflow="hidden"    >
                </Box>

                <Box style={{ flexDirection: 'row', flex: 0, display: 'flex', }} overflow="hidden"    >
                  <Typography style={{ flex: 1 }} sx={{ textDecoration: 'underline', fontWeight: 'bold' }}>{i18n.t('visite.aFaire')}</Typography>
                </Box>

                {laVisite.travauxafaire && <Typography sx={{
                  borderColor: 'black', border: 1, borderRadius: 2, display: "-webkit-box",
                  WebkitLineClamp: "2", WebkitBoxOrient: "vertical", minHeight: 24
                }} style={{
                  whiteSpace: 'pre-line',
                }}>{laVisite.travauxafaire}</Typography>}

                <Box style={{ display: 'flex', flexDirection: 'column' }}>
                  {lesTravAFaire.map((travailAF, index) => (

                    <AfficheTravailAFaire travail={travailAF} index={index} />
                  ))}
                </Box>


                <p></p>
                <Grid style={{ flexDirection: 'row' }}>

                  <Grid style={styles.traitNoir} />
                </Grid>
                <Typography></Typography>
              </Box>

              {messageErreur ? <p>{messageErreur}</p> : null}
              <Grid container display="flex" justifyContent="space-between">
                <Grid container display="flex" justifyContent="flex-start">
                  {laColonie.motifsuppression == null &&

                    <Tooltip title={<Typography fontSize={20}>Supprimer</Typography>} placement="top" sx={{ fontSize: 200 }}>
                      <IconButton onClick={() => { dispatch(setAffDelVisite(true)); }} sx={{ color: red[700] }}>
                        <DeleteIcon sx={{ fontSize: 40, }} />
                      </IconButton>
                    </Tooltip>}
                </Grid>
                <Grid container display="flex" justifyContent="flex-end">
                  {laColonie.motifsuppression == null &&
                    <Tooltip title={<Typography fontSize={20}>Modifier</Typography>} placement="top" sx={{ fontSize: 200 }}>
                      <IconButton onClick={() => { dispatch(setAffModVisite(true)) }} sx={{ color: green[700] }}>
                        <EditIcon sx={{ fontSize: 40, }} />
                      </IconButton>
                    </Tooltip>}

                  <Tooltip title={<Typography fontSize={20}>Fermer</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffVisite(false)) }} >
                      <CloseIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>

            </Box>
          </Container>
        </Modal>
      )}
      {affDelVisite && <DelVisite visite={laVisite} />}
    </>
  )
};
// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>

export default AffichelaVisite;