import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'
import user from './userslice'
import reine from './reineslice'
import eleveur from './eleveurslice'
import display from './displayslice'
import arbreGene from './treeslice'
import cPicker from './cPickersslice'
import typFecondation from './typfecondationslice'
import ruchers from './ruchersslice'
import displayRucher from './displayRucherslice'
import journal from './journalslice'
import displayJournal from './displayJournalslice'
import delegue from './delegueslice'
import displayDelegue from './displayDelegueslice'

const reducer = combineReducers({
  // here we will be adding reducers
  user, 
  reine, 
  display,
  typFecondation,
  eleveur,
  arbreGene,
  cPicker,
  ruchers,
  displayRucher,
  journal,
  displayJournal,
  delegue,
  displayDelegue
})
const store = configureStore({
  reducer,
})
export default store;