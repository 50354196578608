import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Formik, Field, Form, useFormikContext,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';

import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green, deepOrange } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import KeyboardTabIcon from '@mui/icons-material/KeyboardTab';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { makeStyles } from '@mui/styles';

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';

import { headerStyles, styles } from '../../components/Styles';
import useDeviceSize from '../../hooks/useDeviceSize';



import {
  modifyVisite, createVisite, getLastVisite, getListVisite, createTravaux, createTravauxAFaire,
  createHausseVisites
} from '../services/accessApiRuchers';

import { useDispatch, useSelector } from 'react-redux'
import { cPickersStore } from '../../store/cPickersslice'

import { reineStore } from '../../store/reineslice'
import { treeStore } from '../../store/treeslice'

//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';
import CustomInput1 from '../../components/CustomInput1';
import CustomInput2 from '../../components/CustomInput2';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';
import CustomSwitchHoriz from '../../components/CustomSwitchHoriz';
import CustomSlider from '../../components/CustomSlider';

import SaisieTravail from './SaisieTravail';
import SaisieTravailAFaire from './SaisieTravailAFaire';
import SaisieTravailEnAttente from './SaisieTravailEnAttente';


import { setAffModVisite, setAffCreeVisite, listColoniesdeApi } from '../../store/displayRucherslice';
import {
  laVisiteStore, laDerniereVisiteStore, laVisiteRevoke, lesVisitesStore,
  lesTravauxFaitsStore, lesTravauxAFaireStore, lesHausseVisitesStore
} from '../../store/ruchersslice'

import { geoConicConformalRaw } from 'd3';
import { Construction } from '@mui/icons-material';


//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';
import i18n from '../services/i18n';
import { prepDate, dispDate } from '../../services/prepDate';
import dayjs from 'dayjs';


//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

const useStyles = makeStyles({
  root: {
    '& Div': {
      padding: 0,
    },
  },
});

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}

      {...other}
    >
      {value === index && (
        <Box style={{ display: 'flex', flexDirection: 'column', justifyContent: 'stretch', heigth: '100%' }}>
          {children}
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}



function ModVisite(props) {
  const {
    ...inputProps
  } = props

  const dispatch = useDispatch();
  const formRef = useRef();

  const [visible, setVisible] = useState(true);
  const [valTab, setValTab] = useState(0);

  const Moment = require('moment');
  const [allFetch, setAllFetch] = useState();
  const [travauxEnAttente, setTravauxEnAttente] = useState([]);
  // forçage rerener
  const [render, rerender] = useState(false);
  const [widthR, heightR] = useDeviceSize(0, 250)


  const { cPickers } = useSelector(state => state.cPicker);
  let contenuPickers = Object.assign({}, cPickers);

  const visite = useSelector(state => state.ruchers).laVisite;
  const colonie = useSelector(state => state.ruchers).laColonie;
  const lesTravFaits = useSelector(state => state.ruchers).lesTravauxFaits;
  const lesTravAFaire = useSelector(state => state.ruchers).lesTravauxAFaire;
  const lesHausseVisites = useSelector(state => state.ruchers).lesHausseVisites;
  console.log('//////////////////////////////////////////////////////////');
  console.log(lesHausseVisites);
  var modeModif = false;
  var modeDuplic = false;
  modeModif = useSelector(state => state.displayRucher).affModVisite;
  const listeTravauxPossibles = contenuPickers.listTravaux;

  // -- A partir de la liste des travaux possibles, recupération de la liste des travaux A faire
  // liste des travaux 
  useEffect(() => {

    let lesTravauxEnAttente = [];
    for (let travailEnAtt of lesTravAFaire) {
      const locTravail = new Object();
      locTravail.faire = travailEnAtt.faire;
      locTravail.deFaire = travailEnAtt.deFaire;
      locTravail.commentaire = travailEnAtt.commentaire;
      locTravail.dateS = prepDate(travailEnAtt.date);
      locTravail.id = travailEnAtt.id;
      locTravail.travail = travailEnAtt.travail;
      // récupération des libellés
      let trav = listeTravauxPossibles.find(elt => elt.id == travailEnAtt.travail);
      if (trav) {
        locTravail.nomFaire = trav.nomFaire;
        locTravail.nomDefaire = trav.nomDefaire;
        locTravail.aNomDefaire = trav.aNomDefaire;
        locTravail.aCommentaire = trav.aCommentaire;
        locTravail.nomCommentaire = trav.nomCommentaire;
        lesTravauxEnAttente.push(locTravail);
      }
    }
    setTravauxEnAttente(lesTravauxEnAttente);
  }, [listeTravauxPossibles]);


  const incValTab = () => {
    let tmp = valTab + 1;
    if (tmp > 3) { tmp = 0 };
    setValTab(tmp);
  }
  const handleChangeTabs = (event, newValue) => {
    setValTab(newValue);
  };


  const determComportement = (options) => {
    var lesChoix = options.actions.PUT.comportement.choices;
    var tabChoix = [];
    var ind = 0;
    for (var choix of lesChoix) {
      tabChoix.push({ label: choix.display_name, id: choix.value });
      ind++;
    };
    return tabChoix
  };

  const determTenueCadre = (options) => {
    var lesChoix = options.actions.PUT.tenuecadre.choices;
    var tabChoix = [];
    var ind = 0;
    for (var choix of lesChoix) {
      tabChoix.push({ label: choix.display_name, id: choix.value });
      ind++;
    };
    return tabChoix
  };

  const determSante = (options) => {
    var lesChoix = options.actions.PUT.sante.choices;
    var tabChoix = [];
    var ind = 0;
    for (var choix of lesChoix) {
      tabChoix.push({ label: choix.display_name, id: choix.value });
      ind++;
    };
    return tabChoix
  };

  const determReserves = (options) => {
    console.log('determ reserve');
    console.log(options.actions.PUT);
    var lesChoix = options.actions.PUT.EtatReserves.choices;
    console.log(lesChoix);
    var tabChoix = [];
    var ind = 0;
    for (var choix of lesChoix) {
      console.log(choix);
      tabChoix.push({ label: choix.display_name, id: choix.value });
      ind++;
    };
    return tabChoix
  };

  const Initialisation = () => {
    const init = new Object();
    let lesTravauxFaits = [];
    let lesTravauxAFaire = [];
    for (let travailFait of listeTravauxPossibles) {
      const locTravail = new Object();
      locTravail.id = travailFait.id;
      locTravail.nomFaire = travailFait.nomFaire;
      locTravail.nomDefaire = travailFait.nomDefaire;
      locTravail.aNomDefaire = travailFait.aNomDefaire;
      locTravail.aCommentaire = travailFait.aCommentaire;
      locTravail.nomCommentaire = travailFait.nomCommentaire;
      locTravail.ordre = travailFait.ordre;
      locTravail.fait = false;
      locTravail.deFait = false;
      locTravail.commentaire = '';
      locTravail.dateS = new Date();
      lesTravauxFaits.push(locTravail);
      const locTravailDeepCopy = Object.assign({}, locTravail)
      lesTravauxAFaire.push(locTravailDeepCopy);
    }
    init.travauxFaits = lesTravauxFaits;
    init.travauxAFaire = lesTravauxAFaire;

    let lesHausses = [];
    for (var i = 0; i < 10; i++) {
      const locHausse = new Object();
      locHausse.NbCadres = '';
      locHausse.NbCadresABatir = '';
      locHausse.NbCadresRecoltes = '';
      locHausse.NbCadresMielEnCours = '';
      locHausse.NbCadresMielOpercule = '';
      locHausse.observation = '';
      lesHausses.push(locHausse);
    };
    var j = 0;

    for (let hausse of lesHausseVisites) {

      hausse.NbCadres && (lesHausses[j].NbCadres = '' + hausse.NbCadres);
      hausse.NbCadresABatir && (lesHausses[j].NbCadresABatir = '' + hausse.NbCadresABatir);
      hausse.NbCadresRecoltes && (lesHausses[j].NbCadresRecoltes = '' + hausse.NbCadresRecoltes);
      hausse.NbCadresMielEnCours && (lesHausses[j].NbCadresMielEnCours = '' + hausse.NbCadresMielEnCours);
      hausse.NbCadresMielOpercule && (lesHausses[j].NbCadresMielOpercule = '' + hausse.NbCadresMielOpercule);
      lesHausses[j].observation = hausse.observation;
      j++;
    };
    init.lesHausses = lesHausses;
    console.log('=======================================');
    console.log(init.lesHausses);

    if (modeModif) {
      init.dateS = prepDate(visite.date);
      init.ouverture = visite.ouverture;
      init.pres_oeuf = visite.pres_oeuf;
      init.pres_couv_ouv = visite.pres_couv_ouv;
      init.pres_couv_ferm = visite.pres_couv_ferm;
      init.pres_reine = visite.pres_reine;
      { visite.pres_CR ? init.pres_CR = '' + visite.pres_CR : init.pres_CR = '' };
      init.pres_cel_male = visite.pres_cel_male;
      init.pres_males = visite.pres_males;
      { (visite.problem1) ? init.problem1 = '' + visite.problem1 : init.problem1 = '' };
      { (visite.problem2) ? init.problem2 = '' + visite.problem2 : init.problem2 = '' };
      init.observations = visite.observations;
      init.travauxrealises = visite.travauxrealises;
      init.travauxafaire = visite.travauxafaire;
      { (visite.prem_cad_couvain) ? init.prem_cad_couvain = '' + visite.prem_cad_couvain : init.prem_cad_couvain = '' };
      { (visite.dern_cad_couvain) ? init.dern_cad_couvain = '' + visite.dern_cad_couvain : init.dern_cad_couvain = '' };
      { (visite.nbelts) ? init.nbelts = '' + visite.nbelts : init.nbelts = '' };
      { (visite.poids) ? init.poids = visite.poids : init.poids = '' };
      { (visite.ruelles) ? init.ruelles = '' + visite.ruelles : init.ruelles = '' };
      { (visite.sante) ? init.sante = '' + visite.sante : init.sante = '' };
      { (visite.meteo) ? init.meteo = '' + visite.meteo : init.meteo = '' };
      { (visite.tenuecadre) ? init.tenuecadre = '' + visite.tenuecadre : init.tenuecadre = '' };
      { (visite.comportement) ? init.comportement = '' + visite.comportement : init.comportement = '' };
      { (visite.intervenant) ? init.intervenant = '' + visite.intervenant : init.intervenant = '' };
      { (visite.ChampLibre1) ? init.ChampLibre1 = '' + visite.ChampLibre1 : init.ChampLibre1 = '' };
      { (visite.ChampLibre2) ? init.ChampLibre2 = '' + visite.ChampLibre2 : init.ChampLibre2 = '' };
      { (visite.NbCadresABatir) ? init.NbCadresABatir = '' + visite.NbCadresABatir : init.NbCadresABatir = '' };
      { (visite.EtatReserves) ? init.EtatReserves = '' + visite.EtatReserves : init.EtatReserves = '' };
      init.HaussesDetaillees = visite.HaussesDetaillees;


      // -- A partir de la liste des travaux possibles, récup données travaux faits sauveagrds
      if (lesTravFaits.length > 0) {

        for (let travailPos of init.travauxFaits) {
          let trav = lesTravFaits.find(elt => elt.travail == travailPos.id);
          if (trav) {
            travailPos.fait = trav.faire;
            travailPos.deFait = trav.deFaire;
            travailPos.commentaire = trav.commentaire;
          }
        }
      }
      // -- A partir de la liste des travaux possibles, récup données travaux à faire sauveagrds
      if (lesTravAFaire.length > 0) {
        for (let travailPos of init.travauxAFaire) {
          let trav = lesTravAFaire.find(elt => elt.travail == travailPos.id);
          if (trav) {
            travailPos.fait = trav.faire;
            travailPos.deFait = trav.deFaire;
            travailPos.commentaire = trav.commentaire;
            travailPos.dateS = prepDate(trav.date);
          }
        }
      }

    }
    else {
      init.ouverture = true;
      init.pres_oeuf = false;
      init.pres_couv_ouv = false;
      init.pres_couv_ferm = false;
      init.pres_reine = false;
      init.pres_CR = "";
      init.pres_cel_male = false;
      init.pres_males = false;
      init.problem1 = '';
      init.problem2 = '';
      init.observations = '';
      init.travauxrealises = '';
      init.travauxafaire = '';
      init.prem_cad_couvain = '';
      init.dern_cad_couvain = '';
      init.nbelts = '';
      init.poids = '';
      init.ruelles = '';
      init.sante = '';
      init.tenuecadre = '';
      init.comportement = '';
      init.ChampLibre1 = '';
      init.ChampLibre2 = '';
      if (contenuPickers.date) {
        init.dateS = prepDate(contenuPickers.date);
      } else {
        init.dateS = new Date();
      }
      contenuPickers.intervenant ? init.intervenant = contenuPickers.intervenant : init.intervenant = contenuPickers.apiculteur.nom;
      contenuPickers.meteo ? init.meteo = contenuPickers.meteo : init.meteo = '';
      init.EtatReserves = '';
      init.NbCadresABatir = '';
      init.HaussesDetaillees = false;
    };
    return init

  }

  const ValidationSchema = yup.object().shape({
    meteo: yup
      .string()
      .max(20, i18n.t('error.tropLong')),
    intervenant: yup
      .string()
      .max(20, i18n.t('error.tropLong')),
    ChampLibre1: yup
      .string()
      .max(30, i18n.t('error.tropLong')),
    ChampLibre2: yup
      .string()
      .max(30, i18n.t('error.tropLong')),
    prem_cad_couvain: yup
      .number()
      .typeError('Nombre'),
    dern_cad_couvain: yup
      .number()
      .typeError('Nombre'),
    ruelles: yup
      .number()
      .typeError('Nombre'),
    poids: yup
      .number()
      .transform((_value, originalValue) => Number(originalValue.replace(/,/, '.')))
      .typeError('Nombre'),
    nbelts: yup
      .string()
      .matches(/^\d{1,3}((\.|\,)\d{0,1})?$/, i18n.t('visite.errpoids')),
  });


  const FormonSubmit = (data) => {
    console.log('formon submit 1');
    const mVisite = new Object();
    mVisite.date = dayjs(data.dateS).format('YYYY-MM-DD');
    mVisite.ouverture = data.ouverture;
    mVisite.pres_oeuf = data.pres_oeuf;
    mVisite.pres_couv_ouv = data.pres_couv_ouv;
    mVisite.pres_couv_ferm = data.pres_couv_ferm;
    mVisite.pres_reine = data.pres_reine;
    mVisite.pres_CR = data.pres_CR;
    mVisite.pres_cel_male = data.pres_cel_male;
    mVisite.pres_males = data.pres_males;
    mVisite.prem_cad_couvain = data.prem_cad_couvain;
    mVisite.dern_cad_couvain = data.dern_cad_couvain;
    mVisite.ruelles = data.ruelles;
    mVisite.problem1 = data.problem1;
    mVisite.problem2 = data.problem2;
    mVisite.meteo = data.meteo;
    mVisite.nbelts = data.nbelts;
    mVisite.poids = data.poids.replace(',', '.');
    mVisite.observations = data.observations;
    mVisite.travauxrealises = data.travauxrealises;
    mVisite.travauxafaire = data.travauxafaire;
    mVisite.comportement = data.comportement;
    mVisite.tenuecadre = data.tenuecadre;
    mVisite.sante = data.sante;
    mVisite.intervenant = data.intervenant;
    mVisite.ChampLibre1 = data.ChampLibre1;
    mVisite.ChampLibre2 = data.ChampLibre2;
    mVisite.colonie = colonie.id;
    // chargement de la liste des travaux faits
    let lesTravFaits = [];
    for (let travailFait of data.travauxFaits) {
      const locTravail = new Object();
      locTravail.travail = travailFait.id;
      locTravail.commentaire = travailFait.commentaire;
      locTravail.faire = travailFait.fait;
      locTravail.deFaire = travailFait.deFait;
      modeModif ? locTravail.visite = visite.id : locTravail.visite = 0;
      lesTravFaits.push(locTravail);
    };
    // chargement de la liste des travauxà faire - on ne conserve que s'il y  faireou defaire indiqué
    let lesTravAFaire = [];
    for (let travailAFaire of data.travauxAFaire) {
      const locTravail = new Object();
      if (travailAFaire.fait || travailAFaire.deFait) {
        locTravail.travail = travailAFaire.id;
        locTravail.commentaire = travailAFaire.commentaire;
        locTravail.faire = travailAFaire.fait;
        locTravail.deFaire = travailAFaire.deFait;
        locTravail.colonie = colonie.id;
        locTravail.date = dayjs(travailAFaire.dateS).format('YYYY-MM-DD');
        modeModif ? locTravail.visite = visite.id : locTravail.visite = 0;
        lesTravAFaire.push(locTravail);
      }
    };
    console.log('formon submit 2');
    console.log(visite.id);
    mVisite.HaussesDetaillees = data.HaussesDetaillees;
    mVisite.NbCadresABatir = data.NbCadresABatir;
    mVisite.EtatReserves = data.EtatReserves;
    if (contenuPickers.apiculteur.utiliseCadresCouvain && contenuPickers.apiculteur.utilisePremDernCadre) {

      mVisite.prem_cad_couvain = data.prem_cad_couvain;
      mVisite.dern_cad_couvain = data.dern_cad_couvain;
    }
    if (contenuPickers.apiculteur.utiliseCadresCouvain && !contenuPickers.apiculteur.utilisePremDernCadre) {
      mVisite.prem_cad_couvain = '1';
      mVisite.dern_cad_couvain = (parseInt(data.dern_cad_couvain)).toString();
    }

    mVisite.ruelles = data.ruelles;
    console.log('formon submit 3');
    console.log(visite.id);


    //dispatch(laVisiteStore(mVisite));
    let lVisite = 0;
    modeModif ? lVisite = visite.id : lVisite = 0;

    //const locHausses = Object.assign({}, data.lesHausses.slice(0,data.nbelts -1));

    //{values.lesHausses.slice(0, values.nbelts - 1).map((hausse, index) => (
    //data.lesHausses.map (hausse => ({  ...hausse, visite : lVisite }));
    data.lesHausses.map(hausse => {
      hausse.visite = lVisite;
    }
    );


    let xxx = Object.assign({}, contenuPickers);
    contenuPickers.intervenant = data.intervenant;
    //contenuPickers['intervenant'] = 'jmd';
    contenuPickers.meteo = data.meteo;
    contenuPickers.date = data.date;
    dispatch(cPickersStore(contenuPickers));
    console.log('formon submit 4');
    console.log(visite.id);

    if (modeModif) {
      console.log('formon submit 5');
      console.log(visite);
      console.log(visite.id);
      mVisite.id = visite.id;
      console.log('formon submit 6');

      modifyVisite(mVisite)
        .then(() => {
          console.log('formon submit 7');

          dispatch(laVisiteStore(mVisite));
          Promise.all([createTravaux(lesTravFaits, mVisite.id), createTravauxAFaire(lesTravAFaire, colonie.id),
          createHausseVisites(data.lesHausses.slice(0, data.nbelts - 1), mVisite.id)
          ])
            .then(([lesTrav, lesTravAF, lesHausses]) => {
              Promise.all([getLastVisite(JSON.stringify(colonie.id)), getListVisite(JSON.stringify(colonie.id))])
                .then(([localeVisite, lesVisites]) => {
                  dispatch(laDerniereVisiteStore(localeVisite));
                  dispatch(lesVisitesStore(lesVisites));
                  dispatch(lesTravauxFaitsStore(lesTrav));
                  dispatch(lesTravauxAFaireStore(lesTravAF));
                  dispatch(lesHausseVisitesStore(lesHausses))

                  dispatch(setAffModVisite(false));
                })
                .catch((err) => { console.log('erreur !!! mod'); console.log(err) })
            })
        })

      // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher })});
    } else {
      createVisite(mVisite)
        .then((visite) => {
          dispatch(laVisiteStore(visite))
          for (let travailFait of lesTravFaits) {
            travailFait.visite = visite.id;
          };
          Promise.all([createTravaux(lesTravFaits, visite.id), createTravauxAFaire(lesTravAFaire, colonie.id),
          createHausseVisites(data.lesHausses.slice(0, data.nbelts - 1), visite.id)
          ])
            .then(([lesTrav, lesTravAF, lesHausses]) => {
              Promise.all([getLastVisite(JSON.stringify(colonie.id)), getListVisite(JSON.stringify(colonie.id))])
                .then(([localeVisite, lesVisites]) => {
                  dispatch(laDerniereVisiteStore(localeVisite));
                  dispatch(lesVisitesStore(lesVisites));
                  dispatch(lesTravauxFaitsStore(lesTrav));
                  dispatch(lesTravauxAFaireStore(lesTravAF));
                  dispatch(setAffModVisite(false));
                  dispatch(setAffCreeVisite(false));
                })
            })
        })
        .catch((err) => { console.log('erreur !!!'); console.log(err) });
    }
  };

  const onClicTravFait = (id, setFieldValue) => {

    let locsLesTravAtt = travauxEnAttente;
    const ind = locsLesTravAtt.map(e => e.id).indexOf(id);
    if (ind >= 0) {
      let travailFait = locsLesTravAtt[ind];
      // suppression trav en attnte de la liste travaux faits en attente
      locsLesTravAtt.splice(ind, 1)
      setTravauxEnAttente(locsLesTravAtt);
      // mise à jours des travaux faits
      let lesTravFaits = formRef.current.values.travauxFaits;
      let indTravFaits = lesTravFaits.map(e => e.id).indexOf(travailFait.travail);
      if (indTravFaits >= 0) {
        setFieldValue('travauxFaits[' + indTravFaits + '].fait', travailFait.faire);
        setFieldValue('travauxFaits[' + indTravFaits + '].deFait', travailFait.deFaire);
        setFieldValue('travauxFaits[' + indTravFaits + '].commentaire', travailFait.commentaire);
      }

      // suppression dans les travaux à faire      
      let lesTravAFaire = formRef.current.values.travauxAFaire;
      let indTravAFaire = lesTravAFaire.map(e => e.id).indexOf(travailFait.travail);
      if (indTravAFaire >= 0) {
        setFieldValue('travauxAFaire[' + indTravAFaire + '].fait', false);
        setFieldValue('travauxAFaire[' + indTravAFaire + '].deFait', false);
        setFieldValue('travauxAFaire[' + indTravAFaire + '].commentaire', "");
      }
      rerender(!render);
    }
  };

  // abandon travail a fire --> pas de MAJ trvaux faire - maj travaux à faire  
  const onClicTravAbnd = (id, setFieldValue) => {
    let locsLesTravAtt = travauxEnAttente;
    const ind = locsLesTravAtt.map(e => e.id).indexOf(id);
    if (ind >= 0) {
      let travailFait = locsLesTravAtt[ind];
      locsLesTravAtt.splice(ind, 1)
      setTravauxEnAttente(locsLesTravAtt);

      // suppression dans les travaux à faire      
      let lesTravAFaire = formRef.current.values.travauxAFaire;
      let indTravAFaire = lesTravAFaire.map(e => e.id).indexOf(travailFait.travail);
      if (indTravAFaire >= 0) {
        setFieldValue('travauxAFaire[' + indTravAFaire + '].fait', false);
        setFieldValue('travauxAFaire[' + indTravAFaire + '].deFait', false);
        setFieldValue('travauxAFaire[' + indTravAFaire + '].commentaire', "");
      }
      rerender(!render);
    }
  };

  const classes = useStyles();


  return (
    <>
      <Dialog
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" maxWidth={false}
        style={{ overflow: 'scroll' }} >
        <Box style={{ flexDirection: 'column', display: 'flex', height: '95vh' }} sx={{
          width: 900, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
            innerRef={formRef}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>
                <Typography sx={{ fontSize: 30, }} align='center'>{i18n.t('visite.colonie') + ': ' + colonie.nom} </Typography>
                <Divider />
                <Box style={{ flexDirection: 'column', display: 'flex', justifyContent: 'space-between' }}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <Tabs value={valTab} onChange={handleChangeTabs} aria-label="basic tabs example">
                      <Tab label="Contexte" {...a11yProps(0)} />
                      <Tab label="Observation" {...a11yProps(1)} />
                      <Tab label="Cadres" {...a11yProps(2)} />
                      <Tab label="Travaux" {...a11yProps(3)} />
                      <Tab label="A faire" {...a11yProps(4)} />
                    </Tabs>
                  </Box>
                  <Box style={{ height: { heightR } }} >
                    {/*   Panneau 1///////////////////////////////////////*/}
                    {/*   Panneau 1///////////////////////////////////////*/}
                    <TabPanel value={valTab} index={0} className={classes.root}>

                    <Box style={{ overflow: "hidden", overflowY: "scroll", height: heightR }}>


                        <Box container direction="column" justifyContent="start">

                          <Grid container>
                            <Grid xs={3} sx={{ pt: 1, pl: 1, color: grey[600], fontSize: 15 }} display="flex">
                              <Typography style={{ flex: 1 }}>{i18n.t('visite.visiteDu')}</Typography>
                            </Grid>
                            <Grid xs={3}>
                              <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                                <DesktopDatePicker
                                  value={values.dateS}
                                  onChange={(newValue) => { setFieldValue("dateS", newValue || values.dateS); }}
                                  renderInput={(params) =>
                                    <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                                />
                              </LocalizationProvider>
                            </Grid>
                            <Grid xs={6}></Grid>
                          </Grid>

                          <Grid container>
                            {contenuPickers.apiculteur.utiliseMeteo && (
                              <Grid xs={6}>
                                <Field xs={6} component={CustomInput} name="meteo" label={i18n.t('visite.météo')} placeholder={i18n.t('visite.météo')} />
                              </Grid>)}
                            {contenuPickers.apiculteur.utiliseIntervenant && (
                              <Grid xs={6}>
                                <Field xs={6} component={CustomInput} name="intervenant" label={i18n.t('visite.intervenant')} placeholder={i18n.t('visite.intervenant')} />
                              </Grid>)}
                          </Grid>
                          <Grid container>
                            {contenuPickers.apiculteur.utilisePoids && (
                              <Grid xs={6}>
                                <Field xs={6} component={CustomInput} name="poids" label={i18n.t('visite.poids')}
                                  placeholder={i18n.t('visite.poids')} />
                              </Grid>)}

                          </Grid>
                        </Box>
                      </Box>
                    </TabPanel>
                    {/*   Panneau 2///////////////////////////////////////*/}
                    {/*   Panneau 2///////////////////////////////////////*/}
                    <TabPanel value={valTab} index={1} className={classes.root}>
                      <Box style={{ overflow: "hidden", overflowY: "scroll", height: heightR }}>
                        <Grid container>

                          <Grid xs={4}>

                            <CustomSwitch label={i18n.t('visite.ouverture')} vSwitch={values.ouverture} onChange={event => setFieldValue('ouverture', event.target.checked)} />
                          </Grid>
                        </Grid>
                        {values.ouverture &&
                          <Grid container>
                            <Grid xs={4}>
                              <CustomSwitch label={i18n.t('visite.oeufs')} vSwitch={values.pres_oeuf} onChange={event => setFieldValue('pres_oeuf', event.target.checked)} />
                            </Grid>
                            <Grid xs={4}>
                              <CustomSwitch label={i18n.t('visite.couvainOuvert')} vSwitch={values.pres_couv_ouv} onChange={event => setFieldValue('pres_couv_ouv', event.target.checked)} />
                            </Grid>
                            <Grid xs={4}>
                              <CustomSwitch label={i18n.t('visite.couvainFermé')} vSwitch={values.pres_couv_ferm} onChange={event => setFieldValue('pres_couv_ferm', event.target.checked)} />
                            </Grid>
                          </Grid>}
                        <Grid container>
                          {values.ouverture &&
                            <>
                              <Grid xs={4}>
                                <CustomSwitch label={i18n.t('visite.reine')} vSwitch={values.pres_reine} onChange={event => setFieldValue('pres_reine', event.target.checked)} />
                              </Grid>
                              <Grid xs={4}>
                                <CustomSwitch label={i18n.t('visite.cMâles')} vSwitch={values.pres_cel_male} onChange={event => setFieldValue('pres_cel_male', event.target.checked)} />
                              </Grid>
                            </>}
                          <Grid xs={4}>
                            <CustomSwitch label={i18n.t('visite.mâles')} vSwitch={values.pres_males} onChange={event => setFieldValue('pres_males', event.target.checked)} />
                          </Grid>
                        </Grid>

                        {contenuPickers.apiculteur.utiliseCadresCouvain && values.ouverture && (
                          <Grid container>
                            <Grid xs={6}>
                              <Field component={CustomInput} name="prem_cad_couvain" label={i18n.t('visite.1erCadreCouvain')}
                                placeholder={i18n.t('visite.numéro')} />
                            </Grid>
                            <Grid xs={6}>
                              <Field component={CustomInput} name="dern_cad_couvain" label={i18n.t('visite.dernierCadreCouvain')}
                                placeholder={i18n.t('visite.numéro')} />
                            </Grid>
                          </Grid>)}

                        {contenuPickers.apiculteur.utiliseRuelles && values.ouverture && (
                          <Grid container>
                            <Grid xs={6}>
                              <Field component={CustomInput} name="ruelles" label={i18n.t('visite.ruelles')}
                                placeholder={i18n.t('visite.nombre')} />
                            </Grid>
                            <Grid xs={6}></Grid>
                          </Grid>)}
                        {values.ouverture &&
                          <Grid container>
                            <Grid xs={6}>
                              <Field component={CustomPicker}
                                label={i18n.t('visite.cellRoyales')}
                                liste={contenuPickers.TypCR} itemKey='id' itemLabel='nom'
                                ModeModif={modeModif}
                                selectedValue={values.pres_CR}
                                langue={i18n.locale}
                                handleChange={(itemIndex) => handleChange('pres_CR')(itemIndex)}
                              />
                            </Grid>
                          </Grid>}
                        <Grid container>
                          <Grid xs={6}>
                            <Field component={CustomPicker}
                              label={i18n.t('visite.problème1')}
                              liste={contenuPickers.TypPb} itemKey='id' itemLabel='nomprobleme' NomListObjet="nomprobleme"
                              ModeModif={modeModif}
                              selectedValue={values.problem1}
                              langue={i18n.locale}
                              handleChange={(itemIndex) => handleChange('problem1')(itemIndex)}
                            />
                          </Grid>
                          <Grid xs={6}>

                            <Field component={CustomPicker}
                              label={i18n.t('visite.problème2')}
                              liste={contenuPickers.TypPb} itemKey='id' itemLabel='nomprobleme' NomListObjet="nomprobleme"
                              ModeModif={modeModif}
                              selectedValue={values.problem2}
                              langue={i18n.locale}
                              handleChange={(itemIndex) => handleChange('problem2')(itemIndex)}
                            />
                          </Grid>
                        </Grid>
                        {contenuPickers.apiculteur.utiliseComportement && (
                          <Field component={CustomPicker}
                            label={i18n.t('visite.comportement')}
                            liste={determComportement(contenuPickers.optVis)} itemKey='id' itemLabel='label' NomListObjet="label"
                            ModeModif={modeModif}
                            selectedValue={values.comportement}
                            handleChange={(itemIndex) => handleChange('comportement')(itemIndex)}
                          />)}

                        {contenuPickers.apiculteur.utiliseTenueCadre && (
                          <Field component={CustomPicker}
                            label={i18n.t('visite.tenueCadre')}
                            liste={determTenueCadre(contenuPickers.optVis)} itemKey='id' itemLabel='label' NomListObjet="label"
                            ModeModif={modeModif}
                            selectedValue={values.tenuecadre}
                            langue={i18n.locale}
                            handleChange={(itemIndex) => handleChange('tenuecadre')(itemIndex)}
                          />)}

                        {contenuPickers.apiculteur.utiliseSante && (
                          <Field component={CustomPicker}
                            label={i18n.t('visite.santé')}
                            liste={determSante(contenuPickers.optVis)} itemKey='id' itemLabel='label' NomListObjet="label"
                            ModeModif={modeModif}
                            selectedValue={values.sante}
                            langue={i18n.locale}
                            handleChange={(itemIndex) => handleChange('sante')(itemIndex)}
                          />)}

                        {contenuPickers.apiculteur.UtileseChampLibre1 && (
                          <Grid style={{ flex: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Field component={CustomInput} name="ChampLibre1" label={contenuPickers.apiculteur.nomChampLibre1}
                              placeholder={contenuPickers.apiculteur.nomChampLibre1} />
                          </Grid>)}

                        {contenuPickers.apiculteur.UtileseChampLibre2 && (
                          <Grid style={{ flex: 0, flexDirection: 'row', alignItems: 'center', justifyContent: 'flex-start' }}>
                            <Field component={CustomInput} name="ChampLibre2" label={contenuPickers.apiculteur.nomChampLibre2}
                              placeholder={contenuPickers.apiculteur.nomChampLibre2} />
                          </Grid>)}
                        <Field component={CustomInput1} name="observations" label={i18n.t('visite.observation')} placeholder={i18n.t('visite.observation')}
                          multiline={true} />

                      </Box>
                    </TabPanel>
                    <TabPanel value={valTab} index={2} className={classes.root}>
                      <Box style={{ overflow: "hidden", overflowY: "scroll", height: heightR }}>
                      {contenuPickers.apiculteur.utiliseNbElts && (
                              <Grid xs={6}>
                                <Field xs={6} component={CustomInput} name="nbelts" label={i18n.t('visite.nbElts')}
                                  placeholder={i18n.t('visite.nbElts')} />
                              </Grid>)}

                        {values.nbelts && values.nbelts > 1 &&
                          <>

                              <Field component={CustomSwitchHoriz} label='Détailler' 
                              value={values.HaussesDetaillees} name='HaussesDetaillees' />

                          </>
                        }

                        {values.HaussesDetaillees &&
                          <>
                            {values.lesHausses.slice(0, values.nbelts - 1).map((hausse, index) => (
                              <Grid style={{ flex: 1, justifyContent: 'flex-start', flexDirection: 'column' }} key={index}>

                                <>
                                  <Grid style={{ flexDirection: 'row' }}>
                                    {index == 0 && <Typography style={{ fontWeight: 'bold' }}>Haut </Typography>}
                                    <Grid style={styles.traitBleu} />
                                  </Grid>
                                </>
                                <Typography>{"Nombre de cadres de la Hausse " + (values.nbelts - 1 - index)?.toString()}</Typography>
                                <Grid container direction='row' spacing={2}>
                                  <Grid item direction='Column' xs={6}>
                                    <Field component={CustomSlider} name={"lesHausses[" + index + "].NbCadres"} label="Total "
                                      keyboardType="numeric" placeholder="NbCadres" value={hausse.NbCadres} index={index} key={index} />
                                    <Field component={CustomSlider} name={"lesHausses[" + index + "].NbCadresMielEnCours"} label="En remplissage"
                                      keyboardType="numeric" placeholder="NbCadresMielEnCours" value={hausse.NbCadresMielEnCours} index={index} />
                                    <Field component={CustomSlider} name={"lesHausses[" + index + "].NbCadresRecoltes"} label="Récoltés "
                                      keyboardType="numeric" placeholder="NbCadresRecoltes" value={hausse.NbCadresRecoltes} index={index} />

                                  </Grid>

                                  <Grid item direction='Column' xs={6}>
                                    <Field component={CustomSlider} name={"lesHausses[" + index + "].NbCadresABatir"} label="A bâtir"
                                      keyboardType="numeric" placeholder="NbCadresABatir" value={hausse.NbCadresABatir} index={index} />
                                    <Field component={CustomSlider} name={"lesHausses[" + index + "].NbCadresMielOpercule"} label="Operculés"
                                      keyboardType="numeric" placeholder="NbCadresMielOpercule" value={hausse.NbCadresMielOpercule} index={index} />

                                  </Grid>
                                </Grid>
                                <Grid container sx={{ marginTop: '10px', marginBottom: '10px' }}>
                                  <Grid item xs={10}>
                                    <Field component={CustomInput}
                                      name={"lesHausses[" + index + "].observation"}
                                      label={i18n.t('visite.observation')}
                                      placeholder={i18n.t('visite.observation')}
                                      labD={2} fieldD={10} />
                                  </Grid>
                                </Grid>

                              </Grid>
                            ))}
                          </>
                        }
                        <Grid style={{ flexDirection: 'row' }}>
                          <Typography style={{ fontWeight: 'bold' }}>Corps</Typography>
                          <Grid style={styles.traitBleu} />
                        </Grid>
                        <Field component={CustomPicker}
                          label={i18n.t('visite.reserves')}
                          liste={determReserves(contenuPickers.optVis)} itemKey='id' itemLabel='label' NomListObjet="label"
                          ModeModif={modeModif}
                          selectedValue={values.EtatReserves}
                          langue={i18n.locale}
                          handleChange={(itemIndex) => handleChange('EtatReserves')(itemIndex)}

                        />


                        <Field component={CustomSlider} name="NbCadresABatir" label="Cadres à bâtir "
                          keyboardType="numeric" placeholder="NbCadres" value={values.NbCadresABatir} />
                        {(contenuPickers.apiculteur.utiliseCadresCouvain && contenuPickers.apiculteur.utilisePremDernCadre && visite.ouverture) && (
                          <>
                            <Field component={CustomSlider} name="prem_cad_couvain" label="1er cadre couvain "
                              keyboardType="numeric" placeholder="NbCadres" value={values.prem_cad_couvain} />
                            <Field component={CustomSlider} name="dern_cad_couvain" label="Dern cadre couvain "
                              keyboardType="numeric" placeholder="NbCadres" value={values.dern_cad_couvain} /></>)}
                        {(contenuPickers.apiculteur.utiliseCadresCouvain && !contenuPickers.apiculteur.utilisePremDernCadre && visite.ouverture) && (
                          <>
                            <Field component={CustomSlider} name="dern_cad_couvain" label="Nb cadres couvain "
                              keyboardType="numeric" placeholder="NbCadres" value={values.dern_cad_couvain} /></>)}
                        <Field component={CustomSlider} name="ruelles" label="Ruelles "
                          keyboardType="numeric" placeholder="NbCadres" value={values.ruelles} />

                      </Box>

                    </TabPanel>
                    {/* Travaux faits ==================================================*/}
                    <TabPanel value={valTab} index={3} className={classes.root}>
                      <Box style={{ overflow: "hidden", overflowY: "scroll", height: heightR }}>

                        {(travauxEnAttente.length > 0) &&
                          <Typography sx={{ color: deepOrange[900], m: 1 }}>Travaux en attente</Typography>}

                        {travauxEnAttente.map((travailEnAtt, index) => (
                          <Field component={SaisieTravailEnAttente} travail={travailEnAtt} index={index} onClickFait={onClicTravFait}
                            onClickAbnd={onClicTravAbnd} setField={setFieldValue}
                          />
                        ))}
                        <Box style={{ display: 'flex', flexDirection: 'row' }}>
                        </Box>
                        {(travauxEnAttente.length > 0) &&
                          <Box style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ backgroundColor: 'black', height: 1, flex: 1, alignSelf: 'center', }}></div>
                            <ArrowDownwardIcon />
                            <div style={{ backgroundColor: 'black', height: 1, flex: 1, alignSelf: 'center', }}></div>
                            <ArrowDownwardIcon />
                            <div style={{ backgroundColor: 'black', height: 1, flex: 1, alignSelf: 'center', }}></div>
                          </Box>}
                        <Typography sx={{ color: deepOrange[900], m: 1 }}>Travaux réalisés</Typography>

                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                          {values.travauxFaits.map((travailFait, index) => (
                            <Field component={SaisieTravail} travail={travailFait} index={index} />
                          ))}
                        </Box>

                        <p></p>
                        <Field component={CustomInput1} labD={2} fieldD={10} name="travauxrealises" label={i18n.t('visite.travauxRéalisés')} placeholder={i18n.t('visite.travauxRéalisés')}
                          multiline={true} />
                      </Box>
                    </TabPanel>
                    {/* Travaux à faire ==================================================*/}
                    <TabPanel value={valTab} index={4} className={classes.root}>
                      <Box style={{ overflow: "hidden", overflowY: "scroll",height: heightR}}>

                        <Typography sx={{ color: deepOrange[900], m: 1 }}>Travaux à faire</Typography>
                        <Box style={{ display: 'flex', flexDirection: 'column' }}>
                          {values.travauxAFaire.map((travailAFaire, index) => (
                            <Field component={SaisieTravailAFaire} travail={travailAFaire} index={index} />
                          ))}
                        </Box>
                        <p></p>


                        <Field component={CustomInput1} name="travauxafaire" label={i18n.t('visite.aFaire')} placeholder={i18n.t('visite.aFaire')} multiline={true} />
                      </Box>
                    </TabPanel>

                  </Box>


                  <Typography></Typography>
                  <Box>

                    <Grid container display="flex" justifyContent="flex-end">
                      <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => { dispatch(setAffModVisite(false)); dispatch(setAffCreeVisite(false)) }} >
                          <RestoreIcon sx={{ fontSize: 40, }} />
                        </IconButton>
                      </Tooltip>
                      <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                        <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                      </IconButton>
                      <Tooltip title={<Typography fontSize={20}>Onglet Suivant</Typography>} placement="top" sx={{ fontSize: 200 }}>
                        <IconButton onClick={() => incValTab()} sx={{ color: amber[700] }} >
                          <KeyboardTabIcon sx={{ fontSize: 40, }} />
                        </IconButton>
                      </Tooltip>

                    </Grid>
                  </Box>
                </Box>
              </>
            )}

          </Formik>

        </Box>
      </Dialog>
    </>);
}

//<Button onClick={() => dispatch(toggleAffReine()) } >Annuler</Button>


export default ModVisite;
