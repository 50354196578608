import React, { useState, useEffect, } from 'react';
import { useNavigate, useParams } from "react-router-dom";
import { useLocation } from "react-router-dom";

import { redirect } from "react-router-dom";


import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';



import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';


import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ColorLensOutlined } from '@mui/icons-material';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import CancelIcon from '@mui/icons-material/Cancel';

import { grey, amber, green } from '@mui/material/colors';

//import ListEleveur from '../components/listEleveur'

import { useSelector, useDispatch } from 'react-redux'
import MenuBar from '../../components/MenuBar'
import CustomSwitchHoriz from '../../components/CustomSwitchHoriz';
import CustomPicker from '../../components/CustomPicker';

import AfficheLesColoniesdeApi from '../components/AfficheLesColoniesDeApi';

import { login, retrieveApiculteurTotal, postPasswordReset } from '../../userModule/services/authentification';
import { userStore } from '../../store/userslice';





import { cPickersStore } from '../../store/cPickersslice'
//import { userStore} from '../../store/userslice'
import { lesColoniesDeApiStore, lesColoniesDelegationStore, lesRuchersTravailStore } from '../../store/ruchersslice'
//import { } from '../../store/displayRucherslice'
import { setTitreBar, setAffLogin } from '../../store/displayslice'
import { setAffModColonie, setAffCreeColonie, setAffModRucher, setAffCreeRucher } from '../../store/displayRucherslice';


import i18n from '../services/i18n';

import {
  editStats, editRegistre
} from '../services/accessApiRuchers';

function EditionScreen() {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  //const [ lRuchers , setLRuchers] = useState ([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [open, setOpen] = useState(false);
  const [affMessage, setAffMessage] = useState(false);
  const [titre, setTitre] = useState('');
  const [affBntArchive, setAffBntArchive] = useState(false);



  //const [affLogin, setAffLogin] = useState(false);


  const [rucherLoadingErrorMessage, setRucherLoadingErrorMessage] = useState('');
  const { user } = useSelector(state => state.user);

  var langue = '';


  dispatch(setTitreBar('EDITIONS'));
  let { param } = useParams();
  const location = useLocation();

  const typeEdition = location.state.param;

  useEffect(() => {
    console.log('use effect');
    if (typeEdition == 'stats') {
      setTitre('Edition des statistiques');
      setAffBntArchive(true);
    }
    else {
      setTitre('Edition du registre d"élevage');
      setAffBntArchive(false);

    };
    if (user) {
      langue = user.langage;
    } else {
      //navigate("/reine");
      const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;

      if (token) {
        Promise.all([retrieveApiculteurTotal(),])
          .then(async ([userValues,]) => {
            console.log(userValues);
            dispatch(userStore(userValues));
            //dispatch(apiProprietaireStore (userValues.id));
          })


      }
      else {
        dispatch(setAffLogin(true));
      }
    };
    if (typeof (user) !== 'undefined' && user != null) {
      let mounted = true;


      return () => { mounted = false; };
    }
  }, [user])



  // onSubmit method
  const FormonSubmit = (data) => {
    if (typeEdition == 'stats') {

      editStats(data.date, data.archive);
    }
    else {
      editRegistre(data.date);

    };
    setAffMessage(true);
  };

  const Initialisation = () => {
    const init = new Object();
    let current_date = new Date();
    let annee = current_date.getFullYear();
    init.archive = false;
    init.date = annee.toString();
    return init
  };

  const ValidationSchema = yup.object().shape({
    date: yup
      .string()
      .required()
  });

  const listedate = () => {
    let tabChoix = [];
    let dateDeb = new Date();
    let annee = dateDeb.getFullYear();
    console.log(annee);
    let ind = 0;
    for (let i = 0; i < 3; i++) {
      tabChoix.push({ label: annee.toString(), id: annee });
      annee--;
    };
    console.log(tabChoix);
    return tabChoix
  };

  const handleCloseIgnore = () => {
    setAffMessage(false);
  };


  const handleCancel = () => {
    setAffMessage(false);
    navigate('/');
  };


  const handleClose = () => {
    setAffMessage(false);
    navigate('/');
  };


  return (
    <>
      <MenuBar />
      <Grid2 container>

        <Grid2 xs={8} >


          <Paper elevation={10} sx={{ m:2, bgcolor: amber[100] }}>
            <Typography variant="h4" align="center">
              {titre}              </Typography>
            <Dialog
              open={affMessage}
              keepMounted
              onClose={handleCloseIgnore}
              aria-describedby="alert-dialog-slide-description"
              maxWidth='sm'
              fullWidth

            >
              <Container>
                <DialogTitle>{"Statistiques"}</DialogTitle>
                <DialogContentText id="alert-dialog-slide-description">
                  La demande de stats est partie. Surveillez vos mails et votre dossier "indésirables"
                </DialogContentText>
                <DialogActions>
                  <IconButton
                    onClick={() => { handleClose() }}
                    sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </DialogActions>
              </Container>
            </Dialog>
            
            <Formik
              initialValues={Initialisation()}
              validationSchema={ValidationSchema}
              onSubmit={values => FormonSubmit(values)}
            >
              {({
                handleSubmit, isValid, values, setFieldValue, setFieldTouched,
                errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
              }) => (
                <>
                  <Field component={CustomPicker}
                    label={i18n.t('registre.date')}
                    liste={listedate()} NomListObjet='label' itemKey='value'
                    name="date"
                    langue={i18n.locale}
                    selectedValue={values.date}
                    handleChange={(itemIndex) => handleChange('date')(itemIndex)}
                    labD={3} fieldD={1}
                  />
                  {affBntArchive &&
                    <Field component={CustomSwitchHoriz} label='Inclure ruches archivées' 
                    value={values.archive} name='archive' labD={3} fieldD={1}/>
                  }
                  <IconButton
                    onClick={() => { handleSubmit() }}
                    sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                  <IconButton
                    onClick={() => { handleCancel() }}
                    sx={{ color: green[700] }} >
                    <CancelIcon sx={{ fontSize: 40 }} />
                  </IconButton>

                </>
              )}
            </Formik>

          </Paper>

        </Grid2>
      </Grid2>


    </>
  );
}


export default EditionScreen;


