import React from 'react'

import { useDispatch, useSelector } from 'react-redux'

import { Container, Row, Col, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid2 from '@mui/material/Unstable_Grid2';
import Paper from '@mui/material/Paper';

import { grey, amber, green } from '@mui/material/colors';
import CardContent from '@mui/material/CardContent';


import i18n from '../services/i18n';
import { dispDate } from '../services/prepDate';
import { bSante } from '../services/bSante';

import CustomTextStd2 from '../../components/CustomTextStd2';
import CustomTextStd from '../../components/CustomTextStd';
import { styles } from '../../components/Styles';



const ResumeColonie = (props) => {
   const {
      colonie, langue,
      ...inputProps
   } = props

   const { cPickers } = useSelector(state => state.cPicker);
   const contenuPickers = cPickers;
   //console.log(Colonie);

   const typeRuche = (colonie) => {
      const lacolo = contenuPickers.typRuc.find(typr => typr.id === colonie.typeruche);
      if ((typeof (lacolo) != "undefined")) { return lacolo.modeleruche };
      return '';
   };

   const libMarquage = (colonie) => {
      const laMarque = contenuPickers.listMarc.find(mar => mar.id === colonie.marquage);
      if (typeof (laMarque) != "undefined") { return laMarque.couleur };
      return '';
   };

   const libRace = (colonie) => {
      if (colonie.race != 'null') {
         const laRace = contenuPickers.listRac.find(rac => rac.id === colonie.race);
         if (typeof (laRace) != "undefined") { return laRace.race };
      }
      return '';
   };

   const libFiliation = (colonie) => {
      if ((typeof contenuPickers.optCol.actions != "undefined") && (typeof colonie != "undefined")) {
         const nom = contenuPickers.optCol.actions.PUT.filiation.choices.find(fil => fil.value == colonie.filiation);
         if (typeof nom != "undefined") {
            let dispname = nom.display_name;
            return dispname
         };
      }
      return '';
   };


   return (
      <>

         <Box style={styles.titreCard}>
            <Typography sx={styles.TextTitreCard}>{colonie.nom} - à - {colonie.nomRucher} </Typography>
         </Box>
         <p></p>
         <Grid2 sx={{ display: 'flex', flexDirection: 'row'}} container >
            <Grid2 sx={{ display: 'flex', flexDirection: 'column'}} xs={6}>
               <Paper>

                  <CustomTextStd2 label={i18n.t('colonie.format')} contenu={typeRuche(colonie)} />
                  <CustomTextStd2 label={i18n.t('colonie.marquage')} contenu={libMarquage(colonie)} />
                  <CustomTextStd2 label={i18n.t('colonie.filiation')} contenu={libFiliation(colonie)} />
                  <CustomTextStd2 label={i18n.t('colonie.nomreine')} contenu={colonie.nomreine} />
                  <CustomTextStd2 label={i18n.t('colonie.orig reine')} contenu={colonie.originereine} />
               </Paper>
            </Grid2>
            <Grid2 sx={{ display: 'flex', flexDirection: 'column' }} xs={6}>
               <Paper>
                  <CustomTextStd2 label={i18n.t('colonie.enruchement')} contenu={dispDate(colonie.date, langue)} />
                  <CustomTextStd2 label={i18n.t('colonie.race')} contenu={libRace(colonie)} />
                  <CustomTextStd2 label={i18n.t('colonie.numreine')} contenu={colonie.numreine} />
                  <CustomTextStd2 label={i18n.t('colonie.fécondation')} contenu={colonie.fecondationreine} />
                  <CustomTextStd2 label={i18n.t('colonie.mère')} contenu={colonie.ancetrereine} />

               </Paper>
            </Grid2>
            {(colonie.motifsuppression ?
                     <Box style={{ flexDirection: 'row' }}>
                        <Typography style={{ flex: 2, color: 'red' }}>{colonie.motifsuppression}</Typography>
                        <Typography style={{ flex: 2, color: 'grey' }}>{colonie.datesuppression}</Typography>
                     </Box>
                     : null)}
         </Grid2>



      </>
   )

}

export default ResumeColonie;
