import React, { useState } from 'react'
import { Container, Row, Col, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

import { grey, amber, red } from '@mui/material/colors';

import CustomInputSsLabel from '../../components/CustomInputSsLabel';
import CustomToggleButton from '../../components/CustomToggleButton';

import i18n from '../services/i18n';


const SaisieTravailAFaire = (props) => {
  const {
    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched, setFieldValue }, label, labD = 4, fieldD = 8, required = false, index,
    travail: { nomFaire, nomDefaire, nomCommentaire, fait, deFait, aNomDefaire, aCommentaire },
    ...inputProps
  } = props

  //const [trav, setTral] = useState(fait, deFait);

  const hasError = errors[name] && touched[name]
  //console.log(props);
  //console.log(value.travauxFaits[index].fait);
  // value - selection - label
  return (
    <>
      {/*    <View style={styles.container}> */}
      <ToggleButtonGroup aria-label="text formatting" >
        <Box sx={{ display: 'flex', flexDirection: 'row', width: 1, alignItems: 'center', justifyContent: 'space-between' }}>
          <Box sx={{ flex: 3 }}>
            <ToggleButton sx={{
              width: 1, "&.MuiToggleButton-root.Mui-selected": {
                backgroundColor: amber[600]
              }, "&.MuiToggleButton-root.Mui-selected.MuiSvgIcon-root": {
                color: "red"
              }
            }} value={fait} selected={value.travauxAFaire[index].fait}
            size='small'
              onClick={(val) => {
                value.travauxAFaire[index].fait = !value.travauxAFaire[index].fait;
                setFieldValue('fait', value.travauxAFaire[index].fait)
              }
              }>{nomFaire}
            </ToggleButton>
          </Box>
          <Box sx={{ flex: 3,}}>
            {aNomDefaire && <ToggleButton sx={{
              width: 1, "&.MuiToggleButton-root.Mui-selected": {backgroundColor: amber[600]},
               "&.MuiToggleButton-root.Mui-selected.MuiSvgIcon-root": {color: "red"} }}
              value={deFait} selected={value.travauxAFaire[index].deFait}
              size='small'

              onClick={(val) => {
                value.travauxAFaire[index].deFait = !value.travauxAFaire[index].deFait;
                setFieldValue('deFait', value.travauxAFaire[index].deFait)
              }}
            >{nomDefaire}
            </ToggleButton> }
          </Box>
          <Box sx={{ flex: 4 }}>
            {(aCommentaire) ?
              <Field labD={3} fieldD={9} component={CustomInputSsLabel} name={'travauxAFaire[' + index + '].commentaire'}
                label={nomCommentaire} placeholder={nomCommentaire} /> : <p></p>
            }
          </Box>
          {(value.travauxAFaire[index].fait || value.travauxAFaire[index].deFait) ?

            <Box sx={{ display: 'flex', flexDirection: 'row', flex: 4 }}>

              <Box sx={{ flex: 3 }}>
                <Typography >&nbsp; A faire le &nbsp;</Typography>
              </Box>
              <Box sx={{ flex: 4 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                  <DesktopDatePicker
                    value={value.travauxAFaire[index].dateS}
                    onChange={(newValue) => {
                      setFieldValue('travauxAFaire[' + index + '].dateS', newValue || value.travauxAFaire[index].dateS);
                    }}
                    renderInput={(params) =>
                      <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                  />
                </LocalizationProvider>
              </Box>
            </Box> :
            <Box sx={{ display: 'flex', flexDirection: 'row', flex: 4 }}></Box>}



        </Box>
      </ToggleButtonGroup>

    </>
  )
}

let styles = {
  textInput: {
    height: 25,
    width: '100%',
    margin: 0,
    backgroundColor: 'white',
    borderColor: amber[800],
    borderWidth: 1,
    borderRadius: 5,
    textAlignVertical: 'top',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  label: {
    flex: 1
  },

  errorText: {
    fontSize: 10,
    color: 'red',
  },
  errorInput: {
    borderColor: 'red',
  }
}


export default SaisieTravailAFaire;
