import React from 'react'
import { Container, Row, Col, ButtonGroup, Dropdown } from "react-bootstrap";
//import Select from "react-select";
/* import {
  Select,
  InputLabel,
  MenuItem,
  FormControl,
  Button
} from "@material-ui/core"; */
import Box from '@mui/material/Box';
import Grid from '@mui/material/Unstable_Grid2';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import Typography from '@mui/material/Typography';


import { grey, amber } from '@mui/material/colors';


//import i18n from '../rucherModule/services/i18n';



const CustomPicker = (props) => {
  //  console.log(props);
  const {

    field: { name, onBlur, onChange, value },
    form: { errors, touched, setFieldTouched, setFieldValue },

    liste, ModeModif, label, itemLabel, itemKey, langue, selectedValue,
    handleChange, labD = 4, fieldD = 8, NomListObjet = "nom", required = false, ...inputProps
  } = props
  //    if (langue) {i18n.locale = langue;};

  const hasError = errors[name] && touched[name];
  //    console.log(props);
  //console.log(liste);

  //                <Field component={CustomInput} name="nom" placeholder="nom colonie" />

  //  const hasError = errors[name] && touched[name]
  /*
  const construitListe =  (liste, itemLabel, itemKey) => {
      const items = [];
  
        console.log('liste custm picker');
      for (let iter of liste) {
          console.log(iter[itemLabel]+ ' ' + iter[itemKey]);
          
          items.push({label:iter[itemLabel], value:''+iter[itemKey] }); }
  //        items.push({label:iter[itemLabel], value:''+iter[itemKey], key: ''+iter[itemKey]}); }
  //        console.log(items);
      return items };
  */
  return (
    <>
      <Box>

        <Grid container display="flex" alignItems="center">

          <Grid xs={labD} sx={{ pt: 1, pl: 1, color: grey[600] }} display="flex">
            {label && <><Typography>{label} </Typography>
              {required && <Typography color={amber[900]}>&nbsp;*</Typography>}</>}
          </Grid>
          <Grid xs={fieldD}>

            <FormControl variant="standard" fullWidth sx={{ m: 0, minWidth: 40, bgcolor: 'white' }} size="small" margin="dense">

              <Select
                name={name}
                value={selectedValue}
                onChange={(itemvalue) => handleChange(itemvalue)}
              >

                {liste.map((option) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option[NomListObjet]}
                  </MenuItem>))
                }
              </Select>
            </FormControl>
          </Grid>
          {hasError && <p style={styles.errorText}>{errors[name]}</p>}

        </Grid>
      </Box>
    </>
  )
}
//                      {(ModeModif == false) && <Picker.Item color='red' key={0} label="à sélectionner" value={'0'} />}
export default CustomPicker


let styles = {
  textInput: {
    height: 30,
    width: '95%',
    margin: 5,
    backgroundColor: 'white',
    borderColor: 'gray',
    borderWidth: 1,
    borderRadius: 5,
    textAlignVertical: 'top',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  label: {
    flex: 1
  },

  errorText: {
    fontSize: 18,
    color: 'red',
  },
  errorInput: {
    borderColor: 'red',
  }
}

//options={construitListe(liste, itemLabel, itemKey)}

/*
                <Select
                  placeholder={{label:"à sélectionner", value: '-1', key: null, color: 'red'}}
                  value={selectedValue}
                  onChange={(itemValue, itemIndex) =>  {handleChange(itemValue)}}
                  
                  isSearchable={true}
                  options={options}
                  name="year"
                  isLoading={false}
                  loadingMessage={() => "Fetching year"}
                  noOptionsMessage={() => "Year appears here"}
                />
*/
/*
<Select
                 value={selectedValue}
                 options={options}
                 name={name}
                 isLoading={false}
                 loadingMessage={() => "Fetching year"}
                 noOptionsMessage={() => "Year appears here"}
               />

*/