import React, { useState, useEffect, useMemo, useLayoutEffect } from 'react';
import PropTypes from 'prop-types';

import { getDetColonie } from '../services/accessApiRuchers';
import { useDispatch, useSelector } from 'react-redux'
import Paper from '@mui/material/Paper';

import {
  DataGrid, GridActionsCellItem, GridRowModes, GridToolbarContainer, frFR,
  GridRowsProp, GridColDef, useGridApiContext
} from '@mui/x-data-grid';
import DeleteIcon from '@mui/icons-material/DeleteForeverOutlined';
import AddIcon from '@mui/icons-material/Add';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Close';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import CancelPresentationIcon from '@mui/icons-material/CancelPresentation';

import EditIcon from '@mui/icons-material/Edit';
import { red, amber, green, grey } from '@mui/material/colors';

import Dialog from '@mui/material/Dialog';
import Modal from '@mui/material/Modal';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2'; // Grid version 2
import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';

import CloseIcon from '@mui/icons-material/Close';


import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import SearchIcon from '@mui/icons-material/Search';


import { randomId } from '@mui/x-data-grid-generator';

import i18n from '../services/i18n';


import CustomTextStd from '../../components/CustomTextStd';
import CustomTitre from '../../components/CustomTitre';
import CustomTextStd2 from '../../components/CustomTextStd2';
import ResumeObservation from './ResumeObservation';


// import {lesColoniesDeApiStore, setaffReine, setmodReine, setAffReine} from '../../store/rucherdslice'
import { setAffColonie, setAffObservation, setAffModObservation, setAffCreeObservation, setAffDelObservation, setAffLesObservations } from '../../store/displayRucherslice'
import { laColonieStore, lObservationStore } from '../../store/ruchersslice'
import { Divider } from '@mui/material';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 800,
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};
// pas de edit toolbar

function ListObservations() {

  const dispatch = useDispatch()

  const [allFetch, setAllFetch] = useState(true);
  const { affLesObservations } = useSelector(state => state.displayRucher);

  const { lesObservations, laColonie } = useSelector(state => state.ruchers);
  console.log(lesObservations)
  console.log(laColonie);
  const [rows, setRows] = useState();
  const [rowModesModel, setRowModesModel] = React.useState({});
  const { user } = useSelector(state => state.user);

  const langue = user.langage;

  const [messageErreur, setMessageErreur] = useState();





  const handleEditClick = (locObservation) => {
    console.log('handle click')

    dispatch(lObservationStore(locObservation));
    dispatch(setAffModObservation(true));
  };

  const handleDelClick = (locObservation) => {
    console.log('handle click')

    dispatch(lObservationStore(locObservation));
    dispatch(setAffDelObservation(true));
  };

  const handleLoadingError = (res) => {
    console.log('erreur');
    console.log(res);
  };

  const handleAfficheClick = (locObservation) => {
    console.log(locObservation);
    console.log('handle click')
    dispatch(lObservationStore(locObservation));
    dispatch(setAffObservation(true));

  };









  return (

    <>
      {allFetch && (

        <Modal open={affLesObservations} >
          <Container>
            <Box sx={style}>
              <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                <Box sx={{ flex: 9 }}>
                  <CustomTitre titre={i18n.t('observation.lesObservations') + ' - ' + laColonie.nom} />
                </Box>
                <Box sx={{ flex: 1 }}>
                  <IconButton onClick={() => { dispatch(setAffLesObservations(false)) }} >
                    <CancelPresentationIcon sx={{ fontSize: 30, color: grey[900] }} />
                  </IconButton>
                </Box>
              </Box>
              <Divider />
              <Box sx={{
                display: "flex",
                flexDirection: "column",
                height: 400, overflow: "hidden", overflowY: "scroll",
              }}

              >
                {lesObservations.map((observationL) => (

                  <>
                    <Box sx={{ m: 1 }} key={observationL.id}>
                      <Box style={{ flexDirection: 'row', flex: 0, display: 'flex' }}>
                        <Box style={{ flex: 11, flexDirection: 'column' }}>

                          <Card variant="outlined" sx={{ backgroundColor: amber[50], borderColor: amber[800], border: 1, borderRadius: '10px', }}
                            onClick={() => { handleAfficheClick(observationL) }}
                          >
                            <CardActionArea >
                              <CardContent>

                                <ResumeObservation observation={observationL} langue={langue} />
                              </CardContent>
                            </CardActionArea>
                          </Card>
                        </Box>

                        <Box style={{ flex: 1, flexDirection: 'row' }}>
                          {laColonie.motifsuppression == null &&
                            <>
                              <Tooltip title={<Typography fontSize={20}>Modifier</Typography>} placement="top" sx={{ fontSize: 200 }}>
                                <IconButton onClick={() => { handleEditClick(observationL) }} sx={{ color: green[700] }} >
                                  <EditIcon sx={{ fontSize: 30 }} />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={<Typography fontSize={20}>Supprimer</Typography>} placement="top" sx={{ fontSize: 200 }}>
                                <IconButton onClick={() => { handleDelClick(observationL) }} sx={{ color: red[700] }} >
                                  <DeleteIcon sx={{ fontSize: 30 }} />
                                </IconButton>
                              </Tooltip>
                            </>}
                        </Box>
                      </Box>

                    </Box>
                  </>
                ))}
              </Box>
              <Box style={{ flexDirection: 'row', flex: 0, display: 'flex', justifyContent: 'flex-end' }}>

                <Tooltip title={<Typography fontSize={20}>Fermer</Typography>} placement="top" sx={{ fontSize: 200 }}>
                  <IconButton onClick={() => { dispatch(setAffLesObservations(false)) }} >
                    <CloseIcon sx={{ fontSize: 30, }} />
                  </IconButton>
                </Tooltip>
                {messageErreur ? <p>{messageErreur}</p> : null}
              </Box>
            </Box>
          </Container>
        </Modal>
      )}
    </>
  )
};
// <Button type="submit" onClick={() => {dispatch(toggleAffReine());dispatch(setAffReine(true)) }} >Modifier</Button>

export default ListObservations;