import React, { useState } from 'react'
import { Container, Row, Col, Button, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import { borders } from '@mui/system';
import Grid from '@mui/material/Unstable_Grid2';
import Typography from '@mui/material/Typography';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import { grey, amber, deepOrange } from '@mui/material/colors';
import { prepDate, dispDate } from '../../services/prepDate';



const AfficheTravailAFaire = (props) => {
  const {

    travail: { nomFaire, nomDefaire, nomCommentaire, fait, deFait, aNomDefaire, aCommentaire, faire, deFaire, commentaire, dateT },
    ...inputProps
  } = props

  return (
    <>
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        {(faire) ? (
          <Box border={1} borderRadius={1} sx={{ flex: 2, borderColor: deepOrange[700] }}>
            <Typography align='center' sx={{ m: 0, color: deepOrange[900] }}>{nomFaire}</Typography>
          </Box>
        ) : <Box sx={{ flex: 2 }}></Box>}
        {(deFaire) ? (
          <Box border={1} borderRadius={1} sx={{ flex: 2, borderColor: deepOrange[700] }}>
            <Typography align='center' sx={{ m: 0, color: deepOrange[900] }}>{nomDefaire}</Typography>

          </Box>
        ) : <Box sx={{ flex: 2 }}></Box>}
        {(aCommentaire) ? (
          <Box sx={{ flex: 2}}>
            <Typography align='left' sx={{ m: 0, color: deepOrange[900] }}>&nbsp; {nomCommentaire + ' ' + commentaire}</Typography>

          </Box>
        ) : <Box sx={{ flex: 2 }}></Box>}
        {(dateT) ? (
          <Box sx={{ flex: 2 }}>
            <Typography align='left' sx={{ m: 0, color: deepOrange[900] }}>à faire le {dispDate(dateT)}</Typography>

          </Box>
        ) : <Box sx={{ flex: 2 }}></Box>}


      </Box>
    </>
  )
}

let styles = {
  textInput: {
    height: 25,
    width: '100%',
    margin: 0,
    backgroundColor: 'white',
    borderColor: amber[800],
    borderWidth: 1,
    borderRadius: 5,
    textAlignVertical: 'top',
  },
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  label: {
    flex: 1
  },

  errorText: {
    fontSize: 10,
    color: 'red',
  },
  errorInput: {
    borderColor: 'red',
  }
}


export default AfficheTravailAFaire;
