import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import { grey, amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';




import { useDispatch, useSelector } from 'react-redux'
import { reineStore } from '../../store/reineslice'
import { treeStore } from '../../store/treeslice'

//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';
import CustomInput1 from '../../components/CustomInput1';
import CustomInput2 from '../../components/CustomInput2';
import CustomButton from '../../components/CustomButton';


import { setAffModRecolte, setAffCreeRecolte, listColoniesdeApi } from '../../store/displayRucherslice';
import { laRecolteStore, laDerniereRecolteStore, laRecolteRevoke, lesRecoltesStore } from '../../store/ruchersslice'

import { geoConicConformalRaw } from 'd3';
import { Construction } from '@mui/icons-material';


//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';
import { prepDate, dispDate } from '../../services/prepDate';
import dayjs from 'dayjs';
import i18n from '../../journalModule/services/i18n';

import { setAffModEntree, setAffCreeEntree } from '../../store/displayJournalslice';
import {
  leThemeStore, leThemeRevoke, lesThemesStore, lesThemesRevoke,
  lEntreeStore, lEntreeRevoke, lesEntreesStore, lesEntreesRevoke,

} from '../../store/journalslice';
import { modifyEntree, createEntree, getListEntree } from '../services/accessApiJournaux';


//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function ModEntree(props) {
  const {
    ...inputProps
  } = props

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(true);
  const Moment = require('moment');
  const { user } = useSelector(state => state.user);


  const theme = useSelector(state => state.journal).leTheme;
  const entree = useSelector(state => state.journal).lEntree;

  var modeModif = false;
  modeModif = useSelector(state => state.displayJournal).affModEntree;


  const Initialisation = () => {
    const init = new Object();
    if (modeModif) {
      init.ObjetEntree = entree.ObjetEntree;
      init.dateS = prepDate(entree.DateEntree);
      init.TexteEntree = entree.TexteEntree;
    }
    else {
      init.ObjetEntree = "";
      init.dateS = new Date();
      init.TexteEntree = "";
    }
    console.log(init);
    return init

  };

  const ValidationSchema = yup.object().shape({
    ObjetEntree: yup
      .string()
      .min(1)
      .max(100, 'trop long')
      .required('Objet obligatoire'),
  });


  const FormonSubmit = (data) => {
    console.log('on submit ==============================');
    let mEntree = {}
    mEntree.ObjetEntree = data.ObjetEntree;
    const Moment = require('moment');
    mEntree.DateEntree = Moment(data.dateS).format('YYYY-MM-DD');
    mEntree.TexteEntree = data.TexteEntree;
    mEntree.idTheme = theme.id;
    console.log(modeModif);
    if (modeModif) {
      console.log('avt MAJ');
      console.log(mEntree);
      mEntree.id = entree.id;
      modifyEntree(mEntree)
        .then((lesEntrees) => {
          getListEntree(theme.id)
            .then((lesEntrees) => {
              dispatch(setAffModEntree(false));
              dispatch(lesEntreesStore(lesEntrees));
              dispatch(lEntreeStore(mEntree));
            })
        })
    } else {
      createEntree(mEntree)
        .then(() => {
          getListEntree(theme.id)
            .then((lesEntrees) => {
              dispatch(setAffCreeEntree(false));
              dispatch(lesEntreesStore(lesEntrees));

              dispatch(lEntreeStore(mEntree));
            })

        })


    };

    // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher})});
  };



  return (
    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Box sx={{
          width: 500, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2,
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>



                <Typography sx={{ fontSize: 30, }} align='center'>        {(modeModif) ? 'Modifier Entree' :
                  'Créer Entrée'}
                </Typography>
                <Divider />
                <p></p>
                <Field component={CustomInput} name="ObjetEntree" label={i18n.t('entree.ObjetEntree')} placeholder={i18n.t('entree.ObjetEntree')}
                  required={true} multiline={true} numberOfLines={2} />
                <Grid container display="flex" alignItems="center">

                  <Grid xs={4} sx={{ pt: 1, pl: 1, color: grey[600] }} display="flex">
                    <Typography>{i18n.t('entree.date')}</Typography>
                    <Typography color={amber[900]}>&nbsp;*</Typography>

                  </Grid>
                  <Grid xs={8} sx={{ pt: 1, pl: 1, color: grey[600] }} display="flex">
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                      <DesktopDatePicker
                        value={values.dateS}
                        onChange={(newValue) => { setFieldValue("dateS", newValue || values.dateS); }}
                        renderInput={(params) =>
                          <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                </Grid>

                <Field component={CustomInput1} name="TexteEntree" label={i18n.t('entree.texte')}
                  placeholder={i18n.t('entree.texte')} numberOfLines={12} />







                <Grid style={{ flex: 1 }}></Grid>


                <Grid container display="flex" justifyContent="flex-end">
                  <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffModEntree(false)); dispatch(setAffCreeEntree(false)) }} >
                      <RestoreIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
              </>
            )}

          </Formik>

        </Box>
      </Modal>
    </>);
}

//<Button onClick={() => dispatch(toggleAffReine()) } >Annuler</Button>


export default ModEntree;
