import React, { useState, useEffect, } from 'react';
import { useNavigate } from "react-router-dom";
import { redirect } from "react-router-dom";


import Grid2 from '@mui/material/Unstable_Grid2'; // Grid version 2
import Paper from '@mui/material/Paper';
import { ColorLensOutlined } from '@mui/icons-material';
import { grey, amber, green } from '@mui/material/colors';

//import ListEleveur from '../components/listEleveur'

import { useSelector, useDispatch } from 'react-redux'
import MenuBar from '../../components/MenuBar'
import AfficheLesColoniesdeApi from '../components/AfficheLesColoniesDeApi';
import AfficheColonie from '../components/AfficheColonie';
import ModColonie from '../components/ModColonie';
import DelColonie from '../components/DelColonie';
import ModDelColonie from '../components/ModDelColonie';
import ModRucher from '../components/ModRucher';
import ModVisite from '../components/ModVisite';
import DelVisite from '../components/DelVisite';
import ListVisites from '../components/ListVisites';
import AfficheVisite from '../components/AfficheVisite';
import ListSoins from '../components/ListSoins';
import ModSoin from '../components/ModSoin';
import DelSoin from '../components/DelSoin';
import ListComptages from '../components/ListComptages';
import ModComptage from '../components/ModComptage';
import DelComptage from '../components/DelComptage';
import ListObservations from '../components/ListObservations';
import ModObservation from '../components/ModObservation';
import DelObservation from '../components/DelObservation';
import ListRecoltes from '../components/ListRecoltes';
import ModRecolte from '../components/ModRecolte';
import DelRecolte from '../components/DelRecolte';
import { login, retrieveApiculteurTotal, postPasswordReset } from '../../userModule/services/authentification';
import { userStore } from '../../store/userslice';





import { cPickersStore } from '../../store/cPickersslice'
//import { userStore} from '../../store/userslice'
import { lesColoniesDeApiStore, lesColoniesDelegationStore, lesRuchersTravailStore } from '../../store/ruchersslice'
//import { } from '../../store/displayRucherslice'
import { setTitreBar, setAffLogin } from '../../store/displayslice'
import { setAffModColonie, setAffCreeColonie, setAffModRucher, setAffCreeRucher } from '../../store/displayRucherslice';


import i18n from '../services/i18n';

import {
  getListRuchers, getDetColonie, getListTypeRuches, getListMarquage, getListRace, getOptionsColonie,
  getListTypPb, getListTypCR, getListTypMedoc, getListUniNourri, getOptionsVisite, getFirstVisite,
  getFirstColonie, listColoniesdeApi, getListRuchersDeApi, listColoniesDelegation, getListTravaux,
  getListTypNourri, getListTypComptage
} from '../services/accessApiRuchers';

function ColoniesScreen() {
  const dispatch = useDispatch()
  const navigate = useNavigate();

  //const [ lRuchers , setLRuchers] = useState ([]);
  const [allFetch, setAllFetch] = useState();
  const { affColonie } = useSelector(state => state.displayRucher)
  const { affModColonie } = useSelector(state => state.displayRucher)
  const { affCreeColonie, affDelColonie, affModDelColonie } = useSelector(state => state.displayRucher)
  const { affRucher, affModRucher, affCreeRucher } = useSelector(state => state.displayRucher)
  const { affVisite, affModVisite, affCreeVisite, affDelVisite, affLesVisites } = useSelector(state => state.displayRucher)
  const { affModSoin, affCreeSoin, affLesSoins, affDelSoin } = useSelector(state => state.displayRucher)
  const { affModComptage, affCreeComptage, affLesComptages, affDelComptage } = useSelector(state => state.displayRucher)
  const { affModObservation, affCreeObservation, affLesObservations, affDelObservation } = useSelector(state => state.displayRucher)
  const { affModRecolte, affCreeRecolte, affLesRecoltes, affDelRecolte } = useSelector(state => state.displayRucher)
  const { titreBar, affGenealogie, affLogin } = useSelector(state => state.display)
  


  //const [affLogin, setAffLogin] = useState(false);

  console.log(affCreeColonie);

  const [rucherLoadingErrorMessage, setRucherLoadingErrorMessage] = useState('');
  const { user } = useSelector(state => state.user);

  var langue = '';


  dispatch(setTitreBar('COLONIES'));


  const handleRucherLoadingError = (res) => {
    if (res.error === 401) {
      setRucherLoadingErrorMessage("Connectez vous pour accéder à cette fonction");
    } else {
      setRucherLoadingErrorMessage(res.message);
    };
  };

  useEffect(() => {
    console.log('use effect');
    if (user) {
      langue = user.langage;
    } else {
      //navigate("/reine");
      const token = localStorage.getItem('token') ? JSON.parse(localStorage.getItem('token')) : null;

      if (token) {
        Promise.all([retrieveApiculteurTotal(),])
          .then(async ([userValues, ]) => {
            console.log(userValues);
            dispatch(userStore(userValues));
            //dispatch(apiProprietaireStore (userValues.id));
          })


      }
      else {
        dispatch(setAffLogin(true));
      }
    };
    if (typeof (user) !== 'undefined' && user != null) {
      dispatch(setAffModColonie(false))
      dispatch(setAffCreeColonie(false))

      let mounted = true;
      let itemApi = user.id;
      setAllFetch(false);
      Promise.all([getFirstColonie(), getFirstVisite()])
        .then(async ([itemCol, itemVis]) => {
          if (mounted) {
            let idVisite = itemVis.id; let idColonie = itemCol.id;;

            Promise.all([getOptionsColonie(JSON.stringify(idColonie)), getListRace(), getListMarquage(langue),
            getListTypeRuches(), getListTypPb(langue), getListTypCR(langue), getListTypMedoc(), getListUniNourri(langue),
            getOptionsVisite(JSON.stringify(idVisite)), listColoniesdeApi(itemApi), getListRuchersDeApi(itemApi),
            listColoniesDelegation(itemApi), getListTravaux(langue), getListTypNourri(), getListTypComptage()])
              .then(async ([itemOptCol, itemsTypRa, itemsMar, itemsTypRu, itemsTypPb, itemsTypCR, itemsTypMedoc,
                itemsUniNourri, itemOptVis, coloniesdeApi, ruchersDeApi, coloniesDelegation, itemTravaux, itemsTypNourri,
                itemTypComptage
              ]) => {
                { if (mounted) { i18n.locale = langue } };
                //{if(mounted) {setLRuchers(intemRuchers);}};
                { if (mounted) { setAllFetch(true) } };
                {
                  if (mounted) {
                    //             console.log(itemOptVis);
                    //           console.log(itemsMar);

                    var cPickers = {
                      optCol: itemOptCol, listRac: itemsTypRa, listMarc: itemsMar, typRuc: itemsTypRu,
                      TypPb: itemsTypPb, TypCR: itemsTypCR, listMedoc: itemsTypMedoc, listUniNourri: itemsUniNourri,
                      optVis: itemOptVis, apiculteur: user, langue: langue, delegue: false, listTravaux: itemTravaux,
                      listNourri: itemsTypNourri, listTypComptage: itemTypComptage
                    };
                    //            var cPickers = {optCol: optionsColonie, listRac: listRace, listMarc: listMarquage, typRuc: listTypeRuches};

                    //console.log(cPickers);
                    console.log(coloniesdeApi);
                    dispatch(cPickersStore(cPickers));
                    dispatch(lesColoniesDeApiStore(coloniesdeApi));
                    dispatch(lesColoniesDelegationStore(coloniesDelegation));
                    //dispatch(lesRuchersTravailStore(ruchersDeApi));


                  }
                }
              })
          }
        })
        .catch(handleRucherLoadingError);

      return () => { mounted = false; setAllFetch(false); };
    }
  }, [user])



  return (
    <>
      <MenuBar />
      <Grid2 container>

        <Grid2 xs={4} >

          {allFetch && <AfficheLesColoniesdeApi />}
        </Grid2>
        <Grid2 xs={8} >
          <Paper elevation={10} sx={{ bgcolor: amber[100] }}>

            {affCreeRucher && <ModRucher />}
            {affModRucher && <ModRucher />}


            {affColonie && <AfficheColonie />}
            {affCreeColonie && <ModColonie />}
            {affModColonie && <ModColonie />}
            {affDelColonie && <DelColonie />}
            {affModDelColonie && <ModDelColonie />}

            {affCreeVisite && <ModVisite />}
            {affModVisite && <ModVisite />}
            {affDelVisite && <DelVisite />}
            {affVisite && <AfficheVisite />}
            {affLesVisites && <ListVisites />}

            {affLesObservations && <ListObservations />
            }
            {affCreeObservation && <ModObservation />}
            {affDelObservation && <DelObservation />}
            {affModObservation && <ModObservation />}

            {affLesSoins && <ListSoins />}
            {affCreeSoin && <ModSoin />}
            {affDelSoin && <DelSoin />}
            {affModSoin && <ModSoin />}

            {affLesComptages && <ListComptages />}
            {affCreeComptage && <ModComptage />}
            {affDelComptage && <DelComptage />}
            {affModComptage && <ModComptage />}

            {affLesRecoltes && <ListRecoltes />}
            {affCreeRecolte && <ModRecolte />}
            {affDelRecolte && <DelRecolte />}
            {affModRecolte && <ModRecolte />}
          </Paper>

        </Grid2>
      </Grid2>


    </>
  );
}


export default ColoniesScreen;


