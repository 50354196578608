import React, { useState, useEffect, useLayoutEffect, useMemo, useRef } from 'react';
import { useNavigate } from "react-router-dom";

import {
  Formik, Field, Form,
  handleTextInput,
  withNextInputAutoFocusForm,
  withNextInputAutoFocusInput,
  withPickerValues
} from 'formik';
import * as yup from 'yup';

import { Container, ButtonGroup, Dropdown } from "react-bootstrap";
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import FormControl from '@mui/material/FormControl';
import Grid from '@mui/material/Unstable_Grid2';
import Stack from '@mui/material/Stack';

import { grey, amber, green } from '@mui/material/colors';
import IconButton from '@mui/material/IconButton';
import RestoreIcon from '@mui/icons-material/Restore';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import Divider from '@mui/material/Divider';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';

import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import 'dayjs/locale/fr';


import { } from '../services/accessApiRuchers';
import { getListRace } from '../services/accessApiRuchers';
import { modifyComptage, createComptage, getLastComptage, getListComptage } from '../services/accessApiRuchers';


import { useDispatch, useSelector } from 'react-redux'
import { reineStore } from '../../store/reineslice'
import { treeStore } from '../../store/treeslice'

//import { setToken } from '../services/token';
//import { setLangue, getLangue } from '../../rucherModule/services/getLangue';

import CustomInput from '../../components/CustomInput';
import CustomPicker from '../../components/CustomPicker';
import CustomSwitch from '../../components/CustomSwitch';
import CustomInput1 from '../../components/CustomInput1';
import CustomInput2 from '../../components/CustomInput2';
import CustomButton from '../../components/CustomButton';


import { setAffModComptage, setAffCreeComptage, listColoniesdeApi } from '../../store/displayRucherslice';
import { leComptageStore, leComptageRevoke, leDernierComptageStore, lesComptagesStore } from '../../store/ruchersslice'

import { geoConicConformalRaw } from 'd3';
import { Construction } from '@mui/icons-material';


//import CustomButton from '../../components/CustomButton';
//import MyModal from '../../components/MyModal';
//import { headerStyles } from '../../components/Styles';
import i18n from '../services/i18n';
import { prepDate, dispDate } from '../../services/prepDate';
import dayjs from 'dayjs';

//import i18n from '../../rucherModule/services/i18n';
//import {setIdApplication, getIdApplication} from '../../services/splash';
//import { version } from '../../secrets';
const style = {
  bgcolor: amber[100],
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
};

function ModComptage(props) {
  const {
    ...inputProps
  } = props

  const dispatch = useDispatch()
  const [visible, setVisible] = useState(true);
  const Moment = require('moment');
  const { cPickers } = useSelector(state => state.cPicker);

  let contenuPickers = Object.assign({}, cPickers);

  const comptage = useSelector(state => state.ruchers).leComptage;
  console.log(comptage);
  console.log();
  const colonie = useSelector(state => state.ruchers).laColonie;
  var modeModif = false;
  var modeDuplic = false;
  modeModif = useSelector(state => state.displayRucher).affModComptage;

  const referenceLibelle = (comptage) => {
    const laMethode = contenuPickers.listTypComptage.find(item => item.id === comptage);
    if (typeof (laMethode) != "undefined") { return laMethode.libReference };
    return false;
  };

  const Initialisation = () => {
    console.log('init');
    const init = new Object();

    if (modeModif) {
      init.dateS = prepDate(comptage.date);
      init.methode = comptage.methode;
      { comptage.nombreabeilles ? init.nombreabeilles = '' + comptage.nombreabeilles : init.nombreabeilles = '' };
      { comptage.nombrevarroas ? init.nombrevarroas = '' + comptage.nombrevarroas : init.nombrevarroas = '' };
    }
    else {
      init.nombrevarroas = "0";

      init.methode = 1;
      init.nombreabeilles = "0";
    }
    if (contenuPickers.date) {
      init.dateS = prepDate(contenuPickers.date);
    } else {
      init.dateS = new Date();
    }
    return init

  };

  const ValidationSchema = yup.object().shape({
    nombreabeilles: yup
      .number()
      .required()
      .typeError('Nombre'),
    nombrevarroas: yup
      .number()
      .required()
      .typeError('Nombre'),
  });
  /*
      const ValidationSchema = yup.object().shape({
        qtmedicament: yup
          .string().ensure().when('qtnourrissment', { is: '', then: yup.string().required() })
          .matches(/^\s*$|^\d{1,2}((\.|\,)\d{0,2})?$/, i18n.t('comptages.errorMedi')),
        qtnourrissment: yup
          .string().ensure().when('qtmedicament', { is: '', then: yup.string().required() })
          .matches(/^$|^\d{1,4}((\.|\,)\d{0,1})?$/, i18n.t('comptages.errorNourri')),
        typnourrissement: yup
          .string()
          .max(20, 'trop long'),
        medicament: yup
          .string()
          .when('qtmedicament', { is: ((qtmedicament) => qtmedicament.length > 0), then: yup.string().required('obligatoire') }),
        unitenourrissement: yup
          .string()
          .when('qtnourrissment', { is: ((qtnourrissment) => qtnourrissment.length > 0), then: yup.string().required('obligatoire') }),
        typnourrissement: yup
          .string()
          .when('qtnourrissment', { is: ((qtnourrissment) => qtnourrissment.length > 0), then: yup.string().required('obligatoire') })
      }, [['qtmedicament', 'qtnourrissment']],
        [['qtmedicament', 'medicament']],
        [['qtnourrissment', 'unitenourrissement']],
        [['qtnourrissment', 'typnourrissement']]); */

  const FormonSubmit = (data) => {
    const mComptage = new Object();
    mComptage.date = dayjs(data.dateS).format('YYYY-MM-DD');

    mComptage.colonie = colonie.id;
    mComptage.methode = data.methode;
    mComptage.nombreabeilles = data.nombreabeilles;
    mComptage.nombrevarroas = data.nombrevarroas;
    contenuPickers.date = Moment(data.dateS).format('YYYY-MM-DD');


    if (modeModif) {
      mComptage.id = comptage.id;
      modifyComptage(mComptage)
        .then(() => {
          Promise.all([getLastComptage(JSON.stringify(mComptage.colonie)), getListComptage(JSON.stringify(mComptage.colonie))])
            .then(([localeComptage, lesComptages]) => {
              dispatch(leDernierComptageStore(localeComptage));
              dispatch(leComptageRevoke());
              dispatch(lesComptagesStore(lesComptages));
            })
            .catch((err) => { console.log('erreur !!! mod'); console.log(err) });
          dispatch(setAffModComptage(false));
          dispatch(leComptageStore(mComptage))
        })
      // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher })});
    } else {
      createComptage(mComptage)
        .then((item) => {
          Promise.all([getLastComptage(JSON.stringify(mComptage.colonie)), getListComptage(JSON.stringify(mComptage.colonie))])
            .then(([localeComptage, lesComptages]) => {
              dispatch(leDernierComptageStore(localeComptage));
              dispatch(leComptageRevoke());
              dispatch(lesComptagesStore(lesComptages));
            })
            .catch(console.log('erreur !!!'));
          dispatch(leComptageStore(mComptage)); dispatch(setAffCreeComptage(false));
        })
      // navigation.replace('Colonie', {idColonie: colonie.id, contenuPickers: contenuPickers, nomRucher : nomRucher, idRucher: idRucher})});
    };
  };

  const abeilleComptage = (comptage) => {
    const laMethode = contenuPickers.listTypComptage.find(item => item.id === comptage);
    if (typeof (laMethode) != "undefined") { return laMethode.indNombreAbeilles };
    return false;
  };

  return (
    <>
      <Modal
        open={visible}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}      >
        <Box sx={{
          width: 500, bgcolor: amber[100],
          border: '2px solid #000', boxShadow: 24, p: 2,
        }} >
          <Formik
            initialValues={Initialisation()}
            validationSchema={ValidationSchema}
            onSubmit={values => FormonSubmit(values)}
          >
            {({
              handleSubmit, isValid, values, setFieldValue, setFieldTouched,
              errors, touched, handleTextInput, withPickerValues, handleChange, handleBlur
            }) => (
              <>

                <Typography sx={{ fontSize: 30, }} align='center'>{i18n.t('colonie.nom')} : {colonie.nom} </Typography>
                <Divider />
                <p></p>

                <Grid container alignItems='center'>
                  <Grid xs={3} sx={{ pt: 1, pl: 1, color: grey[600], fontSize: 15 }} display="flex">
                    <Typography style={{ flex: 1 }}>{i18n.t('comptage.date')}</Typography>
                  </Grid>
                  <Grid xs={4}>
                    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={'fr'}>
                      <DesktopDatePicker
                        value={values.dateS}
                        onChange={(newValue) => { setFieldValue("dateS", newValue || values.dateS); }}
                        renderInput={(params) =>
                          <TextField variant="standard" size="small" sx={{ backgroundColor: 'white' }}{...params} />}
                      />
                    </LocalizationProvider>
                  </Grid>
                  <Grid xs={6}></Grid>

                </Grid>
                <p></p>

                <Divider />



                <Field component={CustomPicker}
                  liste={contenuPickers.listTypComptage} itemKey='id' itemLabel='Méthode '
                  ModeModif={modeModif} name='methode'
                  selectedValue={values.methode}
                  langue={i18n.locale} label={i18n.t('comptage.methode')}
                  handleChange={(itemIndex) => handleChange('methode')(itemIndex)}
                />

                <Field component={CustomInput} name="nombrevarroas" label={i18n.t('comptage.nombrevarroas')}
                  placeholder={i18n.t('comptage.nombrevarroasu')} required={true} />

                {(typeof values.methode !== 'undefined') &&
                  <Field component={CustomInput} name="nombreabeilles" label={referenceLibelle(values.methode)}
                    placeholder={i18n.t('comptage.nombreabeilles')} required={true} />}


                <Grid container display="flex" justifyContent="flex-end">
                  <Tooltip title={<Typography fontSize={20}>Annuler</Typography>} placement="top" sx={{ fontSize: 200 }}>
                    <IconButton onClick={() => { dispatch(setAffModComptage(false)); dispatch(setAffCreeComptage(false)) }} >
                      <RestoreIcon sx={{ fontSize: 40, }} />
                    </IconButton>
                  </Tooltip>
                  <IconButton onClick={handleSubmit} sx={{ color: green[700] }} >
                    <CheckCircleOutlineIcon sx={{ fontSize: 40 }} />
                  </IconButton>
                </Grid>
              </>
            )}

          </Formik>

        </Box>
      </Modal>
    </>);
}

//<Button onClick={() => dispatch(toggleAffReine()) } >Annuler</Button>


export default ModComptage;
